import { Container, Skeleton } from "@mui/material";
import React from "react";

export default function LoadingBox() {
	const skeletonArray = new Array(3).fill(null);
	return (
		<Container
			sx={{
				display: "flex",
				justifyContent: "space-evenly",
				marginBottom: 5,
			}}
		>
			{skeletonArray.map((_, index) => {
				return (
					<Container
						key={index}
						sx={{
							marginTop: 5,
							width: "300px",
						}}
					>
						<Skeleton height={100} />
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</Container>
				);
			})}
		</Container>
	);
}
