import React from "react";
import { Container, Typography } from "@mui/material";
import {
	ABOUT_LINK,
	CONTACT_LINK,
	HEADER_FONT,
	MEMBERSHIP_LINK,
	SHOP_LINK,
} from "../../utils/constants";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const aboutUsImage =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Faboutus.png?alt=media&token=c8684121-8b37-4eac-9327-fdb39e0b8abe";
const contactImage =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fcontact.png?alt=media&token=12b0551a-73b3-4263-b508-d40e06c1a2f9";
const shopImage =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fshop.png?alt=media&token=00a7ed15-29d6-4b43-b690-8d9ef80c868c";
const signUpImage =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fsignup.png?alt=media&token=035d46d3-3382-490f-b7d9-7c4150e5e9d6";

const cards = [
	{
		url: aboutUsImage,
		title: "About Us",
		link: ABOUT_LINK,
		id: 1,
	},
	{
		url: shopImage,
		title: "Shop Now",
		link: SHOP_LINK,
		id: 2,
	},
	{
		url: contactImage,
		title: "Contact Us",
		link: CONTACT_LINK,
		id: 3,
	},
	{
		url: signUpImage,
		title: "Join Our Membership",
		link: MEMBERSHIP_LINK,
		id: 4,
	},
];

export default function HomeBrowseSiteUpdated() {
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				padding: 5,
			}}
		>
			<Typography variant="overline" style={{ fontFamily: HEADER_FONT }}>
				<span className="text-lg font-bold lg:text-4xl">BROWSE OUR SITE</span>
			</Typography>
			<AnimatePresence>
				<div className="grid grid-cols-2 lg:flex gap-2">
					{cards.map((card, key) => {
						return (
							<motion.div
								key={key}
								initial={{ opacity: 0, y: 50 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ delay: key * 0.1, duration: 0.5 }}
								viewport={{ once: true }}
							>
								<Card key={key} card={card} />
							</motion.div>
						);
					})}
				</div>
			</AnimatePresence>
		</Container>
	);
}
const Card = ({ card }) => {
	return (
		<Link to={card.link} key={card.id}>
			<div className="group overflow-hidden relative bg-neutral-200 rounded-md h-[190px] w-[140px] md:h-[250px] md:w-[250px] lg:h-[500px] lg:w-[22vw]">
				<div
					style={{
						backgroundImage: `url(${card.url})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
					className="absolute brightness-75 inset-0 z-0 transition-transform duration-700 group-hover:scale-105 group-hover:brightness-[0.60]"
				/>
				<div className="absolute inset-0 z-10 flex justify-center items-center">
					<span
						style={{ fontFamily: HEADER_FONT }}
						className="text-white uppercase text-center text-sm md:text-xl"
					>
						{card.title}
					</span>
				</div>
			</div>
		</Link>
	);
};
