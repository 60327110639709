import { Alert, AlertTitle, Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { SHOP_LINK } from "../utils/constants";

export default function EmptyCartMessageBox() {
	return (
		<Container
			sx={{
				height: "85vh",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Alert
				severity="info"
				sx={{
					height: "200px",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#FFFFFF",
				}}
			>
				<AlertTitle>Cart is empty</AlertTitle>
				Add items to your cart —{" "}
				<Link to={SHOP_LINK}>
					<strong>check it out!</strong>
				</Link>
			</Alert>
		</Container>
	);
}
