import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ABOUT_HEADER_FONT, BODY_FONT } from "../../utils/constants";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { defaultSchema } from "hast-util-sanitize";

export default function ContentRightUpdated({ content, image }) {
	const { t } = useTranslation();
	return (
		<div className="grid grid-cols-2">
			<div
				style={{
					backgroundImage: `url(${image})`,
					height: "600px",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			></div>
			<div className="p-5 flex flex-col justify-center">
				<Typography fontSize={70} fontFamily={ABOUT_HEADER_FONT}>
					{t(content.header)}
				</Typography>
				<Typography fontSize={16} fontFamily={BODY_FONT}>
					<Markdown
						rehypePlugins={[[rehypeRaw], [rehypeSanitize, defaultSchema]]}
					>
						{t(content.content)}
					</Markdown>
				</Typography>
			</div>
		</div>
	);
}
