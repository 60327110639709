const content = [
	{
		header: "mission",
		content: "missionContent",
	},
	{
		header: "approach",
		content: "approachContent",
	},
	{
		header: "promise",
		content: "promiseContent",
	},
	{
		header: "contact",
		content: "contactContent",
	},
];

export default content;
