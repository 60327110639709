import { Container, Typography, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { getError } from "../utils";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import { BASEURL } from "../utils/constants";
import { Store } from "../Store";
import SnackbarMessage from "../components/SnackbarMessage";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return {
				...state,
				loading: false,
				user: action.userPayload,
				message: action.messagePayload,
			};
		case "FETCH_FAIL":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function UserBaseScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const { dispatch: ctxDispatch } = useContext(Store);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [{ loading, user, message }, dispatch] = useReducer(reducer, {
		loading: false,
		user: [],
		message: "",
	});

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const { data } = await axios.get(`${BASEURL}/api/users/alluser`, {
					withCredentials: true,
				});
				dispatch({
					type: "FETCH_SUCCESS",
					userPayload: data.user,
					messagePayload: data.message,
				});
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
				setIsShowSnackbar(true);
				if (err.response && err.response.status === 403) {
					setTimeout(function () {
						ctxDispatch({ type: "USER_SIGNOUT" });
					}, 1000);
				}
			}
		};
		fetchData();
	}, [ctxDispatch]);

	const columns = isMobile
		? [
				{ field: "name", headerName: "Name", width: 100 },
				{ field: "email", headerName: "Email", width: 180 },
				{ field: "isMember", headerName: "Member", width: 100 },
		  ]
		: [
				{ field: "name", headerName: "Name", width: 250 },
				{ field: "_id", headerName: "ID", width: 250 },
				{ field: "email", headerName: "Email", width: 300 },
				{ field: "isMember", headerName: "Member", width: 130 },
		  ];

	const handleRowClick = (params) => {
		console.log(params);
		navigate(`/userbase/${params.row._id}`);
	};

	return (
		<Container
			sx={{
				height: "80vh",
				display: "flex",
				alignItems: "center",
				mt: 3,
			}}
		>
			<Helmet>
				<title>User Base</title>
			</Helmet>
			{loading ? (
				<PageLoadingBox />
			) : (
				<div style={{ minHeight: 400, width: "100%" }}>
					<Typography variant={isMobile ? "h5" : "h4"}>User Base</Typography>
					<DataGrid
						rows={user}
						getRowId={(user) => user._id}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 5 },
							},
						}}
						onRowClick={handleRowClick}
						pageSizeOptions={[5, 10]}
					/>
				</div>
			)}
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</Container>
	);
}
