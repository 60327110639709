import {
	CardMedia,
	Container,
	Grid,
	Stack,
	Typography,
	Link as LinkM,
	useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import {
	ABOUT_LINK,
	CONTACT_LINK,
	HOME_LINK,
	PRIVACY_POLICY_LINK,
	SHIPPING_POLICY_TERM,
	SHOP_LINK,
	TERMS_LINK,
} from "../utils/constants";

const logo =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fyang_logo_1.png?alt=media&token=79224b6b-b1f7-4e46-99e3-8b2b87a83b60";

export default function Footer() {
	const isMobile = useMediaQuery("(max-width:600px)");

	return (
		<div
			style={{
				minHeight: isMobile ? "250px" : "300px",
				backgroundColor: "#FCE7CC",
			}}
		>
			<Container>
				<Grid container>
					<Grid item xs={4}>
						<Stack spacing={1} height={"100%"} alignItems={"center"}>
							<div>
								<CardMedia
									component="img"
									style={{
										width: isMobile ? "100px" : "200px",
										height: isMobile ? "100px" : "200px",
										marginTop: "20px",
									}}
									src={logo}
									alt="logo"
								/>
							</div>
							<Grid
								container
								sx={{
									height: "100%",
									width: isMobile ? "100px" : "150px",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<Grid item>
									<LinkM
										href="https://instagram.com/jianshengwellness"
										target="_blank"
										rel="noopener noreferrer"
										sx={{ textDecoration: "none", color: "black" }}
									>
										<InstagramIcon
											sx={{ fontSize: isMobile ? "25px" : "30px" }}
										/>
									</LinkM>
								</Grid>
								<Grid item>
									<LinkM
										href="https://wa.me/6581886982"
										target="_blank"
										rel="noopener noreferrer"
										sx={{ textDecoration: "none", color: "black" }}
									>
										<WhatsAppIcon
											sx={{ fontSize: isMobile ? "25px" : "30px" }}
										/>
									</LinkM>
								</Grid>
								<Grid item>
									<LinkM
										href="mailto:jianshengjsw@gmail.com"
										sx={{ textDecoration: "none", color: "black" }}
									>
										<EmailIcon sx={{ fontSize: isMobile ? "25px" : "30px" }} />
									</LinkM>
								</Grid>
							</Grid>
						</Stack>
					</Grid>
					<Grid item xs={1}></Grid>
					<Grid item xs={3}>
						<Stack
							spacing={2}
							sx={{
								height: "100%",
								marginTop: "30px",
							}}
						>
							<Stack spacing={1}>
								<Typography fontSize={isMobile ? 15 : 18}>
									<strong>Our Page</strong>
								</Typography>
								<Typography fontSize={isMobile ? 12 : 15}>
									<Link to={HOME_LINK} style={{ textDecoration: "none" }}>
										Home
									</Link>
								</Typography>
								<Typography fontSize={isMobile ? 12 : 15}>
									<Link to={ABOUT_LINK} style={{ textDecoration: "none" }}>
										About Us
									</Link>
								</Typography>
								<Typography fontSize={isMobile ? 12 : 15}>
									<Link to={SHOP_LINK} style={{ textDecoration: "none" }}>
										Our Shop
									</Link>
								</Typography>
								<Typography fontSize={isMobile ? 12 : 15}>
									<Link to={CONTACT_LINK} style={{ textDecoration: "none" }}>
										Contact Us
									</Link>
								</Typography>
							</Stack>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<Typography fontSize={isMobile ? 10 : 13}>
									<Link to={TERMS_LINK} style={{ textDecoration: "none" }}>
										Terms of use
									</Link>
								</Typography>
								<Typography fontSize={isMobile ? 10 : 13}>
									<Link
										to={PRIVACY_POLICY_LINK}
										style={{ textDecoration: "none" }}
									>
										Privacy Policy
									</Link>
								</Typography>
								<Typography fontSize={isMobile ? 10 : 13}>
									<Link
										to={SHIPPING_POLICY_TERM}
										style={{ textDecoration: "none" }}
									>
										Shipping Policy
									</Link>
								</Typography>
							</div>
						</Stack>
					</Grid>
					<Grid item xs={4}>
						<Stack
							spacing={1}
							sx={{
								height: "100%",
								marginTop: "30px",
							}}
						>
							<Typography variant="h6" fontSize={isMobile ? 15 : 18}>
								Contact
							</Typography>
							<Typography
								variant="button"
								sx={{ display: "flex" }}
								justifyContent={"start"}
								alignItems={"center"}
								fontSize={isMobile ? 10 : 15}
							>
								<CallIcon
									sx={{
										marginRight: "5px",
										fontSize: isMobile ? "20px" : "30px",
									}}
								/>
								+65 8188 6982
							</Typography>
							{isMobile ? (
								<></>
							) : (
								<Typography
									variant="button"
									sx={{ display: "flex" }}
									justifyContent={"start"}
									alignItems={"center"}
									fontSize={11}
								>
									<EmailIcon
										sx={{
											marginRight: "5px",
											fontSize: "30px",
										}}
									/>
									<LinkM
										href="mailto:jianshengjsw@gmail.com"
										sx={{ textDecoration: "none", color: "black" }}
									>
										jianshengjsw@gmail.com
									</LinkM>
								</Typography>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
