import { Badge, CardMedia, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link, useLocation } from "react-router-dom";
import * as CONSTANTS from "../../utils/constants.js";
import { Store } from "../../Store.js";
import LoginIcon from "@mui/icons-material/Login";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getName } from "../../utils/utils.js";
import { motion } from "framer-motion";
import axios from "axios";

const logo =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fyang_logo_1.png?alt=media&token=79224b6b-b1f7-4e46-99e3-8b2b87a83b60";

export default function ComputerNavBar({ handleLanguage, pages }) {
	const location = useLocation();
	const [activePage, setActivePage] = useState(CONSTANTS.HOME);
	const [visible, setVisible] = useState(true);
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const open = Boolean(isOpenMenu);
	const [isOpenSetting, setIsOpenSetting] = useState(false);
	const openSetting = Boolean(isOpenSetting);
	const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
	const {
		userInfo,
		cart: { cartItems },
	} = state;

	const signoutHandler = async () => {
		try {
			ctxDispatch({ type: "USER_SIGNOUT" });
			const { data } = await axios.post(
				`${CONSTANTS.BASEURL}/api/users/logout`,
				undefined,
				{ withCredentials: true }
			);
			console.log(data.message);
		} catch (err) {
			console.log(err.message);
		}
		setIsOpenMenu(false);
	};

	const menus = [
		{ to: CONSTANTS.PROFILE_LINK, value: userInfo && userInfo.name },
		{ to: "/orderhistory", value: "Order History" },
		{ to: "#signout", value: "Logout" },
	];

	const settings = [
		{ value: "Language", click: handleLanguage },
		...(userInfo
			? userInfo.isAdmin
				? [
						{
							to: CONSTANTS.DASHBOARD_LINK,
							value: "Dashboard",
						},
						{
							to: CONSTANTS.ADMIN_PRODUCTS_LINK,
							value: "View Products",
						},
						{
							to: CONSTANTS.USER_BASE_LINK,
							value: "User Base",
						},
				  ]
				: []
			: []),
	];

	const iconPages = [
		{
			component: (
				<Tooltip title="Cart">
					<Badge color="error" badgeContent={cartItems.length}>
						<ShoppingCartOutlinedIcon sx={{ fontSize: "30px" }} />
					</Badge>
				</Tooltip>
			),
			link: CONSTANTS.CART_LINK,
		},
	];

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
			setPrevScrollPos(currentScrollPos);
		};
		setActivePage(getName(location.pathname));
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [visible, location, prevScrollPos]);

	return (
		<motion.div
			initial={{ y: visible ? -100 : 0 }}
			animate={{ opacity: visible ? 1 : 0, y: visible ? 0 : -100 }}
			transition={{ type: "tween" }}
			style={{
				position: "fixed",
				display: "inherit",
				width: "100%",
				background: "linear-gradient(to bottom, #E9D9B7, #FCE7CC)",
				alignItems: "center",
				zIndex: 100,
				top: 0,
				height: "100px",
			}}
		>
			<div className="flex h-full justify-between items-center md:px-20 lg:px-32">
				<div className="flex justify-evenly h-3/5 gap-1 items-center">
					<Link
						to="/"
						style={{
							textDecoration: "none",
							color: "black",
						}}
					>
						<CardMedia
							component="img"
							src={logo}
							alt="Jian Sheng"
							className={"h-24"}
						/>
					</Link>
					{pages.map((page, key) => {
						return (
							<Link
								to={page.link}
								underline="none"
								variant="overline"
								key={key}
								className="h-full"
							>
								<div
									style={{
										backgroundColor: activePage === page.name ? "#FCE7CC" : "",
										fontFamily: CONSTANTS.NAVBAR_FONT,
									}}
									className="h-full rounded-lg"
								>
									<h1 className="flex items-center text-black text-lg px-1 lg:px-5 h-full rounded-lg hover:bg-[#FCE7CC] transition-all duration-200">
										{page.name}
									</h1>
								</div>
							</Link>
						);
					})}
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-evenly",
						width: "200px",
					}}
				>
					{userInfo ? (
						<div>
							<motion.div
								whileHover={{
									scale: 1.1,
								}}
								transition={{ type: "keyframes", stiffness: 300 }}
							>
								<Link
									aria-controls={open ? "basic-menu" : undefined}
									aria-haspopup="true"
									aria-expanded={open ? "true" : undefined}
									onClick={(e) => setIsOpenMenu(e.currentTarget)}
									style={{ color: "black" }}
								>
									<PermIdentityIcon sx={{ fontSize: "33px" }} />
								</Link>
							</motion.div>
							<Menu
								id="basic-menu"
								anchorEl={isOpenMenu}
								open={open}
								onClose={() => setIsOpenMenu(null)}
								onClick={() => setIsOpenMenu(false)}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								{menus.map((menu, key) => {
									return (
										<Link
											key={key}
											to={menu.to}
											style={{ textDecoration: "none", color: "black" }}
											onClick={menu.value === "Logout" && signoutHandler}
										>
											<MenuItem>{menu.value}</MenuItem>
										</Link>
									);
								})}
							</Menu>
						</div>
					) : (
						<Tooltip title="Login">
							<motion.div
								whileHover={{
									scale: 1.1,
								}}
								transition={{ type: "keyframes", stiffness: 300 }}
							>
								<Link to={CONSTANTS.LOGIN_LINK} style={{ color: "black" }}>
									<LoginIcon sx={{ fontSize: "30px" }} />
								</Link>
							</motion.div>
						</Tooltip>
					)}
					{iconPages.map((page, key) => {
						return (
							<motion.div
								whileHover={{
									scale: 1.1,
								}}
								transition={{ type: "keyframes", stiffness: 300 }}
								key={key}
							>
								<Link to={page.link} style={{ color: "black" }}>
									{page.component}
								</Link>
							</motion.div>
						);
					})}
					<motion.div
						whileHover={{
							scale: 1.1,
						}}
						transition={{ type: "keyframes", stiffness: 300 }}
					>
						<Link
							aria-controls={openSetting ? "basic-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={openSetting ? "true" : undefined}
							onClick={(e) => setIsOpenSetting(e.currentTarget)}
							style={{ color: "black" }}
						>
							<Tooltip title="Options">
								<MoreVertIcon sx={{ fontSize: "30px" }} />
							</Tooltip>
						</Link>
					</motion.div>
					<Menu
						anchorEl={isOpenSetting}
						open={openSetting}
						onClose={() => setIsOpenSetting(null)}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						{settings.map((setting, key) => {
							return (
								<Link
									key={key}
									style={{ color: "black", textDecoration: "none" }}
									onClick={
										setting.click
											? setting.click
											: () => setIsOpenSetting(false)
									}
									to={setting.to}
								>
									<MenuItem>{setting.value}</MenuItem>
								</Link>
							);
						})}
					</Menu>
				</div>
			</div>
		</motion.div>
	);
}
