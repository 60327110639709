import {
	Button,
	IconButton,
	CircularProgress,
	Container,
	Grid,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Store } from "../Store";
import { getError, parseText } from "../utils";
import { useNavigate } from "react-router-dom";
import { ADMIN_PRODUCTS_LINK, BASEURL } from "../utils/constants";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadBytesResumable,
} from "firebase/storage";
import SnackbarMessage from "../components/SnackbarMessage";
import CustomButton from "../components/general/CustomButton";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { defaultSchema } from "hast-util-sanitize";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return { ...state, loading: false };
		case "FETCH_FAIL":
			return { ...state, loading: false, message: action.payload };
		case "CREATE_REQUEST":
			return { ...state, loadingCreate: true };
		case "CREATE_SUCCESS":
			return { ...state, loadingCreate: false };
		case "CREATE_FAIL":
			return { ...state, loadingCreate: false, message: action.payload };
		case "UPLOAD_REQUEST":
			return { ...state, loadingUpload: true };
		case "UPLOAD_SUCCESS":
			return { ...state, loadingUpload: false, message: action.payload };
		case "UPLOAD_FAIL":
			return { ...state, loadingUpload: false, message: action.payload };
		default:
			return state;
	}
};

const metadata = {
	contentType: "image/jpeg",
};

const customSchema = {
	...defaultSchema,
	tagNames: [...defaultSchema.tagNames, "u"],
};

export default function CreateProductScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const { dispatch: ctxDispatch } = useContext(Store);
	const [images, setImages] = useState([]);
	const [imageUrls, setImageUrls] = useState([]);
	const firebaseStorage = getStorage();
	const [downloadImageUrl, setDownloadImageUrl] = useState([]);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [{ loading, message, loadingCreate, loadingUpload }, dispatch] =
		useReducer(reducer, {
			loadingCreate: false,
			loadingUpload: false,
			loading: false,
			message: "",
		});

	const [name, setName] = useState("");
	const [brand, setBrand] = useState("");
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");
	const [dosage, setDosage] = useState("");
	const [storage, setStorage] = useState("");
	const [ingredients, setIngredients] = useState("");
	const [price, setPrice] = useState(0);
	const [discountedPrice, setDiscountedPrice] = useState(0);
	const [countInStock, setCountInStock] = useState("");
	const [isArchive, setIsArchive] = useState(false);
	const [isShowDescriptionPreview, setIsShowDescriptionPreview] =
		useState(false);
	const [isShowDosagePreview, setIsShowDosagePreview] = useState(false);
	const [isShowStoragePreview, setIsShowStoragePreview] = useState(false);
	const [isShowIngredientPreview, setIsShowIngredientPreview] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// TODO add loading
		if (message && loading) {
		}
		dispatch({ type: "CREATE_REQUEST" });
		const currSlug = name.toLowerCase().replace(/ /g, "-");
		try {
			if (downloadImageUrl.length <= 0) {
				throw new Error("No image uploaded. Please upload at least 1 image.");
			}
			const { data } = await axios.post(
				`${BASEURL}/api/products/createproduct`,
				{
					name: name,
					slug: currSlug,
					image: downloadImageUrl,
					brand: brand,
					category: category,
					description: description,
					dosage: dosage,
					storage: storage,
					ingredients: ingredients,
					price: price,
					discountedPrice: discountedPrice,
					countInStock: countInStock,
					isArchive: isArchive,
				},
				{
					withCredentials: true,
				}
			);
			dispatch({ type: "CREATE_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
			setTimeout(function () {
				navigate(ADMIN_PRODUCTS_LINK);
			}, 1000);
		} catch (err) {
			dispatch({ type: "CREATE_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	const handleImageChange = (e) => {
		const files = e.target.files;
		if (files.length > 0) {
			setImages(files);
			const urls = [];
			for (let i = 0; i < files.length; i++) {
				urls.push(URL.createObjectURL(files[i]));
			}
			setImageUrls(urls);
		}
	};

	const handleUpload = async () => {
		if (images && images.length > 0) {
			for (let i = 0; i < images.length; i++) {
				dispatch({ type: "UPLOAD_REQUEST" });
				const fileName = `${Date.now()}_${images[i].name}`;
				const imageRef = ref(firebaseStorage, fileName);
				const uploadTask = uploadBytesResumable(imageRef, images[i], metadata);

				uploadTask.on(
					"state_changed",
					(snapshot) => {
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						console.log("Upload is " + progress + "% done");
						switch (snapshot.state) {
							case "paused":
								console.log("Upload is paused");
								break;
							case "running":
								console.log("Upload is running");
								break;
							default:
								break;
						}
					},
					(error) => {
						// A full list of error codes is available at
						// https://firebase.google.com/docs/storage/web/handle-errors
						dispatch({ type: "UPLOAD_FAIL", payload: error.code });
						setIsShowSnackbar(true);
						// error.code can be storage/unauthorized, storage/canceled, storage/unknown
					},
					async () => {
						// Upload completed successfully, now we can get the download URL
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
						setDownloadImageUrl((prev) => [...prev, downloadURL]);
						dispatch({ type: "UPLOAD_SUCCESS", payload: "Upload success." });
						setIsShowSnackbar(true);
					}
				);
			}
		}
	};
	return (
		<Container maxWidth="md" sx={{ pb: 5, pt: 5 }}>
			<Helmet>
				<title>Create Product</title>
			</Helmet>
			<div style={{ display: "flex" }}>
				<IconButton sx={{ color: "black" }} onClick={() => navigate(-1)}>
					<ArrowBackIosNewIcon sx={{ fontSize: isMobile ? 20 : 30 }} />
				</IconButton>
				<Typography variant={isMobile ? "h5" : "h4"}>List a Product</Typography>
			</div>
			<div className="bg-[#f1f0ef] p-5 my-3 rounded-lg font-light border-[1px] border-slate-400 text-xs sm:text-base">
				<h2 className="font-semibold underline">Instruction manual:</h2>
				<p>1. Bold Sentences: **bold**</p>
				<p>2. Underline Sentences: __underline__</p>
				<p>3. New line: 2 new spaces [2 spacebar, 1 new line]</p>
				<p>4. New paragraph: 2 new lines [press enter twice]</p>
			</div>
			<form onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<TextField
						required
						label="Product Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
						size={isMobile ? "small" : "medium"}
					/>
					<TextField
						required
						label="Product Brand"
						value={brand}
						onChange={(e) => setBrand(e.target.value)}
						size={isMobile ? "small" : "medium"}
					/>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Stack spacing={2}>
								<input
									type="file"
									accept="image/*"
									onChange={handleImageChange}
									multiple
								/>
								{imageUrls.length > 0 ? (
									<Grid item>
										<Typography variant="subtitle1">Uploaded Image:</Typography>
										<div className="grid grid-cols-4 grid-flow-row gap-4">
											{imageUrls.map((imageUrl, key) => {
												return (
													<div
														key={key}
														className={`w-52 h-52 bg-contain`}
														style={{
															backgroundImage: `url(${imageUrl})`,
															backgroundRepeat: "no-repeat",
														}}
													/>
												);
											})}
										</div>
									</Grid>
								) : (
									<Typography variant="subtitle1">
										Select at least 1 image to upload.
									</Typography>
								)}
								{loadingUpload && <CircularProgress size={20} />}
							</Stack>
							<Button
								variant="contained"
								component="label"
								startIcon={<CloudUploadIcon />}
								onClick={handleUpload}
								sx={{ marginTop: 1 }}
							>
								Upload
							</Button>
						</Grid>
					</Grid>
					<TextField
						required
						label="Category"
						value={category}
						onChange={(e) => setCategory(e.target.value)}
						size={isMobile ? "small" : "medium"}
					/>
					<div className="grid grid-cols-10 gap-3 items-center">
						<div className="col-span-10 sm:col-span-9">
							{isShowDescriptionPreview ? (
								<div className="h-64 overflow-scroll border-2 rounded-md relative">
									<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
										Description
									</p>
									<Markdown
										rehypePlugins={[
											[rehypeRaw],
											[rehypeSanitize, customSchema],
										]}
										className="px-2 flex flex-col gap-5"
									>
										{parseText(description)}
									</Markdown>
								</div>
							) : (
								<TextField
									required
									fullWidth
									label="Description"
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									size={isMobile ? "small" : "medium"}
									multiline
									maxRows={10}
								/>
							)}
						</div>
						<div className="col-span-10 sm:col-span-1">
							<CustomButton
								type={"button"}
								onClick={() =>
									setIsShowDescriptionPreview(!isShowDescriptionPreview)
								}
							>
								Preview
							</CustomButton>
						</div>
					</div>
					<div className="grid grid-cols-10 gap-3 items-center">
						<div className="col-span-10 sm:col-span-9">
							{isShowDosagePreview ? (
								<div className="h-64 overflow-scroll border-2 rounded-md relative">
									<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
										Dosage
									</p>
									<Markdown
										rehypePlugins={[
											[rehypeRaw],
											[rehypeSanitize, customSchema],
										]}
										className="px-2 flex flex-col gap-5"
									>
										{parseText(dosage)}
									</Markdown>
								</div>
							) : (
								<TextField
									required
									fullWidth
									label="Dosage"
									value={dosage}
									onChange={(e) => setDosage(e.target.value)}
									size={isMobile ? "small" : "medium"}
									multiline
									maxRows={10}
								/>
							)}
						</div>
						<div className="col-span-10 sm:col-span-1">
							<CustomButton
								type={"button"}
								onClick={() => setIsShowDosagePreview(!isShowDosagePreview)}
							>
								Preview
							</CustomButton>
						</div>
					</div>
					<div className="grid grid-cols-10 gap-3 items-center">
						<div className="col-span-10 sm:col-span-9">
							{isShowStoragePreview ? (
								<div className="h-64 overflow-scroll border-2 rounded-md relative">
									<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
										Storage
									</p>
									<Markdown
										rehypePlugins={[
											[rehypeRaw],
											[rehypeSanitize, customSchema],
										]}
										className="px-2 flex flex-col gap-5"
									>
										{parseText(storage)}
									</Markdown>
								</div>
							) : (
								<TextField
									required
									fullWidth
									label="Storage"
									value={storage}
									onChange={(e) => setStorage(e.target.value)}
									size={isMobile ? "small" : "medium"}
									multiline
									maxRows={10}
								/>
							)}
						</div>
						<div className="col-span-10 sm:col-span-1">
							<CustomButton
								type={"button"}
								onClick={() => setIsShowStoragePreview(!isShowStoragePreview)}
							>
								Preview
							</CustomButton>
						</div>
					</div>
					<div className="grid grid-cols-10 gap-3 items-center">
						<div className="col-span-10 sm:col-span-9">
							{isShowIngredientPreview ? (
								<div className="h-64 overflow-scroll border-2 rounded-md relative">
									<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
										Ingredients
									</p>
									<Markdown
										rehypePlugins={[
											[rehypeRaw],
											[rehypeSanitize, customSchema],
										]}
										className="flex flex-col gap-5 px-2"
									>
										{parseText(ingredients)}
									</Markdown>
								</div>
							) : (
								<TextField
									required
									fullWidth
									label="Ingredients"
									value={ingredients}
									onChange={(e) => setIngredients(e.target.value)}
									size={isMobile ? "small" : "medium"}
									multiline
									maxRows={10}
								/>
							)}
						</div>
						<div className="col-span-10 sm:col-span-1">
							<CustomButton
								type={"button"}
								onClick={() =>
									setIsShowIngredientPreview(!isShowIngredientPreview)
								}
							>
								Preview
							</CustomButton>
						</div>
					</div>
					<TextField
						required
						type="number"
						inputProps={{
							min: 1,
						}}
						label="Price"
						value={price}
						onChange={(e) => setPrice(e.target.value)}
						size={isMobile ? "small" : "medium"}
					/>
					<TextField
						required
						type="number"
						label="Discounted Price"
						value={discountedPrice}
						onChange={(e) => setDiscountedPrice(e.target.value)}
						size={isMobile ? "small" : "medium"}
					/>
					<TextField
						required
						type="number"
						inputProps={{
							min: 1,
						}}
						label="Stock Count"
						value={countInStock}
						onChange={(e) => setCountInStock(e.target.value)}
						size={isMobile ? "small" : "medium"}
					/>
					<div className="flex items-center">
						<Typography sx={{ mr: 2 }}>Archive:</Typography>
						<div>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									checked={isArchive}
									value={isArchive}
									onChange={() => setIsArchive(!isArchive)}
									class="sr-only peer"
								/>
								<div className="peer ring-0 bg-rose-400 rounded-full outline-none duration-300 after:duration-500 w-12 h-12  shadow-md peer-checked:bg-emerald-500  peer-focus:outline-none  after:content-['✖️'] after:rounded-full after:absolute after:outline-none after:h-10 after:w-10 after:bg-gray-50 after:top-1 after:left-1 after:flex after:justify-center after:items-center  peer-hover:after:scale-75 peer-checked:after:content-['✔️'] after:-rotate-180 peer-checked:after:rotate-0"></div>
							</label>
						</div>
					</div>
					<CustomButton type={"submit"}>
						{loadingCreate && <CircularProgress size={15} />}
						<Typography ml={loadingCreate && 4}>List Product</Typography>
					</CustomButton>
				</Stack>
			</form>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</Container>
	);
}
