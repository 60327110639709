import React from "react";
import { motion } from "framer-motion";
import { Button } from "@mui/material";

export default function CustomButton({
	children,
	type,
	onClick,
	disabled,
	backgroundColor,
	hoverBackground,
}) {
	return (
		<>
			{disabled ? (
				<Button disabled={disabled} variant="contained">
					{children}
				</Button>
			) : (
				<motion.button
					initial={{ backgroundColor: backgroundColor || "#e2dfdd" }}
					whileHover={{ backgroundColor: hoverBackground || "#f0f1ef" }}
					type={type}
					onClick={onClick}
					style={{ borderWidth: "1px" }}
					className={`shadow-lg w-full justify-center cursor-pointer flex items-center active:border active:border-[#958d84] rounded-md duration-100 p-1.5`}
				>
					{children}
				</motion.button>
			)}
		</>
	);
}
