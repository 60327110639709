import {
	Button,
	Container,
	Typography,
	useMediaQuery,
	Grid,
	CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState, useEffect, useReducer } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Store } from "../Store";
import { Helmet } from "react-helmet-async";
import { getError } from "../utils";
import SnackbarMessage from "../components/SnackbarMessage";
import { BASEURL } from "../utils/constants";
import PersonIcon from "@mui/icons-material/Person";
import CustomInput from "../components/general/CustomInput";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PasswordIcon from "@mui/icons-material/Password";

const signupBackground =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Flogin_image.png?alt=media&token=c173c478-05fb-463f-8289-1778f2940a68";

const reducer = (state, action) => {
	switch (action.type) {
		case "SEND_REQUEST":
			return { ...state, loading: true };
		case "SEND_SUCCESS":
			return { ...state, loading: false, message: action.payload };
		case "SEND_FAIL":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function SignUpScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [{ loading, message }, dispatch] = useReducer(reducer, {
		loading: false,
		message: "",
	});

	const navigate = useNavigate();
	const { search } = useLocation();
	const redirectUrl = new URLSearchParams(search).get("redirect");
	const redirect = redirectUrl ? redirectUrl : "/";

	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, redirect, userInfo]);

	const isValidPassword = (password) => {
		const regex = /^(?=.*[A-Z]).{8,}$/;
		return regex.test(password);
	};

	const submitHandler = async (e) => {
		e.preventDefault();
		if (userPassword !== repeatPassword) {
			dispatch({
				type: "SEND_FAIL",
				payload: "Password does not match. Please try again.",
			});
			setIsShowSnackbar(true);
			return;
		}
		if (!isValidPassword(userPassword)) {
			dispatch({
				type: "SEND_FAIL",
				payload:
					"Please ensure password has at least 8 characters and at least 1 uppercase.",
			});
			setIsShowSnackbar(true);
			return;
		}
		try {
			dispatch({ type: "SEND_REQUEST" });
			await axios.post(`${BASEURL}/api/users/signup`, {
				name: userName,
				email: userEmail,
				password: userPassword,
			});
			dispatch({ type: "SEND_SUCCESS" });
			navigate(redirect || "/");
		} catch (e) {
			dispatch({ type: "SEND_FAIL", payload: getError(e) });
			setIsShowSnackbar(true);
		}
	};

	return (
		<div
			style={{
				backgroundImage: `url(${signupBackground})`,
				backgroundSize: "cover",
			}}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "90vh",
				}}
			>
				<Helmet>
					<title>Sign up</title>
				</Helmet>
				<div className="bg-[#FCE7CC] p-4 rounded-lg w-[300px] md:w-[500px]">
					<div className="flex flex-col gap-5 px-5">
						<Typography
							variant={isMobile ? "h5" : "h4"}
							textAlign={"start"}
							fontFamily={"inherit"}
						>
							Sign up
						</Typography>
						<form
							onSubmit={submitHandler}
							className="flex flex-col gap-1 md:gap-3 w-full"
						>
							<CustomInput
								icon={<PersonIcon />}
								value={userName}
								setValue={setUserName}
								type={"text"}
								placeholder={"Name"}
								name={"userName"}
							/>
							<CustomInput
								icon={<AlternateEmailIcon />}
								value={userEmail}
								setValue={setUserEmail}
								type={"email"}
								placeholder={"Email"}
								name={"userEmail"}
							/>
							<CustomInput
								icon={<PasswordIcon />}
								value={userPassword}
								setValue={setUserPassword}
								type={"password"}
								placeholder={"Password"}
								name={"userPassword"}
							/>
							<CustomInput
								icon={<PasswordIcon />}
								value={repeatPassword}
								setValue={setRepeatPassword}
								type={"password"}
								placeholder={"Repeat Password"}
								name={"repeatPassword"}
							/>
							<Grid container>
								<Grid item sm={7} xs={12}>
									<Typography
										variant="caption"
										display={"flex"}
										alignItems={"end"}
									>
										Already have an account?&nbsp;
										<Link
											to={`/login?redirect=${redirect}`}
											style={{ textDecoration: "underline" }}
										>
											Login.
										</Link>
									</Typography>
								</Grid>
								<Grid
									item
									sm={5}
									xs={12}
									sx={
										!isMobile && {
											display: "flex",
											justifyContent: "flex-end",
										}
									}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{loading && (
											<CircularProgress sx={{ mr: 2 }} size={"20px"} />
										)}
										<Button
											sx={{
												backgroundColor: "#FCE7CC",
												"&:hover": {
													backgroundColor: "#F59E0B",
												},
											}}
											type="submit"
											color="inherit"
											variant="contained"
										>
											Sign Up
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</div>
				</div>
			</Container>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</div>
	);
}
