import React, { useEffect, useState } from "react";
import { motion, useMotionValue } from "framer-motion";

const ONE_SECOND = 1000;
const AUTO_DELAY = ONE_SECOND * 10;
const DRAG_BUFFER = 50;

const SPRING_OPTIONS = {
	type: "spring",
	mass: 3,
	stiffness: 400,
	damping: 50,
};

export default function HomeSwipeCarousel({ isMobile }) {
	const [imgIndex, setImgIndex] = useState(0);

	const dragX = useMotionValue(0);

	const imgs = isMobile
		? [
				"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2FUntitled%20design-10.png?alt=media&token=79d9b0af-2ea0-4842-8b6d-9673ecdbcf07",
		  ]
		: [
				"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fhome_image_4.png?alt=media&token=96e12d20-9401-4b16-9cff-1e775ce9ef20",
		  ];

	useEffect(() => {
		const intervalRef = setInterval(() => {
			const x = dragX.get();

			if (x === 0) {
				setImgIndex((pv) => {
					if (pv === imgs.length - 1) {
						return 0;
					}
					return pv + 1;
				});
			}
		}, AUTO_DELAY);

		return () => clearInterval(intervalRef);
	}, [dragX, imgs.length]);

	const onDragEnd = () => {
		const x = dragX.get();

		if (x <= -DRAG_BUFFER && imgIndex < imgs.length - 1) {
			setImgIndex((pv) => pv + 1);
		} else if (x >= DRAG_BUFFER && imgIndex > 0) {
			setImgIndex((pv) => pv - 1);
		}
	};

	const Images = ({ imgIndex }) => {
		return (
			<>
				{imgs.map((imgSrc, idx) => {
					return (
						<motion.div
							key={idx}
							style={{
								backgroundImage: `url(${imgSrc})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								borderRadius: isMobile ? "20px" : "10px",
							}}
							animate={{
								scale: imgIndex === idx ? 0.95 : 0.85,
							}}
							transition={SPRING_OPTIONS}
							className={
								isMobile
									? "aspect-[5/6] w-screen shrink-0 object-cover"
									: "aspect-[5/2] w-screen shrink-0 object-cover"
							}
						/>
					);
				})}
			</>
		);
	};

	const Dots = ({ imgIndex, setImgIndex }) => {
		return (
			<div className="mt-4 flex w-full justify-center gap-2">
				{imgs.map((_, idx) => {
					return (
						<button
							key={idx}
							onClick={() => setImgIndex(idx)}
							className={`mb-3 h-3 w-3 rounded-full transition-colors ${
								idx === imgIndex ? "bg-neutral-300" : "bg-neutral-500"
							}`}
						/>
					);
				})}
			</div>
		);
	};

	return (
		<div
			className="relative overflow-hidden bg-white"
			style={{
				position: "relative",
				overflow: "hidden",
				background: "neutral",
				paddingTop: isMobile ? "20px" : "0px",
			}}
		>
			<motion.div
				drag="x"
				dragConstraints={{
					left: 0,
					right: 0,
				}}
				style={{
					x: dragX,
				}}
				animate={{
					translateX: `-${imgIndex * 100}%`,
				}}
				transition={SPRING_OPTIONS}
				onDragEnd={onDragEnd}
				className="flex cursor-grab items-center active:cursor-grabbing"
			>
				<Images imgIndex={imgIndex} />
			</motion.div>

			<Dots imgIndex={imgIndex} setImgIndex={setImgIndex} />
		</div>
	);
}
