import { useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import HomeSwipeCarousel from "../components/home/HomeSwipeCarousel";
import HomePopout from "../components/home/HomePopout";
import HomeMission from "../components/home/HomeMission";
import HomeFeaturedProducts from "../components/home/HomeFeaturedProducts";
import bgImage from "../assets/images/backgroundOnly.png";
import HomeBrowseSiteUpdated from "../components/home/HomeBrowseSiteUpdated";
import HomeBenefits from "../components/home/HomeBenefits";
import HomeTestimonialStacked from "../components/home/HomeTestimonialStacked";

const quoteBackground =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fhome_background_image.png?alt=media&token=96101cd9-e5f3-4211-bbeb-f9453551443b";

export default function HomeScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");

	return (
		<div style={{ width: "100%", paddingBottom: "20px" }}>
			<Helmet>
				<title>Home</title>
			</Helmet>
			<HomePopout />
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<HomeSwipeCarousel isMobile={isMobile} />
			</div>
			<div
				style={{
					minHeight: isMobile ? "300px" : "350px",
					maxWidth: "100%",
					backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Ftraditional-chinese-medicine-5397194_1920.jpg?alt=media&token=ac119295-cc1c-4ee8-b62f-b80d4b61ca06)`,
					backgroundAttachment: "fixed",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}
			>
				<HomeMission isMobile={isMobile} />
			</div>
			<div
				style={{
					minHeight: "50vh",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<HomeBrowseSiteUpdated />
			</div>

			<div
				style={{
					minHeight: "650px",
					backgroundImage: `url(${quoteBackground})`,
					backgroundAttachment: "fixed",
					backgroundSize: "cover",
				}}
			>
				<HomeTestimonialStacked />
			</div>
			<HomeBenefits />
			<div
				style={{
					minHeight: isMobile ? "300px" : "500px",
					backgroundImage: `url(${bgImage})`,
					backgroundSize: isMobile ? "fit" : "cover",
					backgroundRepeat: "no-repeat",
				}}
			>
				<HomeFeaturedProducts isMobile={isMobile} />
			</div>
		</div>
	);
}
