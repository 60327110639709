import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Card,
	CardContent,
	Container,
	Divider,
	Link,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { BODY_FONT, HEADER_FONT } from "../utils/constants";
import CustomButton from "../components/general/CustomButton";

export default function MembershipScreen() {
	const { t } = useTranslation();
	const isMobile = useMediaQuery("(max-width:600px)");

	const membershipInfo = [
		{
			header: t("exploreOurRange"),
			description: t("exploreOurRangeDescription"),
		},
		{
			header: t("personalizedWellness"),
			description: t("personalizedWellnessDescription"),
		},
		{
			header: t("stayUpdated"),
			description: t("stayUpdatedDescription"),
		},
		{
			header: t("communitySupport"),
			description: t("communitySupportDescription"),
		},
	];

	const landingPic =
		"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fsignup.png?alt=media&token=035d46d3-3382-490f-b7d9-7c4150e5e9d6";

	return (
		<div style={{ backgroundColor: "#FFFAEF" }}>
			<Helmet>
				<title>Membership</title>
			</Helmet>
			<motion.div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${landingPic})`,
					backgroundAttachment: "fixed",
				}}
				className="h-screen shrink-0 bg-cover bg-center"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
			>
				<div className="text-white">
					<Typography
						variant={isMobile ? "h5" : "h3"}
						fontFamily={HEADER_FONT}
						textAlign={"center"}
					>
						{t("membershipHeader")}
					</Typography>
				</div>
			</motion.div>
			<Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
				<Stack spacing={3}>
					<Typography
						variant="body1"
						fontFamily={BODY_FONT}
						fontSize={isMobile && 13}
					>
						{t("membershipHeaderDescription")}
					</Typography>
					<motion.div
						initial={{ y: 100 }}
						animate={{ y: 0 }}
						transition={{
							duration: 1,
							type: "spring",
						}}
					>
						<Card variant="outlined" sx={{ backgroundColor: "#C1D3CC" }}>
							<CardContent>
								<Stack spacing={3}>
									<Typography
										variant={isMobile ? "h5" : "h4"}
										fontFamily={HEADER_FONT}
									>
										{t("whyMembership")}
									</Typography>
									<Divider variant="middle" />
									<Typography
										variant="body1"
										fontFamily={BODY_FONT}
										fontSize={isMobile && 13}
									>
										{t("whyMembershipDescription")}
									</Typography>
									<Stack spacing={1}>
										{membershipInfo.map((info, key) => {
											return (
												<Accordion key={key} variant="outlined">
													<motion.div
														initial={{ opacity: 0 }}
														animate={{ opacity: 1 }}
														transition={{ staggerChildren: 0.2 }}
													>
														<AccordionSummary expandIcon={<ExpandMoreIcon />}>
															<Typography variant="h6" fontFamily={HEADER_FONT}>
																{info.header}
															</Typography>
														</AccordionSummary>
													</motion.div>
													<Divider variant="middle" />
													<AccordionDetails>
														<Typography
															variant="body1"
															fontFamily={BODY_FONT}
															fontSize={isMobile && 13}
														>
															{info.description}
														</Typography>
													</AccordionDetails>
												</Accordion>
											);
										})}
									</Stack>
									<Typography
										variant="body1"
										fontFamily={BODY_FONT}
										fontSize={isMobile && 13}
									>
										{t("membershipDescription_1")}
									</Typography>
									<Typography
										variant="body1"
										fontFamily={BODY_FONT}
										fontSize={isMobile && 13}
									>
										{t("membershipDescription_2")}
									</Typography>
									<Typography
										variant="body1"
										fontFamily={BODY_FONT}
										fontSize={isMobile && 13}
									>
										{t("membershipDescription_3")}
									</Typography>
									<Typography
										variant="body1"
										fontFamily={BODY_FONT}
										fontSize={isMobile && 13}
									>
										{t("membershipDescription_4")}
									</Typography>
									<Link
										href="https://docs.google.com/forms/d/e/1FAIpQLScb1rBF2W5QMlViT65mWdiBqcXmdl7t-Z9Gty_HYHMUKTnnDQ/viewform"
										sx={{ textDecoration: "none", color: "black" }}
										target="_blank"
										rel="noopener noreferrer"
									>
										<CustomButton>
											<Typography
												fontSize={isMobile && 13}
												textAlign={"center"}
												color={"black"}
												fontWeight={700}
											>
												{t("joinMembership")}
											</Typography>
										</CustomButton>
									</Link>
								</Stack>
							</CardContent>
						</Card>
					</motion.div>
				</Stack>
			</Container>
		</div>
	);
}
