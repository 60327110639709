import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default function SnackbarMessage(props) {
	const { isShowSnackbar, setIsShowSnackbar, message } = props;

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setIsShowSnackbar(false);
	};

	const action = (
		<React.Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<Snackbar
			open={isShowSnackbar}
			autoHideDuration={6000}
			onClose={handleClose}
			message={message}
			action={action}
		/>
	);
}
