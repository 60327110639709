import {
	Typography,
	Card,
	CardContent,
	Container,
	FormControlLabel,
	FormGroup,
	Stack,
	Switch,
	TextField,
	useMediaQuery,
	IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { getError } from "../utils";
import SnackbarMessage from "../components/SnackbarMessage";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Store } from "../Store";
import { BASEURL } from "../utils/constants";
import CustomButton from "../components/general/CustomButton";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return {
				...state,
				loading: false,
				user: action.userPayload,
				message: action.essagePayload,
			};
		case "FETCH_FAIL":
			return { ...state, loading: false, message: action.payload };
		case "UPDATE_REQUEST":
			return { ...state, loadingUpdate: true };
		case "UPDATE_SUCCESS":
			return { ...state, loadingUpdate: false, message: action.payload };
		case "UPDATE_FAIL":
			return { ...state, loadingUpdate: false, message: action.payload };
		default:
			return state;
	}
};

export default function UserBaseInfoScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { id } = useParams();
	const navigate = useNavigate();
	const [isMember, setIsMember] = useState(false);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [{ loading, message, user }, dispatch] = useReducer(reducer, {
		loading: false,
		message: "",
		user: {},
	});
	const { dispatch: ctxDispatch } = useContext(Store);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const { data } = await axios.get(`${BASEURL}/api/users/${id}`);
				dispatch({
					type: "FETCH_SUCCESS",
					userPayload: data.user,
					messagePayload: data.message,
				});
				setIsMember(data.user.isMember);
				console.log(data);
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
			}
		};
		fetchData();
	}, [id]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			dispatch({ type: "UPDATE_REQUEST" });
			const { data } = await axios.post(
				`${BASEURL}/api/users/update-membership`,
				{
					id,
					membership: isMember,
				}
			);
			ctxDispatch({ type: "USER_MEMBERSHIP_UPDATE", payload: isMember });
			dispatch({ type: "UPDATE_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
		} catch (err) {
			dispatch({ type: "UPDATE_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
		}
	};

	return (
		<Container
			maxWidth="md"
			sx={{
				height: "80vh",
				justifyContent: "center",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Helmet>
				<title>User Base Data</title>
			</Helmet>
			{loading ? (
				<PageLoadingBox />
			) : (
				<div>
					<Card variant="outlined">
						<CardContent>
							<Stack spacing={3}>
								<IconButton
									sx={{ maxWidth: "50px" }}
									onClick={() => navigate(-1)}
								>
									<ArrowBackIosNewIcon sx={{ fontSize: isMobile ? 20 : 30 }} />
								</IconButton>
								<TextField
									label="Name"
									defaultValue={user.name}
									disabled
									size="small"
								/>
								<TextField
									label="Email"
									defaultValue={user.email}
									disabled
									size="small"
								/>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												checked={isMember}
												onChange={(e) => setIsMember(e.target.checked)}
											/>
										}
										label="Membership"
									/>
								</FormGroup>
								<CustomButton variant="contained" onClick={handleSubmit}>
									<Typography>Submit</Typography>
								</CustomButton>
							</Stack>
						</CardContent>
					</Card>
				</div>
			)}
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</Container>
	);
}
