import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import "./css/fonts.css";
import { StoreProvider } from "./Store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./i18n";
import { STRIPE_PROMISE } from "./utils/constants";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const stripePromise = loadStripe(STRIPE_PROMISE);
const root = ReactDOM.createRoot(document.getElementById("root"));

const firebaseConfig = {
	apiKey: "AIzaSyCnwddYt7bAN-B5fzaWpr9ZlGjIusCys3g",
	authDomain: "jian-sheng-website.firebaseapp.com",
	projectId: "jian-sheng-website",
	storageBucket: "jian-sheng-website.appspot.com",
	messagingSenderId: "648706647718",
	appId: "1:648706647718:web:c137393ea835561c58f667",
	measurementId: "G-JPMNXWB2B5",
};

// TODO google analytics
const app = initializeApp(firebaseConfig);
getAnalytics(app);

root.render(
	<React.StrictMode>
		<StoreProvider>
			<HelmetProvider>
				<Elements stripe={stripePromise}>
					<App />
				</Elements>
			</HelmetProvider>
		</StoreProvider>
	</React.StrictMode>
);
