import React, { useContext, useEffect, useReducer, useState } from "react";
import { Store } from "../Store";
import { useNavigate } from "react-router-dom";
import {
	CircularProgress,
	Container,
	Divider,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { getError } from "../utils";
import { BASEURL, LOGIN_LINK } from "../utils/constants";
import SnackbarMessage from "../components/SnackbarMessage";
import { motion } from "framer-motion";
import CustomButton from "../components/general/CustomButton";

const reducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_PROFILE_REQUEST":
			return { ...state, updateProfileLoading: true };
		case "UPDATE_PROFILE_SUCCESS":
			return { ...state, updateProfileLoading: false, message: action.payload };
		case "UPDATE_PROFILE_FAIL":
			return { ...state, updateProfileLoading: false, message: action.payload };
		case "UPDATE_PASSWORD_REQUEST":
			return { ...state, updatePasswordLoading: true };
		case "UPDATE_PASSWORD_SUCCESS":
			return {
				...state,
				updatePasswordLoading: false,
				message: action.payload,
			};
		case "UPDATE_PASSWORD_FAIL":
			return {
				...state,
				updatePasswordLoading: false,
				message: action.payload,
			};
		default:
			return state;
	}
};

export default function ProfileScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();
	const [{ updateProfileLoading, updatePasswordLoading, message }, dispatch] =
		useReducer(reducer, {
			updateProfileLoading: false,
			updatePasswordLoading: false,
			message: "",
		});

	useEffect(() => {
		if (!userInfo) {
			navigate(LOGIN_LINK);
		}
	}, [navigate, userInfo]);

	const [name, setName] = useState(userInfo ? userInfo.name : "");
	const [email, setEmail] = useState(userInfo ? userInfo.email : "");
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);

	// const handleTest = async (e) => {
	// 	e.preventDefault();
	// 	try {
	// 		const { data } = await axios.post(
	// 			`${BASEURL}/api/users/testing`,
	// 			undefined,
	// 			{ withCredentials: true }
	// 		);
	// 		console.log("tested");
	// 	} catch (err) {
	// 		dispatch({ type: "UPDATE_PROFILE_FAIL", payload: getError(err) });
	// 		setIsShowSnackbar(true);
	// 		if (err.response && err.response.status === 403) {
	// 			setTimeout(function () {
	// 				ctxDispatch({ type: "USER_SIGNOUT" });
	// 			}, 1000);
	// 		}
	// 	}
	// };

	const isValidPassword = (password) => {
		const regex = /^(?=.*[A-Z]).{8,}$/;
		return regex.test(password);
	};

	const handleUpdateProfile = async (e) => {
		e.preventDefault();
		try {
			dispatch({ type: "UPDATE_PROFILE_REQUEST" });
			const { data } = await axios.post(
				`${BASEURL}/api/users/profile`,
				{
					name: name,
				},
				{
					withCredentials: true,
				}
			);
			ctxDispatch({ type: "USER_UPDATE", payload: name });
			dispatch({ type: "UPDATE_PROFILE_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
		} catch (err) {
			dispatch({ type: "UPDATE_PROFILE_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	const handleUpdatePassword = async (e) => {
		e.preventDefault();
		dispatch({ type: "UPDATE_PASSWORD_REQUEST" });
		if (newPassword !== repeatPassword) {
			dispatch({
				type: "UPDATE_PASSWORD_FAIL",
				payload: "Password does not match. Try again.",
			});
			setIsShowSnackbar(true);
			return;
		}
		if (!isValidPassword(newPassword)) {
			dispatch({
				type: "UPDATE_PASSWORD_FAIL",
				payload:
					"Please ensure password has at least 8 characters and at least 1 uppercase.",
			});
			setIsShowSnackbar(true);
			return;
		}

		try {
			const { data } = await axios.post(
				`${BASEURL}/api/users/password`,
				{
					oldPassword: oldPassword,
					newPassword: newPassword,
				},
				{ withCredentials: true }
			);
			dispatch({ type: "UPDATE_PASSWORD_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
		} catch (err) {
			dispatch({ type: "UPDATE_PASSWORD_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	return (
		<Container
			maxWidth="sm"
			sx={{
				minHeight: "600px",
				display: "flex",
				justifyContent: "center",
				marginTop: "25px",
				mb: 3,
			}}
		>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
				style={{ width: "100%" }}
			>
				<Stack>
					<Typography mb={1} variant={isMobile ? "h5" : "h4"}>
						Profile Update
					</Typography>
					<form
						onSubmit={handleUpdateProfile}
						style={{ height: "150px", width: "100%" }}
					>
						<Stack
							height={"100%"}
							width={"100%"}
							justifyContent={"space-between"}
						>
							<TextField
								required
								type="text"
								label="Name"
								defaultValue={name}
								onChange={(e) => setName(e.target.value)}
								fullWidth
								size="small"
							/>
							<TextField
								disabled
								type="email"
								label="Email"
								defaultValue={email}
								onChange={(e) => setEmail(e.target.value)}
								fullWidth
								size="small"
							/>

							<CustomButton type={"submit"}>
								<Typography>UPDATE PROFILE</Typography>
								{updateProfileLoading && (
									<CircularProgress size={15} sx={{ ml: 1 }} />
								)}
							</CustomButton>
						</Stack>
					</form>
					<Divider sx={{ marginTop: 4 }} />
					<form
						onSubmit={handleUpdatePassword}
						style={{ marginTop: "20px", height: "200px" }}
					>
						<Typography mb={1} variant={isMobile ? "h5" : "h4"}>
							Password Update
						</Typography>
						<Stack
							sx={{ backgroundColor: "white" }}
							height={"100%"}
							justifyContent={"space-between"}
						>
							<TextField
								required
								value={oldPassword}
								type="password"
								label="Old password"
								onChange={(e) => setOldPassword(e.target.value)}
								size="small"
							/>
							<TextField
								required
								value={newPassword}
								type="password"
								label="New password"
								onChange={(e) => setNewPassword(e.target.value)}
								size="small"
							/>
							<TextField
								required
								value={repeatPassword}
								type="password"
								label="Repeat password"
								onChange={(e) => setRepeatPassword(e.target.value)}
								size="small"
							/>
							<CustomButton type={"submit"}>
								<Typography>UPDATE PASSWORD</Typography>
								{updatePasswordLoading && (
									<CircularProgress size={15} sx={{ ml: 1 }} />
								)}
							</CustomButton>
							{/* <button onClick={handleTest}>test backend</button> */}
						</Stack>
					</form>
				</Stack>
			</motion.div>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</Container>
	);
}
