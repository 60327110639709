import {
	CardContent,
	CardMedia,
	Grid,
	Typography,
	Button,
	Modal,
	Box,
	Stack,
	CircularProgress,
	Divider,
	useMediaQuery,
} from "@mui/material";
import React, { useContext, useReducer, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Store } from "../Store";
import SnackbarMessage from "./SnackbarMessage";
import {
	BASEURL,
	NEUTRALS_BORDER,
	NEUTRALS_FOREGROUND,
	UTILITY_ERROR,
	UTILITY_INFO,
} from "../utils/constants";
import { getError } from "../utils";
import { getStorage, ref, deleteObject } from "firebase/storage";
import CustomButton from "./general/CustomButton.js";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const reducer = (state, action) => {
	switch (action.type) {
		case "DELETE_REQUEST":
			return { ...state, loadingDelete: true };
		case "DELETE_SUCCESS":
			return { ...state, loadingDelete: false, message: action.payload };
		case "DELETE_FAIL":
			return { ...state, loadingDelete: false, message: action.payload };
		default:
			return state;
	}
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: NEUTRALS_FOREGROUND,
	border: `1px solid ${NEUTRALS_BORDER}`,
	boxShadow: 24,
	borderRadius: "5px",
	p: 4,
};

export default function AdminProductListing({ products }) {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { dispatch: ctxDispatch } = useContext(Store);
	const [openModal, setOpenModal] = useState(false);
	const [productId, setProductId] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const navigate = useNavigate();
	const [{ loadingDelete, message }, dispatch] = useReducer(reducer, {
		loadingDelete: false,
		message: "",
	});
	const firebaseStorage = getStorage();

	const handleClickEdit = (link) => {
		navigate(link);
	};

	const handleClickDelete = (pId) => {
		setProductId(pId);
		setOpenModal(true);
	};

	const handleDelete = async () => {
		try {
			dispatch({ type: "DELETE_REQUEST" });
			const { data } = await axios.delete(
				`${BASEURL}/api/products/${productId}`,
				{
					withCredentials: true,
				}
			);

			for (let i = 0; i < data.imageLink.length; i++) {
				const imageRef = ref(firebaseStorage, data.imageLink[i]);
				await deleteObject(imageRef);
			}

			dispatch({ type: "DELETE_SUCCESS", payload: data.message });
			setOpenModal(false);
			setIsShowSnackbar(true);
			setTimeout(function () {
				window.location.reload();
			}, 500);
		} catch (err) {
			dispatch({ type: "DELETE_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	return (
		<div>
			{products.map((product, key) => {
				return (
					<div key={key}>
						<CardContent>
							<Grid container spacing={isMobile ? 1 : 0}>
								<Grid
									item
									sm={3}
									xs={12}
									sx={{
										display: isMobile && "flex",
										justifyContent: isMobile && "center",
									}}
								>
									<CardMedia
										sx={{ height: 150, width: 150 }}
										image={product.image[0]}
										title="image"
									/>
								</Grid>
								<Grid
									item
									sm={7}
									xs={12}
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}
								>
									<Typography
										variant="caption"
										textAlign={isMobile && "center"}
									>
										{product.brand}
									</Typography>
									<Typography textAlign={isMobile && "center"}>
										<Link
											to={`/product/${product.slug}`}
											style={{ color: "black" }}
										>
											{product.name}
										</Link>
									</Typography>
								</Grid>
								<Grid
									item
									sm={2}
									xs={12}
									sx={{
										display: "flex",
										justifyContent: isMobile ? "space-around" : "space-around",
										alignItems: "center",
									}}
								>
									<Button
										variant="outlined"
										onClick={() =>
											handleClickEdit(`/editproduct/${product._id}`)
										}
										color="primary"
										sx={{ backgroundColor: UTILITY_INFO }}
									>
										<EditIcon />
									</Button>
									<Button
										variant="outlined"
										onClick={() => handleClickDelete(product._id)}
										color="error"
										sx={{ backgroundColor: UTILITY_ERROR }}
									>
										<DeleteIcon />
									</Button>
								</Grid>
							</Grid>
						</CardContent>
						{key !== products.length - 1 && <Divider variant="middle" />}
					</div>
				);
			})}
			<Modal open={openModal} onClose={() => setOpenModal(false)}>
				<div className="group">
					<Box sx={style} className="w-[100%] sm:w-[400px]">
						<Stack spacing={3}>
							<div className="flex justify-center group-hover:animate-bounce">
								<WarningAmberIcon fontSize="large" />
							</div>
							<Typography>
								Are you sure you want to delete this product?
							</Typography>
							<Grid container spacing={1}>
								<Grid item xs={3}>
									<CustomButton
										disabled={loadingDelete}
										onClick={handleDelete}
										backgroundColor={UTILITY_ERROR}
									>
										Yes
									</CustomButton>
								</Grid>
								<Grid item xs={3}>
									<CustomButton
										disabled={loadingDelete}
										onClick={() => setOpenModal(false)}
										backgroundColor={UTILITY_INFO}
									>
										No
									</CustomButton>
								</Grid>
								{loadingDelete ? (
									<Grid item xs={3}>
										<CircularProgress size={25} />
									</Grid>
								) : (
									<></>
								)}
							</Grid>
						</Stack>
					</Box>
				</div>
			</Modal>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</div>
	);
}
