import React, { useContext, useEffect, useState } from "react";
import {
	Menu,
	MenuItem,
	Typography,
	Button,
	AppBar,
	Toolbar,
	Drawer,
	List,
	ListItem,
	ListItemText,
	IconButton,
} from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Link } from "react-router-dom";
import * as CONSTANTS from "../../utils/constants.js";
import MenuIcon from "@mui/icons-material/Menu";
import { Store } from "../../Store.js";
import axios from "axios";

export default function MobileNavBar({ handleLanguage, pages }) {
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [visible, setVisible] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);
	const openMobileSetting = Boolean(anchorEl);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	const signoutHandler = async () => {
		try {
			ctxDispatch({ type: "USER_SIGNOUT" });
			const { data } = await axios.post(
				`${CONSTANTS.BASEURL}/api/users/logout`,
				undefined,
				{ withCredentials: true }
			);
			console.log(data.message);
		} catch (err) {
			console.log(err.message);
		}
	};

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
			setPrevScrollPos(currentScrollPos);
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [visible, prevScrollPos]);

	return (
		<div>
			<AppBar
				sx={{
					background: "linear-gradient(to bottom, #FCE7CC, #E9D9B7)",
					visibility: visible ? "visible" : "hidden",
					transition: "top 0.5s",
				}}
			>
				<Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
					<IconButton
						size="large"
						edge="start"
						aria-label="menu"
						sx={{ mr: 2 }}
						onClick={handleDrawerOpen}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						variant="h6"
						component="div"
						sx={{ fontSize: 17 }}
						fontFamily={CONSTANTS.HEADER_FONT}
					>
						<Link
							to={CONSTANTS.HOME_LINK}
							style={{
								textDecoration: "none",
								color: "black",
							}}
						>
							JIAN SHENG WELLNESS
						</Link>
					</Typography>
					{userInfo ? (
						<div>
							<IconButton
								onClick={handleClick}
								style={{
									color: "black",
								}}
							>
								<PermIdentityIcon sx={{ fontSize: "30px" }} />
							</IconButton>
							<Menu
								anchorEl={anchorEl}
								open={openMobileSetting}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuItem>
									<Link
										to={CONSTANTS.PROFILE_LINK}
										onClick={handleClose}
										style={{ textDecoration: "none", color: "black" }}
									>
										{userInfo.name}
									</Link>
								</MenuItem>
								<MenuItem>
									<Link
										to="/orderhistory"
										onClick={handleClose}
										style={{ textDecoration: "none", color: "black" }}
									>
										Order History
									</Link>
								</MenuItem>
								{userInfo.isAdmin && (
									<div>
										<MenuItem>
											<Link
												style={{ color: "black", textDecoration: "none" }}
												to={CONSTANTS.DASHBOARD_LINK}
												onClick={handleClose}
											>
												Dashboard
											</Link>
										</MenuItem>
										<MenuItem>
											<Link
												style={{ color: "black", textDecoration: "none" }}
												to={CONSTANTS.ADMIN_PRODUCTS_LINK}
												onClick={handleClose}
											>
												View Products
											</Link>
										</MenuItem>
										<MenuItem>
											<Link
												style={{ color: "black", textDecoration: "none" }}
												to={CONSTANTS.USER_BASE_LINK}
												onClick={handleClose}
											>
												User Base
											</Link>
										</MenuItem>
									</div>
								)}
								<Link
									to="#signout"
									onClick={signoutHandler}
									style={{ color: "black", textDecoration: "none" }}
								>
									<MenuItem>Logout</MenuItem>
								</Link>
							</Menu>
						</div>
					) : (
						<Button color="inherit">
							<Typography
								sx={{ color: "black" }}
								fontFamily={CONSTANTS.HEADER_FONT}
							>
								<Link
									to={CONSTANTS.LOGIN_LINK}
									style={{ textDecoration: "none", color: "black" }}
								>
									Login
								</Link>
							</Typography>
						</Button>
					)}
				</Toolbar>
			</AppBar>
			<Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerClose}>
				<List sx={{ position: "static" }}>
					{pages.map((page, key) => {
						return (
							<ListItem
								key={key}
								component={Link}
								to={page.link}
								sx={{ textDecoration: "none", color: "black" }}
							>
								<ListItemText primary={page.name} onClick={handleDrawerClose} />
							</ListItem>
						);
					})}
					<ListItem
						component={Link}
						to={"/cart"}
						sx={{ textDecoration: "none", color: "black" }}
					>
						<ListItemText primary={"CART"} onClick={handleDrawerClose} />
					</ListItem>
					<ListItem
						component={Link}
						sx={{ textDecoration: "none", color: "black" }}
					>
						<ListItemText primary={"LANGUAGE"} onClick={handleLanguage} />
					</ListItem>
				</List>
			</Drawer>
		</div>
	);
}
