import {
	CircularProgress,
	Container,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
	Box,
	IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import SnackbarMessage from "../components/SnackbarMessage";
import { getError } from "../utils";
import { BASEURL } from "../utils/constants";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomButton from "../components/general/CustomButton";
import CustomInput from "../components/general/CustomInput";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const reducer = (state, action) => {
	switch (action.type) {
		case "RESET_REQUEST":
			return { ...state, resetLoading: true };
		case "RESET_SUCCESS":
			return { ...state, resetLoading: false, message: action.payload };
		case "RESET_FAIL":
			return { ...state, resetLoading: false, message: action.payload };
		default:
			return state;
	}
};

const forgetPasswordBackground =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Flogin_image.png?alt=media&token=c173c478-05fb-463f-8289-1778f2940a68";

export default function ForgetPasswordScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [{ resetLoading, message }, dispatch] = useReducer(reducer, {
		resetLoading: false,
		message: "",
	});

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			dispatch({ type: "RESET_REQUEST" });
			const { data } = await axios.post(
				`${BASEURL}/api/users/forget-password`,
				{
					email: email,
				}
			);
			dispatch({ type: "RESET_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
		} catch (err) {
			dispatch({ type: "RESET_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
		}
	};

	return (
		<div
			style={{
				backgroundImage: `url(${forgetPasswordBackground})`,
				backgroundSize: "cover",
				minHeight: "90vh",
			}}
		>
			<Container
				maxWidth="sm"
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					minHeight: "70vh",
					zIndex: 1,
				}}
			>
				<Helmet>
					<title>Forget Password</title>
				</Helmet>
				<Stack spacing={3}>
					<Box
						sx={{
							width: isMobile ? "300px" : "500px",
							height: isMobile ? "150px" : "200px",
							backgroundColor: "#FCE7CC",
							borderRadius: 2,
							p: 2,
						}}
					>
						<Container
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: "20px",
							}}
						>
							<div
								style={{ display: "flex", alignItems: "center", gap: "10px" }}
							>
								<IconButton
									onClick={() => navigate(-1)}
									sx={{ color: "black" }}
								>
									<ArrowBackIosNewIcon sx={{ fontSize: "25px" }} />
								</IconButton>
								<Typography
									variant={isMobile ? "h5" : "h4"}
									fontFamily={"inherit"}
								>
									Forget password
								</Typography>
							</div>
							<form onSubmit={submitHandler} className="flex flex-col gap-2">
								<CustomInput
									icon={<AlternateEmailIcon />}
									value={email}
									setValue={setEmail}
									type={"email"}
									placeholder={"Email"}
									name={"email"}
								/>
								<Grid sx={{ display: "flex", alignItems: "center" }}>
									<CustomButton
										type="submit"
										backgroundColor={"#FCE7CC"}
										hoverBackground={"#f9d09c"}
									>
										Submit
									</CustomButton>
									{resetLoading && (
										<CircularProgress
											size={20}
											color="inherit"
											sx={{ ml: 1 }}
										/>
									)}
								</Grid>
							</form>
						</Container>
					</Box>
				</Stack>
				<SnackbarMessage
					isShowSnackbar={isShowSnackbar}
					setIsShowSnackbar={setIsShowSnackbar}
					message={message}
				/>
			</Container>
		</div>
	);
}
