import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import {
	Card,
	CardContent,
	CardMedia,
	Container,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
	Divider,
	CircularProgress,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import SnackbarMessage from "../components/SnackbarMessage";
import ShippingStepper from "../components/ShippingStepper";
import axios from "axios";
import { BASEURL, CART_LINK, PAYMENT_LINK } from "../utils/constants";
import { motion } from "framer-motion";
import { getError } from "../utils";
import CustomButton from "../components/general/CustomButton";

const reducer = (state, action) => {
	switch (action.type) {
		case "CREATE_REQUEST":
			return { ...state, loading: true };
		case "CREATE_SUCCESS":
			return { ...state, loading: false };
		case "CREATE_FAIL":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function OrderPlacementScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const [shippingPrice, setShippingPrice] = useState(0);
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const {
		userInfo,
		cart: { shippingAddress, paymentMethod, cartItems },
	} = state;
	const [{ loading, message }, dispatch] = useReducer(reducer, {
		loading: false,
		message: "",
	});

	useEffect(() => {
		if (!paymentMethod) {
			// TODO set shipping price
			setShippingPrice(0);
			navigate(PAYMENT_LINK);
		}
		if (cartItems.length <= 0) {
			navigate(CART_LINK);
		}
	}, [paymentMethod, navigate, cartItems]);

	const getSubtotal = () => {
		return cartItems.reduce(
			(sum, product) =>
				sum +
				(product.discountedPrice > 0
					? product.discountedPrice
					: product.price) *
					product.quantity,
			0
		);
	};

	const getDiscount = () => {
		if (userInfo.isMember) {
			return getSubtotal() * 0.1;
		}
		return 0;
	};

	const getTotal = () => {
		return getSubtotal() - getDiscount() + shippingPrice;
	};

	const placeOrderHandler = async () => {
		try {
			dispatch({ type: "CREATE_REQUEST" });
			const { data } = await axios.post(
				`${BASEURL}/api/orders`,
				{
					orderItems: cartItems,
					shippingAddress: shippingAddress,
					paymentMethod: paymentMethod,
					itemsPrice: getSubtotal(),
					discount: getDiscount(),
					shippingPrice: shippingPrice,
					taxPrice: 0,
					totalPrice: getTotal(),
				},
				{
					withCredentials: true,
				}
			);
			ctxDispatch({ type: "CART_CLEAR" });
			dispatch({ type: "CREATE_SUCCESS" });
			localStorage.removeItem("cartItems");
			navigate(`/order/${data.order._id}`);
		} catch (err) {
			dispatch({ type: "CREATE_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
		}
	};

	return (
		<div>
			<Helmet>
				<title>Order Placement</title>
			</Helmet>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
			>
				<Container
					maxWidth="md"
					sx={{
						display: "flex",
						minHeight: "600px",
						justifyContent: "center",
						pt: 5,
						pb: 5,
					}}
				>
					<Stack width={"80%"} spacing={3}>
						<ShippingStepper activeStep={3} />
						<Typography variant={isMobile ? "h5" : "h4"}>
							Order Review
						</Typography>
						<Card variant="outlined">
							<CardContent>
								<Typography
									variant={isMobile ? "h6" : "h5"}
									sx={{ textDecoration: "underline" }}
								>
									Shipping
								</Typography>
								<Typography variant="body1">
									<strong>Deliver Address</strong>
								</Typography>
								<Typography variant="body2">
									{shippingAddress.fullName}
								</Typography>
								<Typography variant="body2">
									{shippingAddress.address}, {shippingAddress.country} (
									{shippingAddress.postalCode}), {shippingAddress.city}
								</Typography>
							</CardContent>
							<Divider />
							<CardContent>
								<Typography
									variant={isMobile ? "h6" : "h5"}
									sx={{ textDecoration: "underline" }}
								>
									Payment Method
								</Typography>
								<Typography variant={isMobile ? "caption" : "body2"}>
									{paymentMethod}
								</Typography>
							</CardContent>
							<Divider />
							<CardContent>
								<Typography
									variant={isMobile ? "h6" : "h5"}
									sx={{ textDecoration: "underline" }}
								>
									Cart Items
								</Typography>
								<Stack spacing={2}>
									{cartItems.map((item, key) => {
										return (
											<Grid container key={key}>
												<Grid
													item
													sm={2}
													xs={3}
													sx={{ backgroundColor: "white" }}
												>
													<CardMedia
														component="img"
														src={item.image[0]}
														alt={item.name}
														sx={{ borderRadius: 5 }}
													/>
												</Grid>
												<Grid
													item
													sm={3}
													xs={8}
													sx={{
														marginLeft: "10px",
													}}
												>
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															justifyContent: "space-between",
															height: "100%",
														}}
													>
														<Typography
															variant={"h6"}
															fontSize={isMobile ? 10 : 15}
														>
															{item.name} {isMobile && `x${item.quantity}`}
														</Typography>
														{isMobile && (
															<div
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																	alignItems: "center",
																}}
															>
																<Typography variant="caption">
																	$
																	{item.discountedPrice > 0
																		? item.discountedPrice
																		: item.price}
																</Typography>
															</div>
														)}
													</div>
												</Grid>
												{!isMobile && (
													<>
														<Grid
															item
															sm={3}
															sx={{
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}
														>
															<Typography>{item.quantity}</Typography>
														</Grid>
														<Grid
															item
															sm={2}
															sx={{
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}
														>
															<Typography>
																$
																{item.discountedPrice > 0
																	? item.discountedPrice
																	: item.price}
															</Typography>
														</Grid>
													</>
												)}
											</Grid>
										);
									})}
								</Stack>
							</CardContent>
							<Divider />
							<CardContent>
								<Typography
									variant={isMobile ? "h6" : "h5"}
									sx={{ textDecoration: "underline" }}
								>
									Order Summary
								</Typography>
								<Stack>
									<Grid container>
										<Grid item xs={6} sm={3}>
											<Typography>Subtotal:</Typography>
										</Grid>
										<Grid item xs={6} sm={6}>
											<Typography>${getSubtotal().toFixed(2)}</Typography>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={6} sm={3}>
											<Typography>Member discount:</Typography>
										</Grid>
										<Grid item xs={6} sm={6}>
											<Typography>${getDiscount().toFixed(2)}</Typography>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={6} sm={3}>
											<Typography>Shipping:</Typography>
										</Grid>
										<Grid item xs={6} sm={6}>
											<Typography>${shippingPrice.toFixed(2)}</Typography>
										</Grid>
									</Grid>
									<Grid container>
										<Grid item xs={6} sm={3}>
											<Typography>Total:</Typography>
										</Grid>
										<Grid item xs={6} sm={6}>
											<Typography>${getTotal().toFixed(2)}</Typography>
										</Grid>
									</Grid>
								</Stack>
							</CardContent>
						</Card>
						<CustomButton onClick={placeOrderHandler}>
							Place Order
							{loading && <CircularProgress sx={{ ml: 2 }} size={"15px"} />}
						</CustomButton>
					</Stack>
				</Container>
			</motion.div>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</div>
	);
}
