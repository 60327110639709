import React, { useContext, useEffect, useReducer, useState } from "react";
import { Store } from "../Store";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
	Chip,
	Container,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
	Grid,
	Divider,
} from "@mui/material";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import ErrorMessage from "../components/ErrorMessage";
import NoOrderMessageBox from "../components/NoOrderMessageBox";
import { motion, AnimatePresence } from "framer-motion";
import { BASEURL } from "../utils/constants";
import { getError } from "../utils";
import SnackbarMessage from "../components/SnackbarMessage";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return {
				...state,
				orders: action.payload,
				loading: false,
				totalOrders: action.totalOrdersPayload,
			};
		case "FETCH_FAIL":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function OrderHistoryScreen() {
	const navigate = useNavigate();
	const isMobile = useMediaQuery("(max-width:600px)");
	const { dispatch: ctxDispatch } = useContext(Store);
	const [page, setPage] = useState(1);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);

	const [{ loading, message, orders, totalOrders }, dispatch] = useReducer(
		reducer,
		{
			loading: true,
			message: "",
			orders: [],
			totalOrders: 0,
		}
	);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: "FETCH_REQUEST" });
			try {
				const { data } = await axios.get(
					`${BASEURL}/api/orders/history/${page}`,
					{
						withCredentials: true,
					}
				);
				dispatch({
					type: "FETCH_SUCCESS",
					payload: data.orders,
					totalOrdersPayload: data.totalOrders,
				});
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
				setIsShowSnackbar(true);
				if (err.response && err.response.status === 403) {
					setTimeout(function () {
						ctxDispatch({ type: "USER_SIGNOUT" });
					}, 1000);
				}
			}
		};
		fetchData();
		// TODO should ctxDispatch be added
	}, [page, ctxDispatch]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleMobileOnClick = (orderId) => {
		navigate(`/order/${orderId}`);
	};

	const MobileOrderHistory = () => {
		return (
			<TableContainer component={Paper} variant="outlined">
				<Table>
					<TableBody>
						{orders &&
							orders.map((order, key) => {
								return (
									<TableRow
										key={key}
										onClick={() => handleMobileOnClick(order._id)}
									>
										<TableCell>
											<Stack spacing={1}>
												<Typography variant="caption">
													{order._id.toUpperCase()}
												</Typography>
												<Divider />
												<Grid container>
													<Grid
														item
														xs={4}
														sx={{ display: "flex", alignItems: "center" }}
													>
														{order.createdAt.substring(0, 10)}
													</Grid>
													<Grid
														item
														xs={4}
														sx={{ display: "flex", alignItems: "center" }}
													>
														<Typography>
															${order.totalPrice.toFixed(2)}
														</Typography>
													</Grid>
													<Grid item xs={4}>
														<Stack spacing={1}>
															{order.isPaid ? (
																<Chip
																	label="Paid"
																	color="primary"
																	size="small"
																/>
															) : (
																<Chip
																	label="Not Paid"
																	color="warning"
																	size="small"
																/>
															)}
															{order.isCancelled ? (
																<Chip
																	label="Order Cancelled"
																	color="info"
																	size="small"
																/>
															) : order.isDelivered ? (
																<Chip
																	label="Delivered"
																	color="success"
																	size="small"
																/>
															) : (
																<Chip
																	label="Not Delivered"
																	color="default"
																	size="small"
																/>
															)}
														</Stack>
													</Grid>
												</Grid>
											</Stack>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	return (
		<div>
			<Helmet>
				<title>Order History</title>
			</Helmet>
			{loading ? (
				<PageLoadingBox />
			) : message ? (
				<ErrorMessage message={message} />
			) : (
				<Container sx={{ minHeight: "600px", pt: 5, pb: 5 }}>
					<Stack spacing={3}>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1 }}
						>
							<Typography variant="h4">Order History</Typography>
						</motion.div>
						{orders.length <= 0 ? (
							<NoOrderMessageBox />
						) : isMobile ? (
							<MobileOrderHistory />
						) : (
							<div>
								<TableContainer component={Paper} variant="outlined">
									<Table sx={{ minWidth: 600 }}>
										<TableHead>
											<TableRow>
												<TableCell align="center">Order ID</TableCell>
												<TableCell align="center">Date</TableCell>
												<TableCell align="center">Status</TableCell>
												<TableCell align="center">Total Price</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<AnimatePresence>
												{orders &&
													orders.map((order, key) => {
														return (
															<TableRow key={key}>
																<TableCell align="center">
																	<motion.div
																		initial={{ opacity: 0, y: -50 }}
																		animate={{ opacity: 1, y: 0 }}
																		transition={{
																			duration: 0.5,
																			delay: key * 0.1,
																		}}
																	>
																		<Typography
																			sx={{ textDecoration: "underline" }}
																		>
																			<Link to={`/order/${order._id}`}>
																				{order._id.toUpperCase()}
																			</Link>
																		</Typography>
																	</motion.div>
																</TableCell>
																<TableCell align="center">
																	<motion.div
																		key={key}
																		initial={{ opacity: 0, y: -50 }}
																		animate={{ opacity: 1, y: 0 }}
																		transition={{
																			duration: 0.5,
																			delay: key * 0.1,
																		}}
																	>
																		{order.createdAt.substring(0, 10)}
																	</motion.div>
																</TableCell>
																<TableCell align="center">
																	<motion.div
																		key={key}
																		initial={{ opacity: 0, y: -50 }}
																		animate={{ opacity: 1, y: 0 }}
																		transition={{
																			duration: 0.5,
																			delay: key * 0.1,
																		}}
																	>
																		<Stack spacing={1}>
																			{order.isCancelled ? (
																				<Chip
																					label="Order Cancelled"
																					color="info"
																				/>
																			) : order.isPaid ? (
																				<Chip label="Paid" color="primary" />
																			) : (
																				<Chip
																					label="Not Paid"
																					color="warning"
																				/>
																			)}

																			{order.isCancelled ? (
																				<></>
																			) : order.isDelivered ? (
																				<Chip
																					label="Delivered"
																					color="success"
																				/>
																			) : (
																				<Chip
																					label="Not Delivered"
																					color="default"
																				/>
																			)}
																		</Stack>
																	</motion.div>
																</TableCell>
																<TableCell align="center">
																	<motion.div
																		key={key}
																		initial={{ opacity: 0, y: -50 }}
																		animate={{ opacity: 1, y: 0 }}
																		transition={{
																			duration: 0.5,
																			delay: key * 0.1,
																		}}
																	>
																		${order.totalPrice.toFixed(2)}
																	</motion.div>
																</TableCell>
															</TableRow>
														);
													})}
											</AnimatePresence>
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						)}
						<Pagination
							count={Math.ceil(totalOrders / 10)}
							page={page}
							onChange={handleChangePage}
							sx={{
								mt: 2,
								display: "flex",
								justifyContent: "center",
							}}
						/>
					</Stack>
					<SnackbarMessage
						isShowSnackbar={isShowSnackbar}
						setIsShowSnackbar={setIsShowSnackbar}
						message={message}
					/>
				</Container>
			)}
		</div>
	);
}
