import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { BODY_FONT, HEADER_FONT } from "../../utils/constants";

export default function MobileAbout({ content }) {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<div>
			{content.map((cont, key) => {
				return (
					<Accordion
						key={key}
						expanded={expanded === `panel${key}`}
						onChange={handleChange(`panel${key}`)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel${key}bh-content`}
							id={`panel${key}bh-header`}
						>
							<Typography variant="h5" fontFamily={HEADER_FONT}>
								{t(cont.header)}
							</Typography>
						</AccordionSummary>

						<Divider />
						<AccordionDetails>
							<Typography variant="body2" fontFamily={BODY_FONT}>
								{t(cont.content)}
							</Typography>
						</AccordionDetails>
					</Accordion>
				);
			})}
		</div>
	);
}
