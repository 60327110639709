import { Alert, AlertTitle, Container } from "@mui/material";
import React from "react";

export default function ErrorMessage({ message }) {
	return (
		<Container sx={{ height: "70vh", display: "flex", alignItems: "center" }}>
			<Alert
				severity="error"
				sx={{
					height: "200px",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#FFFFFF",
				}}
			>
				<AlertTitle>Server Error: {message}</AlertTitle>
				Please try again later
			</Alert>
		</Container>
	);
}
