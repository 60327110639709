import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { Helmet } from "react-helmet-async";
import {
	Container,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import EmptyCartMessageBox from "../components/EmptyCartMessageBox";
import CartProductList from "../components/cart/CartProductList";
import { motion } from "framer-motion";
import CustomButton from "../components/general/CustomButton";

export default function CartScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const {
		cart: { cartItems },
	} = state;

	const checkoutHandler = () => {
		navigate("/login?redirect=/shipping");
	};

	const getSubtotal = () => {
		return cartItems.reduce(
			(sum, product) =>
				sum +
				(product.discountedPrice > 0
					? product.discountedPrice
					: product.price) *
					product.quantity,
			0
		);
	};

	return (
		<div>
			<Helmet>
				<title>Cart</title>
			</Helmet>
			{cartItems.length === 0 ? (
				<EmptyCartMessageBox />
			) : (
				<div style={{ minHeight: "100vh" }}>
					<Container sx={{ pt: 5, pb: 5 }}>
						<Grid container spacing={5}>
							<Grid item sm={8} xs={12}>
								<Typography variant={isMobile ? "h6" : "h5"}>
									My cart
								</Typography>
								<CartProductList cartItems={cartItems} />
							</Grid>
							<Grid item sm={4} xs={12}>
								<Stack mt={1} spacing={3}>
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ duration: 1 }}
									>
										<Typography variant={isMobile ? "h6" : "h5"}>
											Payment
										</Typography>
									</motion.div>
									<Grid container justifyContent={"space-between"}>
										<Typography variant="body2">Subtotal</Typography>
										<Typography variant="body2">
											S${getSubtotal().toFixed(2)}
										</Typography>
									</Grid>
									<CustomButton onClick={checkoutHandler}>
										<Typography>CHECK OUT</Typography>
									</CustomButton>
								</Stack>
							</Grid>
						</Grid>
					</Container>
				</div>
			)}
		</div>
	);
}
