import * as CONSTANTS from "./constants.js";

export const getName = (link) => {
	switch (link) {
		case CONSTANTS.HOME_LINK:
			return CONSTANTS.HOME;

		case CONSTANTS.ABOUT_LINK:
			return CONSTANTS.ABOUT;

		case CONSTANTS.MEMBERSHIP_LINK:
			return CONSTANTS.MEMBERSHIP;

		case CONSTANTS.SHOP_LINK:
			return CONSTANTS.SHOP;

		case CONSTANTS.CONTACT_LINK:
			return CONSTANTS.CONTACT;

		default:
			return "";
	}
};

export const testimonials = [
	{
		name: "Sarah L.",
		text: "I've been a customer of JianSheng Wellness for over a year. Their products and herbal teas have greatly improved my daily routine, helping me stay balanced and energized. I highly recommend their health soups too!",
	},
	{
		name: "Michael T.",
		text: "JianSheng Wellness improved my health routine. Their soothing teas have helped me manage stress and improve my well-being. Thank you for such high-quality products!",
	},
	{
		name: "Ming Hao C.",
		text: "JianSheng Wellness is my go-to for holistic health products. Their herbal teas and health soups have boosted my energy and digestion. The friendly and knowledgeable customer service is a plus. Highly recommend!",
	},
	{
		name: "David T.",
		text: "JianSheng Traditional Chinese Medicine products are top-notch, and their teas are both delicious and effective. I've noticed a big improvement in my overall wellness since I started using their products.",
	},
	{
		name: "Zhiqi O.",
		text: "JianSheng health soups and herbal teas have become essential in my daily routine. The quality and authenticity of their TCM offerings are impressive. Highly recommend to anyone seeking natural health solutions.",
	},
];
