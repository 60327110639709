import {
	Button,
	Typography,
	Grid,
	useMediaQuery,
	CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { getError } from "../utils";
import SnackbarMessage from "../components/SnackbarMessage";
import { Helmet } from "react-helmet-async";
import { BASEURL } from "../utils/constants";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PasswordIcon from "@mui/icons-material/Password";
import CustomInput from "../components/general/CustomInput";

const loginBackground =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Flogin_image.png?alt=media&token=c173c478-05fb-463f-8289-1778f2940a68";

const reducer = (state, action) => {
	switch (action.type) {
		case "SEND_REQUEST":
			return { ...state, loading: true };
		case "SEND_SUCCESS":
			return { ...state, loading: false, message: action.payload };
		case "SEND_FAIL":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function LoginScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;

	const navigate = useNavigate();
	const { search } = useLocation();
	const redirectUrl = new URLSearchParams(search).get("redirect");
	const redirect = redirectUrl ? redirectUrl : "/";

	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [{ loading, message }, dispatch] = useReducer(reducer, {
		loading: false,
		message: "",
	});

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			dispatch({ type: "SEND_REQUEST" });
			const { data } = await axios.post(
				`${BASEURL}/api/users/login`,
				{
					email: userEmail,
					password: userPassword,
				},
				{ withCredentials: true }
			);
			ctxDispatch({ type: "USER_SIGNIN", payload: data });
			dispatch({ type: "SEND_SUCCESS" });
			navigate(redirect || "/");
		} catch (e) {
			dispatch({ type: "SEND_FAIL", payload: getError(e) });
			setIsShowSnackbar(true);
		}
	};

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, redirect, userInfo]);

	return (
		<div
			style={{
				backgroundImage: `url(${loginBackground})`,
				backgroundSize: "cover",
			}}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "90vh",
					zIndex: 1,
				}}
			>
				<Helmet>
					<title>Login</title>
				</Helmet>
				<div className="bg-[#FCE7CC] p-4 rounded-lg w-[300px] md:w-[500px]">
					<div className="flex flex-col gap-5 px-5">
						<Typography variant={isMobile ? "h5" : "h4"} fontFamily={"inherit"}>
							Login
						</Typography>
						<form onSubmit={submitHandler} className="flex flex-col gap-5">
							<div className="flex flex-col gap-2 w-full">
								<CustomInput
									icon={<AlternateEmailIcon />}
									value={userEmail}
									setValue={setUserEmail}
									type={"email"}
									placeholder={"Email"}
									name={"userEmail"}
								/>
								<CustomInput
									icon={<PasswordIcon />}
									value={userPassword}
									setValue={setUserPassword}
									type={"password"}
									placeholder={"Password"}
									name={"password"}
								/>
								<Typography
									variant="caption"
									sx={{ textDecoration: "underline" }}
								>
									<Link to={"/forget-password"}>Forget password?</Link>
								</Typography>
							</div>
							<Grid container spacing={1}>
								<Grid item sm={6} xs={12}>
									<Typography
										variant="caption"
										display={"flex"}
										alignItems={"end"}
									>
										Don't have an account?&nbsp;
										<Link
											to={`/signup?redirect=${redirect}`}
											style={{ textDecoration: "underline" }}
										>
											Sign up.
										</Link>
									</Typography>
								</Grid>
								<Grid
									item
									sm={6}
									sx={{ display: "flex", justifyContent: "flex-end" }}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{loading && (
											<CircularProgress sx={{ mr: 2 }} size={"20px"} />
										)}
										<Button
											sx={{
												backgroundColor: "#FCE7CC",
												"&:hover": {
													backgroundColor: "#F59E0B",
												},
											}}
											type="submit"
											color="inherit"
											variant="contained"
										>
											Login
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</div>
				</div>
				<SnackbarMessage
					isShowSnackbar={isShowSnackbar}
					setIsShowSnackbar={setIsShowSnackbar}
					message={message}
				/>
			</Container>
		</div>
	);
}
