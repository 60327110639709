import {
	Container,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ShippingStepper from "../components/ShippingStepper";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { motion } from "framer-motion";
import { CART_LINK } from "../utils/constants";
import CustomButton from "../components/general/CustomButton";

export default function PaymentScreen() {
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const {
		cart: { shippingAddress, paymentMethod, cartItems },
	} = state;
	const [currPaymentMethod, setPaymentMethod] = useState(
		paymentMethod || "PayNow"
	);
	useEffect(() => {
		if (!shippingAddress.address) {
			navigate("/shipping");
		}
		if (cartItems.length <= 0) {
			navigate(CART_LINK);
		}
	}, [shippingAddress, navigate, cartItems]);

	const submitHandler = (e) => {
		e.preventDefault();
		ctxDispatch({ type: "SAVE_PAYMENT_METHOD", payload: currPaymentMethod });
		localStorage.setItem("paymentMethod", currPaymentMethod);
		navigate("/orderplacement");
	};
	return (
		<div>
			<Helmet>
				<title>Shipping</title>
			</Helmet>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
			>
				<Container
					maxWidth="md"
					sx={{
						display: "flex",
						justifyContent: "center",
						minHeight: "600px",
						mt: 2,
					}}
				>
					<form
						onSubmit={submitHandler}
						style={{
							height: "100%",
							width: "80%",
							display: "flex",
							flexDirection: "column",
							marginTop: "20px",
						}}
					>
						<Stack spacing={4}>
							<ShippingStepper activeStep={2} />
							<FormControl>
								<FormLabel>Payment Method</FormLabel>
								<RadioGroup
									value={currPaymentMethod}
									onChange={(e) => setPaymentMethod(e.target.value)}
								>
									<FormControlLabel
										value="PayNow"
										control={<Radio />}
										label="PayNow"
									/>
								</RadioGroup>
							</FormControl>
							<CustomButton type={"submit"}>Continue</CustomButton>
						</Stack>
					</form>
				</Container>
			</motion.div>
		</div>
	);
}
