import React, { useRef } from "react";
import { Helmet } from "react-helmet-async";
import {
	Container,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import content from "../assets/content/aboutContent";
import { useTranslation } from "react-i18next";
import { motion, useInView } from "framer-motion";
import { BODY_FONT, HEADER_FONT } from "../utils/constants";
import MobileAbout from "../components/about/MobileAbout";
import ContentRightUpdated from "../components/about/ContentRightUpdated";
import ContentLeftUpdated from "../components/about/ContentLeftUpdated";

const aboutApproach =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fabout_approach-min.png?alt=media&token=0db3ae61-5cbf-41fc-b116-f4cdc1f4f338";
const aboutContact =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fabout_contact-min.png?alt=media&token=c4387681-756e-4ba8-9592-de5877b23ba3";
const aboutMission =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fchinese-medicine-3666268_1920.jpg?alt=media&token=9a17f3df-3c7e-4901-baa0-592bb1b4eaa1";
const aboutPromise =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fabout_promise-min.png?alt=media&token=ad0d787c-c7cb-4e7c-99c8-d628e3e9b649";
const landingPic =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Faboutus.png?alt=media&token=c8684121-8b37-4eac-9327-fdb39e0b8abe";

export default function AboutScreen() {
	const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];
	const isInViews = [
		useInView(refs[0]),
		useInView(refs[1]),
		useInView(refs[2]),
		useInView(refs[3]),
	];
	const { t } = useTranslation();
	const isMobile = useMediaQuery("(max-width:600px)");

	return (
		<div
			style={{
				paddingBottom: isMobile ? "50px" : "0px",
				backgroundColor: "#FFFAEF",
			}}
		>
			<Helmet>
				<title>About</title>
			</Helmet>
			<motion.div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${landingPic})`,
					backgroundAttachment: "fixed",
				}}
				className="h-screen shrink-0 bg-cover bg-center"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
			>
				<div className="text-white">
					<Typography
						fontSize={isMobile ? 30 : 60}
						fontFamily={HEADER_FONT}
						textAlign={"center"}
					>
						{t("aboutHeader")}
					</Typography>
					<Typography
						fontSize={isMobile ? 13 : 17}
						fontFamily={BODY_FONT}
						textAlign={"center"}
					>
						{t("aboutHeaderDescription")}
					</Typography>
				</div>
			</motion.div>
			{isMobile ? (
				<Container sx={{ marginTop: "50px" }}>
					<MobileAbout content={content} />
				</Container>
			) : (
				<Stack>
					<motion.div
						style={{
							backgroundSize: "cover",
							backgroundPosition: "center",
							minHeight: "600px",
						}}
						initial={{ y: 100, opacity: 0 }}
						animate={isInViews[0] && { y: 0, opacity: 1 }}
						transition={{
							duration: 0.5,
							type: "keyframes",
						}}
						ref={refs[0]}
					>
						<ContentLeftUpdated content={content[0]} image={aboutMission} />
					</motion.div>
					<motion.div
						style={{
							backgroundSize: "cover",
							backgroundPosition: "center",
							minHeight: "600px",
						}}
						initial={{ y: 100, opacity: 0 }}
						animate={isInViews[1] && { y: 0, opacity: 1 }}
						transition={{
							duration: 0.5,
							type: "keyframes",
						}}
						ref={refs[1]}
					>
						<ContentRightUpdated content={content[1]} image={aboutApproach} />
					</motion.div>
					<motion.div
						style={{
							backgroundSize: "cover",
							backgroundPosition: "center",
							minHeight: "600px",
						}}
						initial={{ y: 100, opacity: 0 }}
						animate={isInViews[2] && { y: 0, opacity: 1 }}
						transition={{
							duration: 0.5,
							type: "keyframes",
						}}
						ref={refs[2]}
					>
						<ContentLeftUpdated content={content[2]} image={aboutPromise} />
					</motion.div>
					<motion.div
						style={{
							backgroundSize: "cover",
							backgroundPosition: "center",
							minHeight: "600px",
						}}
						initial={{ y: 100, opacity: 0 }}
						animate={isInViews[3] && { y: 0, opacity: 1 }}
						transition={{
							duration: 0.5,
							type: "keyframes",
						}}
						ref={refs[3]}
					>
						<ContentRightUpdated content={content[3]} image={aboutContact} />
					</motion.div>
				</Stack>
			)}
		</div>
	);
}
