import React, { useEffect, useReducer, useState } from "react";
import { Container, Typography, Stack, Pagination } from "@mui/material";
import LoadingBox from "../LoadingBox";
import Product from "../shop/Product";
import axios from "axios";
import MaintenanceMessage from "../MaintenanceMessage";
import { BASEURL, ABOUT_HEADER_FONT } from "../../utils/constants";
import { getError } from "../../utils";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return { ...state, products: action.payload, loading: false };
		case "FETCH_FAIL":
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

export default function HomeFeaturedProducts({ isMobile }) {
	const [{ loading, error, products }, dispatch] = useReducer(reducer, {
		products: [],
		loading: true,
		error: "",
	});
	const [currentPage, setCurrentPage] = useState(1);

	const startIndex = isMobile ? (currentPage - 1) * 2 : (currentPage - 1) * 4;

	const displayedItems = isMobile
		? products.slice(startIndex, startIndex + 2)
		: products.slice(startIndex, startIndex + 4);

	const handleChangePage = (event, newPage) => {
		setCurrentPage(newPage);
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: "FETCH_REQUEST" });
			try {
				const result = await axios.get(`${BASEURL}/api/products/home`);
				dispatch({ type: "FETCH_SUCCESS", payload: result.data });
			} catch (e) {
				dispatch({ type: "FETCH_FAIL", payload: getError(e) });
			}
		};
		fetchData();
	}, []);

	return (
		<Container sx={{ paddingTop: 3 }}>
			<Typography
				fontSize={isMobile ? 30 : 45}
				fontFamily={ABOUT_HEADER_FONT}
				sx={
					(isMobile && { display: "flex", justifyContent: "center" }, { mb: 1 })
				}
			>
				SHOP OUR PRODUCTS
			</Typography>
			{loading ? (
				<LoadingBox />
			) : error ? (
				// <ErrorMessage message={error} />
				<MaintenanceMessage />
			) : (
				<Stack spacing={5}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-evenly",
						}}
					>
						{displayedItems.map((product, index) => {
							return <Product product={product} key={index} type={"home"} />;
						})}
					</div>
					<Pagination
						count={
							isMobile
								? Math.ceil(products.length / 2)
								: Math.ceil(products.length / 4)
						}
						page={currentPage}
						onChange={handleChangePage}
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					/>
				</Stack>
			)}
		</Container>
	);
}
