import React from "react";
import { Container, useMediaQuery } from "@mui/material";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { motion, AnimatePresence } from "framer-motion";

export default function HomeBenefits() {
	const isMobile = useMediaQuery("(max-width:600px)");

	const benefits = [
		{
			Icon: (
				<MonitorHeartOutlinedIcon
					sx={{ fontSize: isMobile ? 30 : 40 }}
					data-testid="heart-icon"
				/>
			),
			benefit: "Pamper your health to pamper yourself",
		},
		{
			Icon: (
				<LocalFloristOutlinedIcon
					sx={{ fontSize: isMobile ? 30 : 40 }}
					data-testid="florist-icon"
				/>
			),
			benefit: "Sustainable material and packaging",
		},
		{
			Icon: (
				<LocalShippingOutlinedIcon
					sx={{ fontSize: isMobile ? 30 : 40 }}
					data-testid="shipping-icon"
				/>
			),
			benefit: "Fast delivery nationwide",
		},
		{
			Icon: (
				<LocalOfferOutlinedIcon
					sx={{ fontSize: isMobile ? 30 : 40 }}
					data-testid="offer-icon"
				/>
			),
			benefit: "10% Membership discount",
		},
	];

	return (
		<div className="min-h-[200px] flex items-center" data-testid="component-container">
			<AnimatePresence>
				<Container
					sx={{
						display: "flex",
						justifyContent: "space-evenly",
					}}
				>
					{benefits.map((benefit, key) => {
						return (
							<motion.div
								className="h-[100px] w-[20%]"
								key={key}
								initial={{ opacity: 0, y: 50 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5, delay: key * 0.1 }}
								viewport={{ once: true }}
							>
								<BenefitIcon key={key} benefit={benefit} />
							</motion.div>
						);
					})}
				</Container>
			</AnimatePresence>
		</div>
	);
}

const BenefitIcon = ({ benefit }) => {
	return (
		<div className="flex flex-col items-center justify-between gap-3">
			<div>{benefit.Icon}</div>
			<span className="text-center text-xs sm:text-lg">{benefit.benefit}</span>
		</div>
	);
};
