import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getError } from "../utils";
import {
	Button,
	Card,
	CardContent,
	CardMedia,
	Container,
	Grid,
	Rating,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Store } from "../Store";
import SnackbarMessage from "../components/SnackbarMessage";
import { motion } from "framer-motion";
import { BASEURL } from "../utils/constants";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return { ...state, loading: false, product: action.payload };
		case "FETCH_FAIL":
			return { ...state, loading: false, message: action.payload };
		case "SUBMIT_REQUEST":
			return { ...state, loading: true };
		case "SUBMIT_SUCCESS":
			return { ...state, loading: false, message: action.payload };
		case "SUBMIT_FAIL":
			return { ...state, loading: false, message: action.payload };
		case "INCOMPLETE_ENTRY":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function ProductReviewScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const [ratingValue, setRatingValue] = useState(0);
	const [review, setReview] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const params = useParams();
	const navigate = useNavigate();
	const { dispatch: ctxDispatch } = useContext(Store);
	const { slug, orderid: orderId } = params;

	const [{ loading, product, message }, dispatch] = useReducer(reducer, {
		loading: true,
		product: [],
		message: "",
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: "FETCH_REQUEST" });
			try {
				const { data } = await axios.get(
					`${BASEURL}/api/products/slug/${slug}`
				);
				dispatch({ type: "FETCH_SUCCESS", payload: data });
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
				setIsShowSnackbar(true);
			}
		};
		fetchData();
	}, [slug, orderId]);

	const handleSubmitReview = async (e) => {
		e.preventDefault();
		if (!review || !ratingValue) {
			dispatch({
				type: "INCOMPLETE_ENTRY",
				payload: "Please enter a valid rating and review.",
			});
			setIsShowSnackbar(true);
			return;
		}
		try {
			const { data } = await axios.post(
				`${BASEURL}/api/products/${product._id}/reviews`,
				{
					ratingValue,
					review,
					orderId,
					slug,
				},
				{
					withCredentials: true,
				}
			);
			dispatch({ type: "SUBMIT_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
			setTimeout(function () {
				navigate(`/product/${product.slug}`);
			}, 1000);
		} catch (err) {
			dispatch({ type: "SUBMIT_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	return (
		<div style={{ minHeight: "80vh", display: "flex", alignItems: "center" }}>
			<Helmet>
				<title>Product Review</title>
			</Helmet>
			{!loading && (
				<Container sx={{ mt: 2 }}>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}
					>
						<Typography variant={isMobile ? "h4" : "h3"} mb={1}>
							Product Review
						</Typography>
						<Card variant="outlined" sx={{ borderRadius: 5 }}>
							<CardContent>
								<Grid
									container
									sx={{ display: "flex", justifyContent: "space-between" }}
								>
									<Grid
										item
										sm={3}
										xs={12}
										sx={{ display: "flex", alignItems: "center" }}
									>
										<CardMedia
											component="img"
											src={product.image[0]}
											alt={product.name}
											sx={{ borderRadius: 5 }}
										/>
									</Grid>
									<Grid item sm={7} xs={12}>
										<Stack spacing={5}>
											<div>
												<div
													style={{
														display: !isMobile && "flex",
														alignItems: "center",
														justifyContent: "space-between",
													}}
												>
													<Typography variant={isMobile ? "h6" : "h5"}>
														{product.name}
													</Typography>
													<div style={{ display: "flex" }}>
														<Rating
															disabled
															value={product.rating}
															precision={0.5}
														/>
														<Typography ml={1}>
															{product.rating.toFixed(1) || 0}
														</Typography>
													</div>
												</div>
												<Typography variant="caption">
													{product.numReviews} Reviews
												</Typography>
											</div>
											<div style={{ display: "flex", flexDirection: "column" }}>
												<div
													style={{
														display: "flex",
														alignItems: "center",
													}}
												>
													<Typography mr={1}>Rating:</Typography>
													<Rating
														value={ratingValue}
														onChange={(e) =>
															setRatingValue(Number(e.target.value))
														}
														precision={0.5}
													/>
													<Typography ml={1}>{ratingValue}</Typography>
												</div>
												<TextField
													placeholder="Write your review here"
													multiline
													rows={5}
													value={review}
													onChange={(e) => setReview(e.target.value)}
												/>
												<motion.div
													whileHover={{
														scale: 1.02,
														shadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
													}}
													transition={{ type: "spring", stiffness: 300 }}
												>
													<Button
														variant="contained"
														size="small"
														sx={{ mt: 1 }}
														onClick={handleSubmitReview}
														color="inherit"
														fullWidth
													>
														Submit
													</Button>
												</motion.div>
											</div>
										</Stack>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
						<SnackbarMessage
							isShowSnackbar={isShowSnackbar}
							setIsShowSnackbar={setIsShowSnackbar}
							message={message}
						/>
					</motion.div>
				</Container>
			)}
		</div>
	);
}
