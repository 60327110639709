import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../Store";
import { getError, parseText } from "../utils";
import axios from "axios";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Rating,
	Stack,
	Tab,
	Tabs,
	TextField,
	Typography,
	useMediaQuery,
	Pagination,
	IconButton,
} from "@mui/material";
import ErrorMessage from "../components/ErrorMessage";
import ProductBreadcrumb from "../components/ProductBreadcrumb";
import { motion } from "framer-motion";
import { BASEURL, BODY_FONT } from "../utils/constants";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { defaultSchema } from "hast-util-sanitize";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return { ...state, product: action.payload, loading: false };
		case "FETCH_FAIL":
			return { ...state, loading: false, error: action.payload };
		case "ADD_REQUEST":
			return { ...state, loading: true };
		case "ADD_SUCCESS":
			return { ...state, loading: false };
		case "ADD_FAIL":
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

const customSchema = {
	...defaultSchema,
	tagNames: [...defaultSchema.tagNames, "u"],
};

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default function ProductScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const [value, setValue] = useState(0);
	const [productItemCount, setProductItemCount] = useState(1);
	const params = useParams();
	const navigate = useNavigate();
	const { slug } = params;
	const [isAdding, setIsAdding] = useState(false);
	const [cartButtonText, setCartButtonText] = useState("ADD TO CART");
	const [currentReviewPage, setCurrentReviewPage] = useState(1);
	const [imageIndex, setImageIndex] = useState(0);

	const [{ loading, error, product }, dispatch] = useReducer(reducer, {
		product: [],
		loading: true,
		error: "",
	});

	const paginatedItems = loading
		? []
		: product.reviews.slice(
				(currentReviewPage - 1) * 5,
				(currentReviewPage - 1) * 5 + 5
		  );

	const handlePageChange = (event, newPage) => {
		setCurrentReviewPage(newPage);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const result = await axios.get(`${BASEURL}/api/products/slug/${slug}`);
				dispatch({ type: "FETCH_SUCCESS", payload: result.data });
			} catch (e) {
				dispatch({ type: "FETCH_FAIL", payload: getError(e) });
			}
		};
		fetchData();
		if (product.isArchive) {
			navigate("/");
		}
	}, [slug, navigate, product.isArchive]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleProductCountChange = (event) => {
		setProductItemCount(event.target.value);
	};

	// TODO SNACKBAR
	const addToCartHandler = async (item) => {
		try {
			addingToCart();
			const existItem = cartItems.find((x) => x._id === product._id);
			const quantity = existItem
				? Number(existItem.quantity) + Number(productItemCount)
				: productItemCount;
			dispatch({ type: "ADD_REQUEST" });
			const { data } = await axios.get(
				`${BASEURL}/api/products/id/${item._id}`
			);
			if (data.countInStock < quantity) {
				window.alert("Sorry product out of stock");
				return;
			}
			ctxDispatch({ type: "CART_ADD_ITEM", payload: { ...item, quantity } });
			dispatch({ type: "ADD_SUCCESS" });
		} catch (err) {
			dispatch({ type: "ADD_SUCCESS", payload: getError(err) });
		}
	};

	const addingToCart = () => {
		setCartButtonText("ADDING");
		setIsAdding(true);
		setTimeout(() => {
			setCartButtonText("ADDED TO CART");
			setIsAdding(false);
		}, 2000);
		setCartNameBack();
	};

	const setCartNameBack = () => {
		setTimeout(() => {
			setCartButtonText("ADD TO CART");
		}, 4000);
	};

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const {
		cart: { cartItems },
	} = state;

	return (
		<div style={{ backgroundColor: "#FFFAEF", paddingTop: 1 }}>
			<Container>
				{loading ? (
					<PageLoadingBox />
				) : error ? (
					<ErrorMessage message={error} />
				) : (
					<div>
						<ProductBreadcrumb productName={product.name} />
						<Stack spacing={isMobile ? 10 : 15} mt={5}>
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 1 }}
							>
								<Grid container justifyContent={"space-between"}>
									<Grid
										item
										sm={5}
										xs={12}
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}
									>
										<div className="flex w-full h-full items-center">
											<IconButton
												onClick={() =>
													setImageIndex(
														imageIndex <= 0 ? imageIndex : imageIndex - 1
													)
												}
											>
												<ChevronLeftIcon fontSize="large" />
											</IconButton>
											<div
												className="h-56 w-full md:h-96 bg-contain bg-center bg-no-repeat"
												style={{
													backgroundImage: `url('${product.image[imageIndex]}')`,
												}}
											/>
											<IconButton
												onClick={() =>
													setImageIndex(
														imageIndex >= product.image.length - 1
															? imageIndex
															: imageIndex + 1
													)
												}
											>
												<ChevronRightIcon fontSize="large" />
											</IconButton>
										</div>
										<div className="h-24 w-44 flex mt-1 mr-1">
											{product.image.map((image, key) => {
												return (
													<div
														key={key}
														className="h-full w-full bg-contain bg-center bg-no-repeat cursor-pointer"
														style={{
															backgroundImage: `url('${image}')`,
														}}
														onClick={() => setImageIndex(key)}
													/>
												);
											})}
										</div>
									</Grid>
									<Grid item sm={5} xs={12}>
										<Stack
											height={"100%"}
											justifyContent={"space-between"}
											spacing={1}
										>
											<div>
												<Grid container justifyContent={"space-between"}>
													<Grid item>
														<Typography variant="body1">
															{product.brand}
														</Typography>
													</Grid>
													{product.countInStock <= 0 && (
														<Grid item>
															<Chip
																label="Out of Stock"
																size="small"
																color="error"
															/>
														</Grid>
													)}
												</Grid>
												<Typography variant={isMobile ? "h5" : "h4"}>
													{product.name}
												</Typography>
												<div style={{ display: "flex" }}>
													<Rating
														value={product.rating}
														disabled
														precision={0.5}
													/>
													<Typography ml={1}>
														{product.rating === 0 ? (
															<>No ratings yet</>
														) : (
															<>{product.rating.toFixed(1)}</>
														)}
													</Typography>
												</div>
											</div>
											<div style={{ display: "flex" }}>
												<Typography
													variant="h6"
													sx={{
														textDecoration:
															product.discountedPrice > 0 && "line-through",
														fontWeight: product.discountedPrice <= 0 && "bold",
													}}
												>
													${product.price}
												</Typography>
												{product.discountedPrice > 0 && (
													<Typography
														variant="h6"
														ml={1}
														sx={{ fontWeight: "bold" }}
													>
														${product.discountedPrice}
													</Typography>
												)}
											</div>
											<div style={{ display: "flex", flexDirection: "column" }}>
												<TextField
													value={productItemCount}
													label="Quantity"
													type="number"
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														min: 1,
													}}
													variant="filled"
													onChange={handleProductCountChange}
												/>
												<Button
													disabled={
														product.countInStock <= 0 || product.isArchive
													}
													variant="contained"
													color="inherit"
													onClick={() => addToCartHandler(product)}
												>
													{isAdding ? (
														<Grid container justifyContent={"center"}>
															<Grid
																item
																sx={{ display: "flex", alignItems: "center" }}
																mr={2}
															>
																<CircularProgress size={15} />
															</Grid>
															<Grid item>{cartButtonText}</Grid>
														</Grid>
													) : (
														<div>{cartButtonText}</div>
													)}
												</Button>
											</div>
										</Stack>
									</Grid>
								</Grid>
							</motion.div>
							<Divider />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									justifyContent: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-evenly",
										borderBottom: 1,
										borderColor: "divider",
									}}
								>
									<Tabs
										value={value}
										onChange={handleChange}
										variant="scrollable"
										scrollButtons="auto"
									>
										<Tab
											sx={{ marginRight: isMobile ? 1 : 10 }}
											label="Description"
										/>
										<Tab
											sx={{ marginRight: isMobile ? 1 : 10 }}
											label="Dosage"
										/>
										<Tab
											sx={{ marginRight: isMobile ? 1 : 10 }}
											label="Storage"
										/>
										<Tab
											sx={{ marginRight: isMobile ? 1 : 10 }}
											label="Ingredients"
										/>
										<Tab
											sx={{ marginRight: isMobile ? 1 : 10 }}
											label="Reviews"
										/>
									</Tabs>
								</Box>
								<Container
									sx={{
										minHeight: "300px",
										overflow: "auto",
										marginBottom: "50px",
									}}
								>
									<CustomTabPanel value={value} index={0}>
										<Container maxWidth={"md"}>
											<Typography
												fontSize={isMobile && 12}
												fontFamily={BODY_FONT}
											>
												<Markdown
													rehypePlugins={[
														[rehypeRaw],
														[rehypeSanitize, customSchema],
													]}
													className="flex flex-col gap-5"
												>
													{parseText(product.description)}
												</Markdown>
											</Typography>
										</Container>
									</CustomTabPanel>
									<CustomTabPanel value={value} index={1}>
										<Container maxWidth={"md"}>
											<Typography
												fontSize={isMobile && 12}
												fontFamily={BODY_FONT}
											>
												<Markdown
													rehypePlugins={[
														[rehypeRaw],
														[rehypeSanitize, customSchema],
													]}
													className="flex flex-col gap-5"
												>
													{parseText(product.dosage)}
												</Markdown>
											</Typography>
										</Container>
									</CustomTabPanel>
									<CustomTabPanel value={value} index={2}>
										<Container maxWidth={"md"}>
											<Typography
												fontSize={isMobile && 12}
												fontFamily={BODY_FONT}
											>
												<Markdown
													rehypePlugins={[
														[rehypeRaw],
														[rehypeSanitize, customSchema],
													]}
													className="flex flex-col gap-5"
												>
													{parseText(product.storage)}
												</Markdown>
											</Typography>
										</Container>
									</CustomTabPanel>
									<CustomTabPanel value={value} index={3}>
										<Container maxWidth={"md"}>
											<Typography
												fontSize={isMobile && 12}
												fontFamily={BODY_FONT}
											>
												<Markdown
													rehypePlugins={[
														[rehypeRaw],
														[rehypeSanitize, customSchema],
													]}
													className="flex flex-col gap-5"
												>
													{parseText(product.ingredients)}
												</Markdown>
											</Typography>
										</Container>
									</CustomTabPanel>
									<CustomTabPanel value={value} index={4}>
										<Container maxWidth={"md"}>
											{paginatedItems.map((review, key) => {
												return (
													<List key={key}>
														<ListItem
															sx={{
																display: "flex",
																flexDirection: "column",
																alignItems: "flex-start",
															}}
														>
															<ListItemText sx={{ width: "100%" }}>
																<Card variant="outlined">
																	<CardContent
																		sx={{ backgroundColor: "white" }}
																	>
																		<div
																			style={{
																				display: "flex",
																				justifyContent: "space-between",
																				alignItems: "center",
																			}}
																		>
																			<Typography
																				fontSize={isMobile && 12}
																				fontFamily={BODY_FONT}
																			>
																				<strong>{review.name}</strong>
																			</Typography>
																			<Rating
																				disabled
																				precision={0.5}
																				value={review.rating}
																				size="small"
																			/>
																		</div>
																		<Typography
																			fontSize={isMobile && 11}
																			fontFamily={BODY_FONT}
																		>
																			{review.comment}
																		</Typography>
																	</CardContent>
																</Card>
															</ListItemText>
														</ListItem>
													</List>
												);
											})}
											{product.reviews.length > 0 ? (
												<Pagination
													sx={{ display: "flex", justifyContent: "center" }}
													count={Math.ceil(product.reviews.length / 5)}
													page={currentReviewPage}
													onChange={handlePageChange}
												/>
											) : (
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
														minHeight: "50vh",
													}}
												>
													<Typography variant="overline">
														<strong>
															<u>No reviews yet</u>
														</strong>
													</Typography>
													<Typography variant="overline" textAlign={"center"}>
														Give this product a review after purchasing it
													</Typography>
												</div>
											)}
										</Container>
									</CustomTabPanel>
								</Container>
							</Box>
						</Stack>
					</div>
				)}
			</Container>
		</div>
	);
}
