import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { Helmet } from "react-helmet-async";
import {
	Container,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import ShippingStepper from "../components/ShippingStepper";
import { CART_LINK, PAYMENT_LINK } from "../utils/constants";
import { motion } from "framer-motion";
import CustomButton from "../components/general/CustomButton";

export default function ShippingScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const {
		userInfo,
		cart: { shippingAddress, cartItems },
	} = state;
	const [fullName, setFullName] = useState(shippingAddress.fullName || "");
	const [address, setAddress] = useState(shippingAddress.address || "");
	const [city, setCity] = useState(shippingAddress.city || "");
	const [country, setCountry] = useState(shippingAddress.country || "");
	const [postalCode, setPostalCode] = useState(
		shippingAddress.postalCode || ""
	);
	useEffect(() => {
		if (!userInfo) {
			navigate("/login?redirect=/shipping");
		}
		if (cartItems.length <= 0) {
			navigate(CART_LINK);
		}
	}, [userInfo, navigate, cartItems]);
	const submitHandler = (e) => {
		e.preventDefault();
		ctxDispatch({
			type: "SAVE_SHIPPING_ADDRESS",
			payload: {
				fullName,
				address,
				city,
				postalCode,
				country,
			},
		});
		localStorage.setItem(
			"shippingAddress",
			JSON.stringify({
				fullName,
				address,
				city,
				postalCode,
				country,
			})
		);
		navigate(PAYMENT_LINK);
	};
	return (
		<div>
			<Helmet>
				<title>Shipping</title>
			</Helmet>
			<Container
				maxWidth="md"
				sx={{
					display: "flex",
					justifyContent: "center",
					minHeight: "600px",
					mt: 4,
				}}
			>
				<form
					onSubmit={submitHandler}
					style={{
						height: "100%",
						width: "80%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 1 }}
					>
						<Stack spacing={3}>
							<ShippingStepper activeStep={userInfo ? 1 : 0} />
							<Typography variant="h4">Shipping Address</Typography>

							<TextField
								label="Full Name"
								variant="filled"
								value={fullName}
								onChange={(e) => setFullName(e.target.value)}
								size={isMobile && "small"}
								required
							/>
							<TextField
								label="Address"
								variant="filled"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								size={isMobile && "small"}
								required
							/>
							<TextField
								label="City"
								variant="filled"
								value={city}
								onChange={(e) => setCity(e.target.value)}
								size={isMobile && "small"}
								required
							/>
							<TextField
								label="Postal Code"
								type="code"
								variant="filled"
								value={postalCode}
								onChange={(e) => setPostalCode(e.target.value)}
								size={isMobile && "small"}
								required
							/>
							<TextField
								label="Country"
								variant="filled"
								value={country}
								onChange={(e) => setCountry(e.target.value)}
								size={isMobile && "small"}
								required
							/>
							<CustomButton type="submit">Continue</CustomButton>
						</Stack>
					</motion.div>
				</form>
			</Container>
		</div>
	);
}
