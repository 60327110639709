import {
	Card,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	BASEURL,
	CREATE_PRODUCT_LINK,
	SET_FEATURED_PRODUCT_LINK,
} from "../utils/constants";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import AdminProductListing from "../components/AdminProductListing";
import AdminLoadingSkeleton from "../components/loading/AdminLoadingSkeleton";
import ErrorMessage from "../components/ErrorMessage";
import CustomButton from "../components/general/CustomButton";
import { Store } from "../Store";
import { getError } from "../utils";
import SnackbarMessage from "../components/SnackbarMessage";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return {
				...state,
				products: action.payload,
				categories: action.categoriesPayload,
				loading: false,
			};
		case "FETCH_FAIL":
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

export default function AdminProductsScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { page } = useParams();
	const [numPages, setNumPages] = useState(1);
	const [category, setCategory] = useState("");
	const navigate = useNavigate();
	const { dispatch: ctxDispatch } = useContext(Store);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);

	const [{ loading, error, products, categories }, dispatch] = useReducer(
		reducer,
		{
			categories: [],
			products: [],
			loading: true,
			error: "",
		}
	);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const { data } = await axios.post(
					`${BASEURL}/api/products/adminpage/${page}`,
					{
						category: category,
					},
					{
						withCredentials: true,
					}
				);
				dispatch({
					type: "FETCH_SUCCESS",
					payload: data.products,
					categoriesPayload: data.allCategories,
				});
				setNumPages(Math.ceil(data.totalItems / 8));
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
				setIsShowSnackbar(true);
				if (err.response && err.response.status === 403) {
					setTimeout(function () {
						ctxDispatch({ type: "USER_SIGNOUT" });
					}, 1000);
				}
			}
		};
		fetchData();
		// TODO should ctxDispatch be added
	}, [page, category, ctxDispatch]);

	const handleChangePage = (event, newPage) => {
		navigate(`/adminproducts/${newPage}`);
	};

	return (
		<Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
			<Helmet>
				<title>Products (Admin)</title>
			</Helmet>
			<Grid container>
				<Grid item sm={4} xs={6}>
					<Stack spacing={1}>
						<Link to={CREATE_PRODUCT_LINK}>
							<CustomButton>
								<Typography
									fontSize={isMobile ? 10 : 14}
									sx={{ color: "black" }}
								>
									Create Product
								</Typography>
							</CustomButton>
						</Link>
						<Link to={SET_FEATURED_PRODUCT_LINK}>
							<CustomButton variant="contained" color="inherit">
								<Typography
									fontSize={isMobile ? 10 : 14}
									sx={{ color: "black" }}
								>
									Set Featured Products
								</Typography>
							</CustomButton>
						</Link>
					</Stack>
				</Grid>
				<Grid item sm={8} xs={6}>
					<Container sx={{ display: "flex", justifyContent: "flex-end" }}>
						<FormControl sx={{ minWidth: 120 }} size="small">
							<InputLabel>Category</InputLabel>
							<Select
								value={category}
								label="Category"
								onChange={(e) => setCategory(e.target.value)}
							>
								<MenuItem value="">
									<em>All</em>
								</MenuItem>
								{categories.map((cat, key) => {
									return (
										<MenuItem key={key} value={cat}>
											{cat}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Container>
				</Grid>
			</Grid>
			{loading ? (
				<AdminLoadingSkeleton />
			) : error ? (
				<ErrorMessage message={error} />
			) : (
				<Stack mt={4} spacing={3} alignItems="center">
					<Card variant="outlined" sx={{ width: "100%" }}>
						<AdminProductListing products={products} />
					</Card>
					<Pagination
						count={numPages}
						page={parseInt(page)}
						onChange={handleChangePage}
					/>
				</Stack>
			)}
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				message={error}
				setIsShowSnackbar={setIsShowSnackbar}
			/>
		</Container>
	);
}
