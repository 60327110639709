import React from "react";
import { Container, Typography, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { BODY_FONT, ABOUT_HEADER_FONT } from "../../utils/constants";

export default function HomeMission({ isMobile }) {
	return (
		<Container
			sx={{
				minHeight: isMobile ? "300px" : "350px",
				display: "flex",
				alignItems: "center",
			}}
			data-testid="component-container"
		>
			<motion.div
				initial={{ y: 75 }}
				whileInView={{ y: 0 }}
				viewport={{ once: true }}
				transition={{
					duration: 1,
					type: "spring",
				}}
			>
				<Stack spacing={4}>
					<Typography
						textAlign={isMobile && "center"}
						fontFamily={ABOUT_HEADER_FONT}
						fontSize={isMobile ? 40 : 60}
					>
						OUR MISSION
					</Typography>
					<div>
						<Typography
							textAlign={"center"}
							fontFamily={BODY_FONT}
							fontSize={isMobile ? 15 : 25}
						>
							Ensuring the accessibility of high-quality products,
						</Typography>
						<Typography
							textAlign={"center"}
							fontFamily={BODY_FONT}
							fontSize={isMobile ? 15 : 25}
						>
							Enhancing the overall quality of life for individuals.
						</Typography>
					</div>
				</Stack>
			</motion.div>
		</Container>
	);
}
