import {
	Button,
	CircularProgress,
	Container,
	Grid,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import SnackbarMessage from "../components/SnackbarMessage";
import { Store } from "../Store";
import axios from "axios";
import { getError } from "../utils";
import { BASEURL } from "../utils/constants";

const reducer = (state, action) => {
	switch (action.type) {
		case "RESET_REQUEST":
			return { ...state, resetLoading: true };
		case "RESET_SUCCESS":
			return { ...state, resetLoading: false, message: action.payload };
		case "RESET_FAIL":
			return { ...state, resetLoading: false, message: action.payload };
		case "PASSWORD_MISMATCH":
			return { ...state, message: "Password mismatch. Please try again." };
		default:
			return state;
	}
};

export default function ResetPasswordScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const [newPassword, setNewPassword] = useState("");
	const [reNewPassword, setReNewPassword] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [{ resetLoading, message }, dispatch] = useReducer(reducer, {
		resetLoading: false,
		message: "",
	});
	const { token } = useParams();

	const navigate = useNavigate();

	const { state } = useContext(Store);
	const { userInfo } = state;

	useEffect(() => {
		if (userInfo || !token) {
			navigate("/");
		}
	}, [navigate, userInfo, token]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (newPassword !== reNewPassword) {
			dispatch({ type: "PASSWORD_MISMATCH" });
			setIsShowSnackbar(true);
			return;
		}
		try {
			dispatch({ type: "RESET_REQUEST" });
			const { data } = await axios.post(`${BASEURL}/api/users/reset-password`, {
				password: newPassword,
				token: encodeURI(token),
			});
			dispatch({ type: "RESET_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
			setTimeout(function () {
				navigate("/login");
			}, 1000);
		} catch (err) {
			dispatch({ type: "RESET_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
		}
	};

	return (
		<Container maxWidth="sm" sx={{ height: "80vh", mt: 4 }}>
			<Helmet>
				<title>Reset Password</title>
			</Helmet>
			<Typography variant={isMobile ? "h5" : "h4"}>Reset Password</Typography>
			<form onSubmit={handleSubmit}>
				<Stack spacing={isMobile ? 1 : 3}>
					<TextField
						name="newPassword"
						type="password"
						label="New Password"
						variant="filled"
						value={newPassword}
						fullWidth
						onChange={(e) => setNewPassword(e.target.value)}
						size={isMobile && "small"}
					/>
					<TextField
						name="reNewPassword"
						type="password"
						label="Repeat Password"
						variant="filled"
						value={reNewPassword}
						fullWidth
						onChange={(e) => setReNewPassword(e.target.value)}
						size={isMobile && "small"}
					/>
					<Grid sx={{ display: "flex", alignItems: "center" }}>
						<Button type="submit" variant="contained" sx={{ mt: 1 }}>
							Submit
						</Button>
						{resetLoading && (
							<CircularProgress size={20} color="inherit" sx={{ ml: 1 }} />
						)}
					</Grid>
				</Stack>
			</form>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</Container>
	);
}
