import React from "react";

export default function CustomInput({
	icon,
	value,
	setValue,
	type,
	placeholder,
	name,
}) {
	return (
		<div className="relative">
			<span className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none z-10">
				{icon}
			</span>
			<input
				className="rounded-md pl-10 py-2 w-full text-lg opacity-50"
				value={value}
				onChange={(e) => setValue(e.target.value)}
				type={type}
				placeholder={placeholder}
				name={name}
			/>
		</div>
	);
}
