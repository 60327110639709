import {
	Button,
	CircularProgress,
	Container,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../Store";
import { getError, parseText } from "../utils";
import ErrorMessage from "../components/ErrorMessage";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { BASEURL } from "../utils/constants";
import CustomButton from "../components/general/CustomButton";
import SnackbarMessage from "../components/SnackbarMessage";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import { defaultSchema } from "hast-util-sanitize";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return { ...state, loading: false };
		case "FETCH_FAIL":
			return { ...state, loading: false, message: action.payload };
		case "UPDATE_REQUEST":
			return { ...state, loadingUpdate: true };
		case "UPDATE_SUCCESS":
			return { ...state, loadingUpdate: false, message: action.payload };
		case "UPDATE_FAIL":
			return { ...state, loadingUpdate: false };
		default:
			return state;
	}
};

const customSchema = {
	...defaultSchema,
	tagNames: [...defaultSchema.tagNames, "u"],
};

export default function EditProductScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const params = useParams();
	const { id: productId } = params;
	const { dispatch: ctxDispatch } = useContext(Store);
	const [{ loading, message, loadingUpdate }, dispatch] = useReducer(reducer, {
		loading: true,
		message: "",
		loadingUpdate: false,
	});

	const [name, setName] = useState("");
	const [brand, setBrand] = useState("");
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");
	const [dosage, setDosage] = useState("");
	const [storage, setStorage] = useState("");
	const [ingredients, setIngredients] = useState("");
	const [price, setPrice] = useState(0);
	const [discountedPrice, setDiscountedPrice] = useState(0);
	const [countInStock, setCountInStock] = useState("");
	const [isArchive, setIsArchive] = useState(false);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [isShowDescriptionPreview, setIsShowDescriptionPreview] =
		useState(false);
	const [isShowDosagePreview, setIsShowDosagePreview] = useState(false);
	const [isShowStoragePreview, setIsShowStoragePreview] = useState(false);
	const [isShowIngredientPreview, setIsShowIngredientPreview] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const { data } = await axios.get(
					`${BASEURL}/api/products/id/${productId}`
				);
				setName(data.name);
				setBrand(data.brand);
				setCategory(data.category);
				setDescription(data.description);
				setDosage(data.dosage);
				setStorage(data.storage);
				setIngredients(data.ingredients);
				setPrice(data.price);
				setDiscountedPrice(data.discountedPrice);
				setCountInStock(data.countInStock);
				setIsArchive(data.isArchive);
				dispatch({ type: "FETCH_SUCCESS" });
			} catch (err) {
				dispatch({
					type: "FETCH_FAIL",
					payload: getError(err),
				});
				console.log(getError(err));
			}
		};
		fetchData();
	}, [productId]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const currSlug = name.toLowerCase().replace(/ /g, "-");
		try {
			dispatch({ type: "UPDATE_REQUEST" });
			const { data } = await axios.put(
				`${BASEURL}/api/products/${productId}`,
				{
					_id: productId,
					name: name,
					brand: brand,
					category: category,
					description: description,
					dosage: dosage,
					storage: storage,
					ingredients: ingredients,
					price: price,
					discountedPrice: discountedPrice,
					countInStock,
					slug: currSlug,
					isArchive: isArchive,
				},
				{
					withCredentials: true,
				}
			);
			dispatch({ type: "UPDATE_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
			setTimeout(function () {
				navigate(-1);
			}, 1000);
		} catch (err) {
			dispatch({ type: "UPDATE_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	return (
		<Container maxWidth="md">
			<Helmet>
				<title>Edit Product</title>
			</Helmet>
			{loading ? (
				<PageLoadingBox />
			) : message ? (
				<ErrorMessage message={message} />
			) : (
				<>
					<div className="flex mt-5">
						<Button onClick={() => navigate(-1)} sx={{ color: "black" }}>
							<ArrowBackIosNewIcon sx={{ fontSize: isMobile ? 20 : 30 }} />
						</Button>
						<Typography variant={isMobile ? "h5" : "h4"}>
							Edit Product
						</Typography>
					</div>
					<div className="bg-[#f1f0ef] p-5 my-3 rounded-lg font-light border-[1px] border-slate-400 text-xs sm:text-base">
						<h2 className="font-semibold underline">Instruction manual:</h2>
						<p>1. Bold Sentences: **bold**</p>
						<p>2. Underline Sentences: __underline__</p>
						<p>3. New line: 2 new spaces [2 spacebar, 1 new line]</p>
						<p>4. New paragraph: 2 new lines [press enter twice]</p>
					</div>
					<form onSubmit={handleSubmit} className="mb-5">
						<Stack spacing={3}>
							<TextField
								required
								label="Product Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								size={isMobile ? "small" : "medium"}
							/>
							<TextField
								required
								label="Product Brand"
								value={brand}
								onChange={(e) => setBrand(e.target.value)}
								size={isMobile ? "small" : "medium"}
							/>

							<TextField
								required
								label="Category"
								value={category}
								onChange={(e) => setCategory(e.target.value)}
								size={isMobile ? "small" : "medium"}
							/>

							<div className="grid grid-cols-10 gap-3 items-center">
								<div className="col-span-10 sm:col-span-9">
									{isShowDescriptionPreview ? (
										<div className="h-64 overflow-scroll border-2 rounded-md relative">
											<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
												Description
											</p>
											<Markdown
												rehypePlugins={[
													[rehypeRaw],
													[rehypeSanitize, customSchema],
												]}
												className="px-2 flex flex-col gap-5"
											>
												{parseText(description)}
											</Markdown>
										</div>
									) : (
										<TextField
											required
											fullWidth
											label="Description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											size={isMobile ? "small" : "medium"}
											multiline
											maxRows={10}
										/>
									)}
								</div>
								<div className="col-span-10 sm:col-span-1">
									<CustomButton
										type={"button"}
										onClick={() =>
											setIsShowDescriptionPreview(!isShowDescriptionPreview)
										}
									>
										Preview
									</CustomButton>
								</div>
							</div>
							<div className="grid grid-cols-10 gap-3 items-center">
								<div className="col-span-10 sm:col-span-9">
									{isShowDosagePreview ? (
										<div className="h-64 overflow-scroll border-2 rounded-md relative">
											<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
												Dosage
											</p>
											<Markdown
												rehypePlugins={[
													[rehypeRaw],
													[rehypeSanitize, customSchema],
												]}
												className="px-2 flex flex-col gap-5"
											>
												{parseText(dosage)}
											</Markdown>
										</div>
									) : (
										<TextField
											required
											fullWidth
											label="Dosage"
											value={dosage}
											onChange={(e) => setDosage(e.target.value)}
											size={isMobile ? "small" : "medium"}
											multiline
											maxRows={10}
										/>
									)}
								</div>
								<div className="col-span-10 sm:col-span-1">
									<CustomButton
										type={"button"}
										onClick={() => setIsShowDosagePreview(!isShowDosagePreview)}
									>
										Preview
									</CustomButton>
								</div>
							</div>
							<div className="grid grid-cols-10 gap-3 items-center">
								<div className="col-span-10 sm:col-span-9">
									{isShowStoragePreview ? (
										<div className="h-64 overflow-scroll border-2 rounded-md relative">
											<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
												Storage
											</p>
											<Markdown
												rehypePlugins={[
													[rehypeRaw],
													[rehypeSanitize, customSchema],
												]}
												className="px-2 flex flex-col gap-5"
											>
												{parseText(storage)}
											</Markdown>
										</div>
									) : (
										<TextField
											required
											fullWidth
											label="Storage"
											value={storage}
											onChange={(e) => setStorage(e.target.value)}
											size={isMobile ? "small" : "medium"}
											multiline
											maxRows={10}
										/>
									)}
								</div>
								<div className="col-span-10 sm:col-span-1">
									<CustomButton
										type={"button"}
										onClick={() =>
											setIsShowStoragePreview(!isShowStoragePreview)
										}
									>
										Preview
									</CustomButton>
								</div>
							</div>
							<div className="grid grid-cols-10 gap-3 items-center">
								<div className="col-span-10 sm:col-span-9">
									{isShowIngredientPreview ? (
										<div className="h-64 overflow-scroll border-2 rounded-md relative">
											<p className="sticky top-0 py-1 text-center underline font-semibold bg-slate-200">
												Ingredients
											</p>
											<Markdown
												rehypePlugins={[
													[rehypeRaw],
													[rehypeSanitize, customSchema],
												]}
												className="flex flex-col gap-5 px-2"
											>
												{parseText(ingredients)}
											</Markdown>
										</div>
									) : (
										<TextField
											required
											fullWidth
											label="Ingredients"
											value={ingredients}
											onChange={(e) => setIngredients(e.target.value)}
											size={isMobile ? "small" : "medium"}
											multiline
											maxRows={10}
										/>
									)}
								</div>
								<div className="col-span-10 sm:col-span-1">
									<CustomButton
										type={"button"}
										onClick={() =>
											setIsShowIngredientPreview(!isShowIngredientPreview)
										}
									>
										Preview
									</CustomButton>
								</div>
							</div>
							<TextField
								required
								type="number"
								inputProps={{
									min: 1,
								}}
								label="Price"
								value={price}
								onChange={(e) => setPrice(e.target.value)}
								size={isMobile ? "small" : "medium"}
							/>
							<TextField
								required
								type="number"
								label="Discounted Price"
								value={discountedPrice}
								onChange={(e) => setDiscountedPrice(e.target.value)}
								size={isMobile ? "small" : "medium"}
							/>
							<TextField
								required
								type="number"
								inputProps={{
									min: 1,
								}}
								label="Stock Count"
								value={countInStock}
								onChange={(e) => setCountInStock(e.target.value)}
								size={isMobile ? "small" : "medium"}
							/>
							<div className="flex items-center">
								<Typography sx={{ mr: 2 }}>Archive:</Typography>
								<div>
									<label className="relative inline-flex items-center cursor-pointer">
										<input
											type="checkbox"
											checked={isArchive}
											value={isArchive}
											onChange={() => setIsArchive(!isArchive)}
											class="sr-only peer"
										/>
										<div className="peer ring-0 bg-rose-400 rounded-full outline-none duration-300 after:duration-500 w-12 h-12  shadow-md peer-checked:bg-emerald-500  peer-focus:outline-none  after:content-['✖️'] after:rounded-full after:absolute after:outline-none after:h-10 after:w-10 after:bg-gray-50 after:top-1 after:left-1 after:flex after:justify-center after:items-center  peer-hover:after:scale-75 peer-checked:after:content-['✔️'] after:-rotate-180 peer-checked:after:rotate-0"></div>
									</label>
								</div>
							</div>
							<CustomButton disabled={loadingUpdate} type={"submit"}>
								{loadingUpdate && <CircularProgress size={15} />}
								<Typography ml={loadingUpdate && 4}>Update Product</Typography>
							</CustomButton>
						</Stack>
					</form>
				</>
			)}
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</Container>
	);
}
