import {
	Box,
	FormControlLabel,
	Modal,
	Radio,
	RadioGroup,
	FormLabel,
	FormControl,
	Stack,
	Grid,
	useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import MobileNavBar from "./MobileNavBar.js";
import * as CONSTANTS from "../../utils/constants.js";
import ComputerNavBar from "./ComputerNavBar.js";
import CustomButton from "../general/CustomButton.js";

const pages = [
	{
		name: CONSTANTS.HOME,
		link: CONSTANTS.HOME_LINK,
	},
	{
		name: CONSTANTS.ABOUT,
		link: CONSTANTS.ABOUT_LINK,
	},
	{
		name: CONSTANTS.MEMBERSHIP,
		link: CONSTANTS.MEMBERSHIP_LINK,
	},
	{
		name: CONSTANTS.SHOP,
		link: CONSTANTS.SHOP_LINK,
	},
	{
		name: CONSTANTS.CONTACT,
		link: CONSTANTS.CONTACT_LINK,
	},
];

export default function NavigationBar() {
	const isMobile = useMediaQuery("(max-width:600px)");

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: isMobile ? "100%" : 400,
		bgcolor: CONSTANTS.NEUTRALS_BACKGROUND,
		border: "2px solid #000",
		boxShadow: 24,
		borderRadius: "5px",
		p: 4,
	};

	const [languageType, setLanguageType] = useState("en");
	const [openModal, setOpenModal] = useState(false);
	const { i18n } = useTranslation();

	const handleChangeLanguage = (e) => {
		i18n.changeLanguage(languageType);
		setOpenModal(false);
		e.preventDefault();
	};

	const handleLanguage = () => {
		setOpenModal(true);
	};

	return (
		<div style={{ width: "200px" }}>
			{isMobile ? (
				<MobileNavBar handleLanguage={handleLanguage} pages={pages} />
			) : (
				<ComputerNavBar handleLanguage={handleLanguage} pages={pages} />
			)}
			<Modal open={openModal} onClose={() => setOpenModal(false)}>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.1 }}
				>
					<Box sx={style}>
						<form onSubmit={handleChangeLanguage}>
							<FormControl>
								<Stack spacing={2}>
									<FormLabel
										sx={{
											fontSize: isMobile ? "20px" : "30px",
										}}
									>
										Language
									</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										value={languageType}
										onChange={(e) => setLanguageType(e.target.value)}
									>
										<Grid>
											<FormControlLabel
												value="en"
												control={<Radio />}
												label="English"
											/>
											<FormControlLabel
												value="zh"
												control={<Radio />}
												label="Chinese"
											/>
										</Grid>
									</RadioGroup>
									<CustomButton type="submit">OK</CustomButton>
								</Stack>
							</FormControl>
						</form>
					</Box>
				</motion.div>
			</Modal>
			<div
				style={{
					width: "100%",
					display: "block",
					height: isMobile ? "50px" : "100px",
				}}
			></div>
		</div>
	);
}
