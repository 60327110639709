import {
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Container,
	FormControlLabel,
	Grid,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Store } from "../Store";
import { Link, useSearchParams } from "react-router-dom";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import ErrorMessage from "../components/ErrorMessage";
import { BASEURL } from "../utils/constants";
import { getError } from "../utils";
import SnackbarMessage from "../components/SnackbarMessage";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return {
				...state,
				loading: false,
				orders: action.payload,
				pageNum: action.pageNum,
			};
		case "FETCH_FAIL":
			return { ...state, loading: false, error: action.payload };

		case "SEARCH_REQUEST":
			return { ...state, loadingSearch: true };
		case "SEARCH_SUCCESS":
			return { ...state, loadingSearch: false, orders: action.payload };
		case "SEARCH_FAIL":
			return { ...state, loadingSearch: false, error: action.payload };

		case "FILTER_REQUEST":
			return { ...state, loadingFilter: true };
		case "FILTER_SUCCESS":
			return { ...state, loadingFilter: false, orders: action.payload };
		case "FILTER_FAIL":
			return { ...state, loadingFilter: false, error: action.payload };
		default:
			return state;
	}
};

export default function DashboardScreen() {
	const { dispatch: ctxDispatch } = useContext(Store);
	const [searchParams, setSearchParams] = useSearchParams({
		productId: "",
		paid: false,
		delivered: false,
		page: "1",
	});
	const productId = searchParams.get("productId");
	const paid = searchParams.get("paid") === "true";
	const delivered = searchParams.get("delivered") === "true";
	const page = searchParams.get("page") || "1";
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);

	const [
		{ loading, loadingSearch, loadingFilter, error, orders, pageNum },
		dispatch,
	] = useReducer(reducer, {
		orders: [],
		loading: true,
		loadingSearch: false,
		loadingFilter: false,
		error: "",
		pageNum: 1,
	});
	//TODO code repetition
	const fetchData = async () => {
		dispatch({ type: "FETCH_REQUEST" });
		try {
			const { data } = await axios.post(
				`${BASEURL}/api/orders/dashboard`,
				{ page: parseInt(page) },
				{
					withCredentials: true,
				}
			);
			dispatch({
				type: "FETCH_SUCCESS",
				payload: data.allOrders,
				pageNum: Math.ceil(data.totalOrders / 10),
			});
		} catch (err) {
			dispatch({ type: "FETCH_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: "FETCH_REQUEST" });
			try {
				const { data } = await axios.post(
					`${BASEURL}/api/orders/dashboard`,
					{ page: parseInt(page) },
					{
						withCredentials: true,
					}
				);
				dispatch({
					type: "FETCH_SUCCESS",
					payload: data.allOrders,
					pageNum: Math.ceil(data.totalOrders / 10),
				});
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
				setIsShowSnackbar(true);
				if (err.response && err.response.status === 403) {
					setTimeout(function () {
						ctxDispatch({ type: "USER_SIGNOUT" });
					}, 1000);
				}
			}
		};
		fetchData();
	}, [page, ctxDispatch]);

	const handleFilter = async () => {
		dispatch({ type: "FILTER_REQUEST" });
		try {
			const { data } = await axios.get(
				`${BASEURL}/api/orders/filter/${paid}/${delivered}`,
				{
					withCredentials: true,
				}
			);
			dispatch({ type: "FILTER_SUCCESS", payload: data });
		} catch (err) {
			dispatch({ type: "FILTER_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	const handleSearchId = async () => {
		dispatch({ type: "SEARCH_REQUEST" });
		try {
			const { data } = await axios.get(`${BASEURL}/api/orders/${productId}`, {
				withCredentials: true,
			});
			dispatch({ type: "SEARCH_SUCCESS", payload: [data] });
		} catch (err) {
			dispatch({ type: "SEARCH_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	const handleReset = () => {
		setSearchParams(
			(prev) => {
				prev.set("productId", "");
				prev.set("paid", false);
				prev.set("delivered", false);
				prev.set("page", "1");
				return prev;
			},
			{ replace: true }
		);
		fetchData();
	};

	return (
		<Container
			sx={{
				minHeight: "80vh",
				pt: 5,
				pb: 5,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Helmet>
				<title>Dashboard</title>
			</Helmet>
			{loading ? (
				<PageLoadingBox />
			) : error ? (
				<ErrorMessage message={error} />
			) : (
				<>
					<Grid container spacing={3} sx={{ marginBottom: 1 }}>
						<Grid item md={6} sm={12}>
							<TextField
								label="Product ID"
								variant="outlined"
								size="small"
								value={productId}
								onChange={(e) =>
									setSearchParams(
										(prev) => {
											prev.set("productId", e.target.value);
											return prev;
										},
										{ replace: true }
									)
								}
							/>
							<Button
								variant="contained"
								onClick={handleSearchId}
								sx={{ marginLeft: 1 }}
							>
								Search
							</Button>
							{loadingSearch ? (
								<CircularProgress size={20} sx={{ marginLeft: 1 }} />
							) : (
								<></>
							)}
						</Grid>
						<Grid item md={6} sm={12}>
							<FormControlLabel
								control={
									<Checkbox
										size="small"
										checked={paid}
										onChange={(e) => {
											setSearchParams(
												(prev) => {
													prev.set("paid", !paid);
													return prev;
												},
												{ replace: true }
											);
										}}
									/>
								}
								label="Paid"
							/>
							<FormControlLabel
								control={
									<Checkbox
										size="small"
										checked={delivered}
										onChange={(e) => {
											setSearchParams(
												(prev) => {
													prev.set("delivered", !delivered);
													return prev;
												},
												{ replace: true }
											);
										}}
									/>
								}
								label="Delivered"
							/>
							<Button variant="contained" onClick={handleFilter} size="small">
								Filter
							</Button>
							<Button
								variant="contained"
								size="small"
								onClick={handleReset}
								sx={{ marginLeft: 1 }}
							>
								RESET
							</Button>
							{loadingFilter ? (
								<CircularProgress size={20} sx={{ marginLeft: 1 }} />
							) : (
								<></>
							)}
						</Grid>
					</Grid>

					<TableContainer component={Paper} variant="outlined">
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="center">Client Name</TableCell>
									<TableCell align="center">Order ID</TableCell>
									<TableCell align="center">Order Date</TableCell>
									<TableCell align="center">Amount</TableCell>
									<TableCell align="center">Status</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{orders.map((order, key) => {
									return (
										<TableRow key={key}>
											<TableCell align="center">
												{order.shippingAddress.fullName}
											</TableCell>
											<TableCell align="center">
												<Link
													to={`/order/${order._id}`}
													style={{ textDecoration: "underline" }}
												>
													{order._id.toUpperCase()}
												</Link>
											</TableCell>
											<TableCell align="center">
												{order.createdAt.slice(0, 10)}
											</TableCell>
											<TableCell align="center">${order.totalPrice}</TableCell>
											<TableCell align="center">
												<Grid
													container
													spacing={1}
													sx={{
														justifyContent: "center",
													}}
												>
													<Grid item>
														{order.isCancelled ? (
															<Chip label="Order Cancelled" color="info" />
														) : order.isPaid ? (
															<Chip label="Paid" color="primary" />
														) : (
															<Chip label="Not Paid" color="warning" />
														)}
													</Grid>
													<Grid item>
														{order.isCancelled ? (
															<></>
														) : order.isDelivered ? (
															<Chip label="Delivered" color="success" />
														) : (
															<Chip label="Not Delivered" color="default" />
														)}
													</Grid>
												</Grid>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<Pagination
						sx={{ mt: 1 }}
						count={pageNum === 0 ? 1 : pageNum}
						page={parseInt(page)}
						onChange={(e, newPage) => {
							setSearchParams(
								(prev) => {
									console.log(newPage);
									prev.set("page", newPage);
									return prev;
								},
								{ replace: true }
							);
						}}
					/>
				</>
			)}
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={error}
			/>
		</Container>
	);
}
