import { createContext, useReducer } from "react";

export const Store = createContext();

const initialState = {
	userInfo: localStorage.getItem("userInfo")
		? JSON.parse(localStorage.getItem("userInfo"))
		: null,
	cart: {
		shippingAddress: localStorage.getItem("shippingAddress")
			? JSON.parse(localStorage.getItem("shippingAddress"))
			: {},
		cartItems: localStorage.getItem("cartItems")
			? JSON.parse(localStorage.getItem("cartItems"))
			: [],
		paymentMethod: localStorage.getItem("paymentMethod")
			? localStorage.getItem("paymentMethod")
			: "",
	},
};

function reducer(state, action) {
	switch (action.type) {
		case "USER_SIGNIN":
			localStorage.setItem("userInfo", JSON.stringify(action.payload));
			return { ...state, userInfo: action.payload };

		case "USER_SIGNOUT":
			localStorage.removeItem("userInfo");
			localStorage.removeItem("shippingAddress");
			localStorage.removeItem("paymentMethod");
			return {
				...state,
				userInfo: null,
				cart: { cartItems: [], shippingAddress: {}, paymentMethod: "" },
			};

		case "USER_UPDATE":
			const userInfoString = localStorage.getItem("userInfo");
			if (userInfoString) {
				const userInfo = JSON.parse(userInfoString);
				userInfo.name = action.payload;
				localStorage.setItem("userInfo", JSON.stringify(userInfo));
			}
			return { ...state };

		case "USER_MEMBERSHIP_UPDATE":
			const userInfoStr = localStorage.getItem("userInfo");
			if (userInfoStr) {
				const userInfo = JSON.parse(userInfoStr);
				userInfo.isMember = action.payload;
				localStorage.setItem("userInfo", JSON.stringify(userInfo));
			}
			return { ...state };

		case "CART_ADD_ITEM":
			//add to cart
			const newItem = action.payload;
			const existItem = state.cart.cartItems.find(
				(item) => item._id === newItem._id
			);
			const cartItems = existItem
				? state.cart.cartItems.map((item) =>
						item._id === existItem._id ? newItem : item
				  )
				: [...state.cart.cartItems, newItem];
			localStorage.setItem("cartItems", JSON.stringify(cartItems));
			return { ...state, cart: { ...state.cart, cartItems } };

		case "CART_REMOVE_ITEM": {
			const cartItems = state.cart.cartItems.filter(
				(item) => item._id !== action.payload._id
			);
			localStorage.setItem("cartItems", JSON.stringify(cartItems));
			return { ...state, cart: { ...state.cart, cartItems } };
		}
		case "SAVE_SHIPPING_ADDRESS":
			return {
				...state,
				cart: {
					...state.cart,
					shippingAddress: action.payload,
				},
			};

		case "SAVE_PAYMENT_METHOD":
			return {
				...state,
				cart: { ...state.cart, paymentMethod: action.payload },
			};

		case "CART_CLEAR":
			return { ...state, cart: { ...state.cart, cartItems: [] } };

		default:
			return state;
	}
}

export function StoreProvider(props) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = { state, dispatch };
	return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
