import React from "react";
import { BODY_FONT } from "../../utils/constants";
import { motion, AnimatePresence } from "framer-motion";
import { testimonials } from "../../utils/utils";

export default function HomeTestimonialStackedComponent({ index }) {
	return (
		<div className="flex relative w-full min-h-[350px] sm:min-h-[450px] justify-center overflow-hidden">
			<AnimatePresence>
				{testimonials.map((testimonial, key) => {
					return (
						index === key && (
							<motion.div
								initial={{ x: "-100%", opacity: 0, scale: 0.8 }}
								animate={{ x: 0, opacity: 1, scale: 1 }}
								exit={{ x: "100%", opacity: 0, scale: 0.8 }}
								transition={{ duration: 1, ease: "easeOut", type: "spring" }}
								key={key}
								style={{
									position: "absolute",
									backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)`,
								}}
								className="w-[80%] sm:w-[65%] h-full flex flex-col gap-10 items-center justify-center px-10 rounded-lg"
							>
								<div>
									<svg
										className="w-16 h-16"
										stroke="currentColor"
										strokeWidth="1.5"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
											strokeLinejoin="round"
											strokeLinecap="round"
										></path>
									</svg>
								</div>
								<span
									className="text-sm md:text-lg text-center sm:text-start"
									style={{ fontFamily: BODY_FONT }}
								>
									"{testimonial.text}"
								</span>
								<h2
									className="font-bold text-sm md:text-lg"
									style={{ fontFamily: BODY_FONT }}
								>
									~{testimonial.name}
								</h2>
							</motion.div>
						)
					);
				})}
			</AnimatePresence>
		</div>
	);
}
