import { Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

export default function AdminLoadingSkeleton() {
	const skeletonData = [1, 2, 3, 4];
	return (
		<div style={{ height: "100vh" }}>
			<Stack mt={4} spacing={3}>
				{skeletonData.map((item, key) => {
					return (
						<Grid key={key} container>
							<Grid item xs={3}>
								<Skeleton
									variant="rectangular"
									width={150}
									height={150}
									data-testid="image-skeleton"
								/>
							</Grid>
							<Grid item xs={9}>
								<Stack spacing={3}>
									<Skeleton
										sx={{ width: "100%" }}
										data-testid="text-skeleton"
									/>
									<Skeleton
										sx={{ width: "100%" }}
										data-testid="text-skeleton"
									/>
								</Stack>
							</Grid>
						</Grid>
					);
				})}
			</Stack>
		</div>
	);
}
