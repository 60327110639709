import React from "react";
import { Container, Typography, Stack, Card, CardContent } from "@mui/material";

export default function PrivacyPolicyScreen() {
	return (
		<div style={{ backgroundColor: "#FFFAEF" }}>
			<Container
				sx={{
					minHeight: "90vh",
					display: "flex",
					alignItems: "center",
				}}
				maxWidth={"md"}
			>
				<Card variant="outlined">
					<CardContent>
						<Stack spacing={2}>
							<Typography variant="h4" textAlign={"center"}>
								<strong>Privacy Policy</strong>
							</Typography>
							<Typography variant="h6">
								<strong>Personal Data</strong>
							</Typography>
							<Typography variant="body1">
								We collect personal information, including but not limited to
								name, address, email, and payment details, to process orders and
								provide a personalized shopping experience.
							</Typography>
							<Typography variant="h6">
								<strong>Collection and use of personal data</strong>
							</Typography>
							<Typography>
								We use collected information for order processing,
								communication, and improving our services. We do not sell or
								share your information with third parties, except as required by
								law.
							</Typography>
							<Typography variant="h6">
								<strong>Subscription</strong>
							</Typography>
							<Typography>
								You have the option to opt out of promotional communications.
								The option for opting out is to be done via the membership
								Google form.
							</Typography>
							<Typography>
								We may update our terms and conditions to reflect changes in how
								we handle your information. Check our website periodically for
								the latest terms and conditions.
							</Typography>
						</Stack>
					</CardContent>
				</Card>
			</Container>
		</div>
	);
}
