import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SHOP_LINK } from "../utils/constants";

export default function ProductBreadcrumb({ productName }) {
	const navigate = useNavigate();

	return (
		<Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: "20px" }}>
			<Link underline="hover" color="inherit" href="/">
				Home
			</Link>
			<Link
				underline="hover"
				color="inherit"
				onClick={() => navigate(SHOP_LINK)}
				style={{ cursor: "pointer" }}
			>
				Shop
			</Link>
			<Typography color="text.primary">{productName}</Typography>
		</Breadcrumbs>
	);
}
