import { Container } from "@mui/material";
import React from "react";
import loadingLogo from "../../assets/images/yang_logo.png";
import { motion } from "framer-motion";
import { BODY_FONT } from "../../utils/constants";

export default function PageLoadingBox() {
	return (
		<Container
			sx={{
				display: "flex",
				justifyContent: "center",
				height: "70vh",
				alignItems: "center",
			}}
			data-testid="loading-container"
		>
			<motion.div
				className="flex items-center"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{
					duration: 1,
					repeat: Infinity,
					repeatType: "reverse",
				}}
			>
				<img src={loadingLogo} height={"100px"} width={"100px"} alt="loading" />
				<p style={{ fontFamily: BODY_FONT }} data-testid="loading-text">
					LOADING WELLNESS...
				</p>
			</motion.div>
		</Container>
	);
}
