export const HOME = "Home";
export const ABOUT = "About";
export const MEMBERSHIP = "Membership";
export const SHOP = "Shop";
export const CONTACT = "Contact";
export const PROFILE = "PROFILE";
export const SEARCH = "SEARCH";
export const PAYMENT = "PAYMENT";
export const CART = "CART";

export const HOME_LINK = "/";
export const ABOUT_LINK = "/about";
export const MEMBERSHIP_LINK = "/membership";
export const SHOP_LINK = "/shop/1";
export const CONTACT_LINK = "/contact";
export const PROFILE_LINK = "/profile";
export const SEARCH_LINK = "/search";
export const PAYMENT_LINK = "/payment";
export const LOGIN_LINK = "/login";
export const CART_LINK = "/cart";
export const DASHBOARD_LINK = "/dashboard";
export const ADMIN_PRODUCTS_LINK = "/adminproducts/1";
export const CREATE_PRODUCT_LINK = "/createproduct";
export const SET_FEATURED_PRODUCT_LINK = "/setproducts";
export const USER_BASE_LINK = "/userbase";
export const TERMS_LINK = "/terms";
export const PRIVACY_POLICY_LINK = "/privacy-policy";
export const SHIPPING_POLICY_TERM = "/shipping-policy";

export const NAVBAR_FONT = "Roboto-Regular";
export const HEADER_FONT = "Lato-Regular";
export const ABOUT_HEADER_FONT = "Oswald-VariableFont_wght";
export const BODY_FONT = "Quicksand-Medium";

export const BASEURL = "https://jian-sheng-backend-service-point.onrender.com";
export const STRIPE_PROMISE =
	"pk_live_51OaaaXI0wcIVqAlUjsOONPcQuvocJEMwcDV7uZf20SCTtkbvStkTB9DEGX3e49MZHB56uGBzaxwIaqVvm6op2nrg00KWyyDbBm";

// export const BASEURL = "http://localhost:5000";
// export const STRIPE_PROMISE =
// 	"pk_test_51Nm5lGBgR5qDFepHfhmUUrLiOhQB52V1aH0WJy2hbNPw49Anr7ZtXOWWIFrNiYjqohNF0rnutnKMFcZzkquP9j4v00asHPy1h2";

//COLOUR CODES
export const PRIMARY_COLOR = "#fce7cc";
export const PRIMARY_LIGHT = "#fffefc";
export const PRIMARY_DARK = "#f9d09c";

export const SECONDARY_COLOR = "#fbfccc";
export const SECONDARY_LIGHT = "#fffffc";
export const SECONDARY_DARK = "#f7f99c";

export const NEUTRALS_FOREGROUND = "#fbfbfb";
export const NEUTRALS_BACKGROUND = "#f1f0ef";
export const NEUTRALS_BORDER = "#e2dfdd";
export const COPY_LIGHTER = "#958d84";

export const UTILITY_SUCCESS = "#ccfccc";
export const UTILITY_INFO = "#d9f0fa";
export const UTILITY_WARNING = "#fcfccc";
export const UTILITY_ERROR = "#fccccc";
export const UTILITY_SUCCESS_CONTENT = "#0bbe0b";
export const UTILITY_WARNING_CONTENT = "#bebe0b";
export const UTILITY_ERROR_CONTENT = "#be0b0b";
