import React, { useContext, useEffect, useReducer, useState } from "react";
import { Store } from "../Store";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Chip,
	CircularProgress,
	Container,
	Grid,
	Modal,
	Stack,
	Typography,
	useMediaQuery,
	Divider,
	IconButton,
} from "@mui/material";
import ShippingStepper from "../components/ShippingStepper";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import { useStripe } from "@stripe/react-stripe-js";
import {
	BASEURL,
	LOGIN_LINK,
	NEUTRALS_BORDER,
	NEUTRALS_FOREGROUND,
	UTILITY_ERROR,
	UTILITY_INFO,
} from "../utils/constants";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import ErrorMessage from "../components/ErrorMessage";
import SnackbarMessage from "../components/SnackbarMessage";
import { getError } from "../utils";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import OverlayPageLoadingBox from "../components/loading/OverlayPageLoading";
import CustomButton from "../components/general/CustomButton";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function reducer(state, action) {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return { ...state, loading: false, order: action.payload };
		case "FETCH_FAIL":
			return { ...state, loading: false, error: action.payload };

		case "PAY_REQUEST":
			return { ...state, loadingPay: true };
		case "PAY_SUCCESS":
			return {
				...state,
				loadingPay: false,
				message: action.payload,
			};
		case "PAY_FAIL":
			return { ...state, loadingPay: false, message: action.payload };
		case "PAY_RESET":
			return { ...state, loadingPay: false };

		case "DELIVER_REQUEST":
			return { ...state, loadingDeliver: true };
		case "DELIVER_SUCCESS":
			return {
				...state,
				loadingDeliver: false,
				message: action.payload,
			};
		case "DELIVER_FAIL":
			return { ...state, loadingDeliver: false, message: action.payload };
		case "DELIVER_RESET":
			return {
				...state,
				loadingDeliver: false,
			};

		case "CANCEL_REQUEST":
			return { ...state, loadingCancel: true };
		case "CANCEL_SUCCESS":
			return { ...state, loadingCancel: false, message: action.payload };
		case "CANCEL_FAIL":
			return { ...state, loadingCancel: false, message: action.payload };

		default:
			return state;
	}
}

export default function OrderReviewScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const stripe = useStripe();
	const [openModal, setOpenModal] = useState(false);
	const [openCancelModal, setOpenCancelModal] = useState(false);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: isMobile ? "100%" : 400,
		bgcolor: NEUTRALS_FOREGROUND,
		border: `1px solid ${NEUTRALS_BORDER}`,
		boxShadow: 24,
		p: 4,
		borderRadius: "5px",
	};

	const params = useParams();
	const { id: orderId } = params;
	const navigate = useNavigate();

	const [
		{
			loading,
			error,
			order,
			message,
			loadingPay,
			loadingDeliver,
			loadingCancel,
		},
		dispatch,
	] = useReducer(reducer, {
		loading: true,
		order: {},
		error: "",
		message: "",
		loadingPay: false,
		loadingCancel: false,
	});

	useEffect(() => {
		const fetchOrder = async () => {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const { data } = await axios.post(
					`${BASEURL}/api/orders/id`,
					{ orderId: orderId },
					{
						withCredentials: true,
					}
				);
				dispatch({ type: "FETCH_SUCCESS", payload: data });
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
				if (err.response && err.response.status === 403) {
					setTimeout(function () {
						ctxDispatch({ type: "USER_SIGNOUT" });
					}, 1000);
				}
			}
		};
		if (!userInfo) {
			navigate(LOGIN_LINK);
		}
		fetchOrder();
		// TODO should ctxDispatch be added
	}, [navigate, orderId, userInfo, ctxDispatch]);

	const handlePayNowSubmit = async (e) => {
		e.preventDefault();
		try {
			dispatch({ type: "PAY_REQUEST" });
			const { paymentMethod, error } = await stripe.createPaymentMethod({
				type: "paynow",
			});
			if (error) {
				dispatch({ type: "PAY_FAIL", payload: error });
				setIsShowSnackbar(true);
				return;
			}
			const { data } = await axios.post(
				`${BASEURL}/api/payment/get-payment-intent`,
				{
					payment_method_id: paymentMethod.id,
					amount: order.totalPrice * 100,
				},
				{
					withCredentials: true,
				}
			);
			const paymentIntent = await stripe.confirmPayNowPayment(
				`${data.clientSecret}`
			);
			if (paymentIntent.paymentIntent.status === "succeeded") {
				await axios.post(
					`${BASEURL}/api/payment/payment-success`,
					{
						amount: order.totalPrice * 100,
						order: order,
					},
					{
						withCredentials: true,
					}
				);
				const msgData = await axios.post(
					`${BASEURL}/api/orders/update-payment`,
					{
						id: orderId,
					},
					{
						withCredentials: true,
					}
				);
				dispatch({ type: "PAY_SUCCESS", payload: msgData.data.message });
				setIsShowSnackbar(true);
				setTimeout(function () {
					window.location.reload();
				}, 1000);
			} else {
				dispatch({
					type: "PAY_FAIL",
					payload: "Payment failed. Please try again.",
				});
				setIsShowSnackbar(true);
			}
		} catch (err) {
			dispatch({
				type: "PAY_FAIL",
				payload: getError(err),
			});
			setIsShowSnackbar(true);
		}
	};

	const handleDeliverOrder = async () => {
		try {
			dispatch({ type: "DELIVER_REQUEST" });
			const { data } = await axios.put(
				`${BASEURL}/api/orders/delivered`,
				{
					id: order._id,
				},
				{
					withCredentials: true,
				}
			);
			dispatch({ type: "DELIVER_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
			setTimeout(function () {
				window.location.reload();
			}, 1000);
		} catch (err) {
			dispatch({ type: "DELIVER_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	const handleCancelOrder = async () => {
		dispatch({ type: "CANCEL_REQUEST" });
		try {
			const { data } = await axios.put(
				`${BASEURL}/api/orders/cancel-order`,
				{
					order: order,
				},
				{
					withCredentials: true,
				}
			);
			dispatch({ type: "CANCEL_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
			setTimeout(function () {
				window.location.reload();
			}, 1000);
		} catch (err) {
			dispatch({ type: "CANCEL_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	const handleReview = (slug) => {
		navigate(`/review/${slug}/${order._id}`);
	};

	return (
		<div>
			<Helmet>
				<title>Order</title>
			</Helmet>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}
				className="flex justify-center"
			>
				{loadingPay && <OverlayPageLoadingBox />}
				<Container
					maxWidth="md"
					sx={{
						display: "flex",
						minHeight: "600px",
						justifyContent: "center",
						marginTop: "25px",
						pb: 5,
						filter: loadingPay ? "blur(8px)" : "blur(0px)",
					}}
				>
					{loading ? (
						<PageLoadingBox />
					) : error ? (
						<ErrorMessage message={error} />
					) : (
						<Stack width={"80%"} spacing={isMobile ? 1 : 3}>
							<ShippingStepper activeStep={4} />
							<Typography
								fontSize={isMobile ? "16px" : "25px"}
								sx={{ display: "flex", alignItems: "center" }}
							>
								<IconButton onClick={() => navigate(-1)}>
									<ArrowBackIosNewIcon />
								</IconButton>
								Order: {order._id.toUpperCase()}
							</Typography>
							<Card variant="outlined" sx={{ borderRadius: 5 }}>
								<CardContent>
									<Grid
										container
										sx={{ display: "flex", justifyContent: "space-between" }}
									>
										<Grid item>
											<Typography
												variant={isMobile ? "h6" : "h5"}
												sx={{ textDecoration: "underline" }}
											>
												Shipping
											</Typography>
										</Grid>
										<Grid item sx={{ display: "flex", alignItems: "center" }}>
											{order.isPaid ? (
												<Chip
													label="Paid"
													color="primary"
													sx={{ marginRight: 1 }}
													size="small"
												/>
											) : (
												<Chip label="Not Paid" color="warning" size="small" />
											)}
											{order.isDelivered ? (
												<Chip label="Delivered" color="success" size="small" />
											) : (
												<Chip
													label="Not Delivered"
													color="default"
													size="small"
												/>
											)}
											{order.isCancelled ? (
												<Chip
													label="Order cancelled"
													color="info"
													size="small"
												/>
											) : (
												<></>
											)}
										</Grid>
									</Grid>
									<Typography variant="body1">
										<strong>Deliver Address</strong>
									</Typography>
									<Typography variant="body2">
										{order.shippingAddress.fullName}
									</Typography>
									<Typography variant="body2">
										{order.shippingAddress.address},{" "}
										{order.shippingAddress.country} (
										{order.shippingAddress.postalCode}),{" "}
										{order.shippingAddress.city}
									</Typography>
								</CardContent>
								<Divider />
								<CardContent>
									<Typography
										variant={isMobile ? "h6" : "h5"}
										sx={{ textDecoration: "underline" }}
									>
										Payment Method
									</Typography>
									<Typography variant={isMobile ? "caption" : "body2"}>
										{order.paymentMethod}
									</Typography>
								</CardContent>
								<Divider />
								<CardContent>
									<Typography
										variant={isMobile ? "h6" : "h5"}
										sx={{ textDecoration: "underline" }}
									>
										Cart Items
									</Typography>
									<Stack spacing={2}>
										{order.orderItems.map((item, key) => {
											return (
												<Grid container key={key}>
													<Grid
														item
														sm={2}
														xs={3}
														sx={{ backgroundColor: "white" }}
													>
														<CardMedia
															component="img"
															src={item.image[0]}
															alt={item.name}
															sx={{ borderRadius: 5 }}
														/>
													</Grid>
													<Grid
														item
														sm={3}
														xs={8}
														sx={{
															marginLeft: "10px",
														}}
													>
														<div
															style={{
																display: "flex",
																flexDirection: "column",
																justifyContent: "space-between",
																height: "100%",
															}}
														>
															<Typography
																variant={"h6"}
																fontSize={isMobile ? 10 : 15}
															>
																{item.name} {isMobile && `x${item.quantity}`}
															</Typography>
															{isMobile && (
																<div
																	style={{
																		display: "flex",
																		justifyContent: "space-between",
																		alignItems: "center",
																	}}
																>
																	<Typography variant="caption">
																		$
																		{item.discountedPrice > 0
																			? item.discountedPrice
																			: item.price}
																	</Typography>
																	<Button
																		variant="outlined"
																		onClick={() => handleReview(item.slug)}
																		disabled={!order.isDelivered}
																	>
																		<Typography fontSize={8}>Review</Typography>
																	</Button>
																</div>
															)}
														</div>
													</Grid>
													{!isMobile && (
														<>
															<Grid
																item
																sm={3}
																sx={{
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<Typography>{item.quantity}</Typography>
															</Grid>
															<Grid
																item
																sm={2}
																sx={{
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<Typography>
																	$
																	{item.discountedPrice > 0
																		? item.discountedPrice
																		: item.price}
																</Typography>
															</Grid>
															<Grid
																item
																sm={1}
																sx={{
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<Button
																	variant="outlined"
																	onClick={() => handleReview(item.slug)}
																	disabled={!order.isDelivered}
																>
																	Review
																</Button>
															</Grid>
														</>
													)}
												</Grid>
											);
										})}
									</Stack>
								</CardContent>
								<Divider />
								<CardContent>
									<Typography
										variant={isMobile ? "h6" : "h5"}
										sx={{ textDecoration: "underline" }}
									>
										Order Summary
									</Typography>
									<Stack>
										<Grid container>
											<Grid item xs={6} sm={3}>
												<Typography>Subtotal:</Typography>
											</Grid>
											<Grid item xs={6} sm={6}>
												<Typography>${order.itemsPrice.toFixed(2)}</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={6} sm={3}>
												<Typography>Member discount:</Typography>
											</Grid>
											<Grid item xs={6} sm={6}>
												<Typography>${order.discount.toFixed(2)}</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={6} sm={3}>
												<Typography>Shipping:</Typography>
											</Grid>
											<Grid item xs={6} sm={6}>
												<Typography>
													${order.shippingPrice.toFixed(2)}
												</Typography>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={6} sm={3}>
												<Typography>Total:</Typography>
											</Grid>
											<Grid item xs={6} sm={6}>
												<Typography>${order.totalPrice.toFixed(2)}</Typography>
											</Grid>
										</Grid>
									</Stack>
								</CardContent>
							</Card>
							{order.isPaid ? (
								<CustomButton disabled={true}>PAID</CustomButton>
							) : (
								<CustomButton
									disabled={order.isCancelled}
									onClick={handlePayNowSubmit}
									backgroundColor={UTILITY_INFO}
								>
									<Typography>PAY</Typography>
								</CustomButton>
							)}
							{order.isCancelled ? (
								<CustomButton disabled={true}>
									<Typography>ORDER CANCELLED</Typography>
								</CustomButton>
							) : (
								<CustomButton
									disabled={order.isPaid}
									onClick={() => setOpenCancelModal(true)}
									backgroundColor={UTILITY_ERROR}
								>
									<Typography>CANCEL ORDER</Typography>
								</CustomButton>
							)}
							{userInfo.isAdmin ? (
								!order.isDelivered ? (
									<CustomButton
										disabled={order.isCancelled}
										onClick={() => setOpenModal(true)}
										backgroundColor={UTILITY_INFO}
									>
										<Typography>DELIVER</Typography>
									</CustomButton>
								) : (
									<CustomButton disabled={true}>
										<Typography>ORDER DELIVERED</Typography>
									</CustomButton>
								)
							) : (
								<></>
							)}
						</Stack>
					)}
				</Container>
			</motion.div>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
			<Modal open={openModal} onClose={() => setOpenModal(false)}>
				<div className="group">
					<Box sx={style}>
						<Stack spacing={3}>
							<div className="flex justify-center group-hover:animate-bounce">
								<WarningAmberIcon fontSize="large" />
							</div>
							<Typography>
								Are you sure you want to set this product to "Delivered"?
							</Typography>
							<Grid container spacing={1}>
								<Grid item xs={3}>
									<CustomButton
										disabled={loadingDeliver}
										onClick={handleDeliverOrder}
										backgroundColor={UTILITY_INFO}
									>
										Yes
									</CustomButton>
								</Grid>
								<Grid item xs={3}>
									<CustomButton
										disabled={loadingDeliver}
										onClick={() => setOpenModal(false)}
									>
										No
									</CustomButton>
								</Grid>
								{loadingDeliver && (
									<Grid item xs={3}>
										<CircularProgress size={25} />
									</Grid>
								)}
							</Grid>
						</Stack>
					</Box>
				</div>
			</Modal>

			<Modal open={openCancelModal} onClose={() => setOpenCancelModal(false)}>
				<div className="group">
					<Box sx={style}>
						<Stack spacing={3}>
							<div className="flex justify-center group-hover:animate-bounce">
								<WarningAmberIcon fontSize="large" />
							</div>
							<Typography>
								Are you sure you want to cancel this order?
							</Typography>
							<Grid container spacing={1}>
								<Grid item xs={3}>
									<CustomButton
										disabled={loadingCancel}
										onClick={handleCancelOrder}
										backgroundColor={UTILITY_ERROR}
									>
										Yes
									</CustomButton>
								</Grid>
								<Grid item xs={3}>
									<CustomButton
										disabled={loadingCancel}
										onClick={() => setOpenCancelModal(false)}
									>
										No
									</CustomButton>
								</Grid>
								{loadingCancel && (
									<Grid item xs={3}>
										<CircularProgress size={25} />
									</Grid>
								)}
							</Grid>
						</Stack>
					</Box>
				</div>
			</Modal>
		</div>
	);
}
