import React from "react";
import { Container, Typography, Stack, Card, CardContent } from "@mui/material";

export default function TermsScreen() {
	return (
		<div style={{ backgroundColor: "#FFFAEF" }}>
			<Container
				sx={{
					minHeight: "90vh",
					display: "flex",
					alignItems: "center",
				}}
				maxWidth={"md"}
			>
				<Card variant="outlined">
					<CardContent>
						<Stack spacing={2}>
							<Typography variant="h4" textAlign={"center"}>
								<strong>Terms of use</strong>
							</Typography>
							<Typography>
								The management reserves the right to amend offer and the terms
								and conditions at any time without prior notice.
							</Typography>
							<Typography>
								All images of products serve illustrative purposes only. Actual
								products may differ in packaging and looks.
							</Typography>
						</Stack>
					</CardContent>
				</Card>
			</Container>
		</div>
	);
}
