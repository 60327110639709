import {
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Pagination,
	Select,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useReducer, useState } from "react";
import ProductListing from "../components/shop/ProductListing";
import { Helmet } from "react-helmet-async";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MaintenanceMessage from "../components/MaintenanceMessage";
import { BASEURL, HEADER_FONT } from "../utils/constants";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return {
				...state,
				products: action.payload,
				categories: action.categoriesPayload,
				loading: false,
			};
		case "FETCH_FAIL":
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

const landingPic =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fshop.png?alt=media&token=00a7ed15-29d6-4b43-b690-8d9ef80c868c";

export default function ShopScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { page } = useParams();
	const [category, setCategory] = useState("");
	const [numPages, setNumPages] = useState(1);
	const navigate = useNavigate();
	const [{ loading, error, products, categories }, dispatch] = useReducer(
		reducer,
		{
			products: [],
			categories: [],
			loading: true,
			error: "",
		}
	);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: "FETCH_REQUEST" });
			try {
				const { data } = await axios.post(
					`${BASEURL}/api/products/page/${page}`,
					{
						category: category,
					}
				);
				dispatch({
					type: "FETCH_SUCCESS",
					payload: data.products,
					categoriesPayload: data.allCategories,
				});
				setNumPages(Math.ceil(data.totalItems / 8));
			} catch (e) {
				dispatch({ type: "FETCH_FAIL", payload: e.message });
			}
		};
		fetchData();
	}, [page, category]);

	const handleChangePage = (event, newPage) => {
		navigate(`/shop/${newPage}`);
	};

	return (
		<div style={{ paddingBottom: "20px", backgroundColor: "#FFFAEF" }}>
			<Helmet>
				<title>Shop</title>
			</Helmet>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${landingPic})`,
					backgroundAttachment: "fixed",
				}}
				className="h-screen shrink-0 bg-cover bg-center"
			>
				<div className="text-white">
					<Typography
						variant={isMobile ? "h5" : "h3"}
						textAlign={"center"}
						fontFamily={HEADER_FONT}
					>
						Shop
					</Typography>
				</div>
			</div>
			{loading ? (
				<div>
					<PageLoadingBox />
				</div>
			) : error ? (
				<MaintenanceMessage />
			) : (
				<Container sx={{ pt: isMobile ? 3 : 5 }}>
					<Container sx={{ mt: isMobile ? 3 : 5 }}>
						<FormControl sx={{ minWidth: 120, mb: 2 }} size="small">
							<InputLabel size={"small"} variant="outlined">
								Category
							</InputLabel>
							<Select
								value={category}
								label="Category"
								onChange={(e) => setCategory(e.target.value)}
							>
								<MenuItem value="">
									<em>All</em>
								</MenuItem>
								{categories.map((cat, key) => {
									return (
										<MenuItem key={key} value={cat}>
											{cat}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Container>
					<Stack spacing={4} alignItems="center">
						<ProductListing key={parseInt(page)} data={products} />
						<Pagination
							count={numPages}
							page={parseInt(page)}
							onChange={handleChangePage}
						/>
					</Stack>
				</Container>
			)}
		</div>
	);
}
