import { Card, Stack } from "@mui/material";
import React from "react";
import CartProduct from "./CartProduct";
import { motion, AnimatePresence } from "framer-motion";

export default function CartProductList({ cartItems }) {
	return (
		<Stack>
			<Card variant="outlined" sx={{ marginTop: "10px", borderRadius: 2 }}>
				<AnimatePresence>
					{cartItems.map((item, index) => {
						return (
							<motion.div
								key={index}
								initial={{ opacity: 0, x: -50 }}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 0.5, delay: index * 0.1 }}
							>
								<CartProduct key={index} item={item} />
							</motion.div>
						);
					})}
				</AnimatePresence>
			</Card>
		</Stack>
	);
}
