import React from "react";
import Product from "./Product";
import { Container, Grid } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

export default function ProductListing({ data }) {
	return (
		<Container>
			<Grid container spacing={3}>
				<AnimatePresence>
					{data.map((product, index) => {
						return (
							<Grid item key={index} xs={6} md={4} lg={3}>
								<motion.div
									key={index}
									initial={{ opacity: 0, y: 50 }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: -50 }}
									transition={{ duration: 0.5, delay: index * 0.1 }}
								>
									<Product product={product} type={"shop"} />
								</motion.div>
							</Grid>
						);
					})}
				</AnimatePresence>
			</Grid>
		</Container>
	);
}
