import {
	Box,
	CardMedia,
	Container,
	Grid,
	Stack,
	TextField,
	Typography,
	CircularProgress,
	Link,
	useMediaQuery,
} from "@mui/material";
import React, { useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { getError } from "../utils";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import SnackbarMessage from "../components/SnackbarMessage";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { BASEURL, BODY_FONT, HEADER_FONT } from "../utils/constants";
import CustomButton from "../components/general/CustomButton";

const reducer = (state, action) => {
	switch (action.type) {
		case "SEND_REQUEST":
			return { ...state, loadingSend: true };
		case "SEND_SUCCESS":
			return { ...state, loadingSend: false, message: action.payload };
		case "SEND_FAIL":
			return { ...state, loadingSend: false, message: action.payload };
		default:
			return state;
	}
};

const logo =
	"https://firebasestorage.googleapis.com/v0/b/jian-sheng-website.appspot.com/o/static%2Fyang_logo_1.png?alt=media&token=79224b6b-b1f7-4e46-99e3-8b2b87a83b60";

export default function ContactScreen() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { t } = useTranslation();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [query, setQuery] = useState("");
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const [{ loadingSend, message }, dispatch] = useReducer(reducer, {
		loadingSend: false,
		message: "",
	});

	const submitHandler = async (e) => {
		e.preventDefault();
		dispatch({ type: "SEND_REQUEST" });
		try {
			const { data } = await axios.post(`${BASEURL}/api/contact/query`, {
				name: name,
				email: email,
				query: query,
			});
			dispatch({ type: "SEND_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
		} catch (err) {
			dispatch({ type: "SEND_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
		}
	};

	return (
		<div style={{ backgroundColor: "#FFFAEF" }}>
			<Helmet>
				<title>Contact</title>
			</Helmet>
			<Container
				sx={{
					minHeight: "90vh",
					alignItems: "center",
					display: "flex",
					pt: isMobile ? 3 : 0,
				}}
			>
				<Grid container justifyContent={"space-around"}>
					<Grid
						item
						sm={5}
						xs={12}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<CardMedia
								component="img"
								src={logo}
								alt="logo"
								sx={{
									height: isMobile ? "150px" : "300px",
									width: isMobile ? "150px" : "300px",
								}}
							/>
						</div>
						<Typography
							textAlign={"center"}
							fontFamily={BODY_FONT}
							fontSize={isMobile && 12}
						>
							{t("contactDescription_1")}
						</Typography>
						<Stack spacing={1} mt={5}>
							<Typography fontFamily={BODY_FONT} fontSize={isMobile && 12}>
								{t("contactDescription_2")}
							</Typography>
							<Typography
								variant="button"
								sx={{ display: "flex" }}
								justifyContent={"start"}
								fontSize={isMobile && 12}
							>
								<CallIcon sx={{ marginRight: "5px" }} />
								+65 8188 6982
							</Typography>
							<Typography
								variant="button"
								sx={{ display: "flex" }}
								justifyContent={"start"}
								fontSize={isMobile && 12}
							>
								<EmailIcon sx={{ marginRight: "5px" }} />
								<Link
									href="mailto:jianshengjsw@gmail.com"
									sx={{ textDecoration: "none", color: "black" }}
								>
									jianshengjsw@gmail.com
								</Link>
							</Typography>
						</Stack>
					</Grid>
					<Grid item sm={5} xs={12}>
						<Box sx={{ height: "500px" }}>
							<form onSubmit={submitHandler} style={{ height: "100%" }}>
								<Stack
									height={"100%"}
									spacing={isMobile ? 2 : 0}
									justifyContent={isMobile ? "center" : "space-between"}
								>
									<motion.div
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ duration: 1 }}
									>
										<Typography
											variant="h4"
											fontFamily={HEADER_FONT}
											fontSize={isMobile ? 30 : 50}
										>
											{t("contactUs")}
										</Typography>
									</motion.div>
									<motion.div
										initial={{ opacity: 0, x: 100 }}
										animate={{ opacity: 1, x: 0 }}
										transition={{ duration: 1 }}
										style={{
											display: "flex",
											flexDirection: "column",
											height: "70%",
											justifyContent: "space-between",
										}}
									>
										<TextField
											required
											value={name}
											onChange={(e) => setName(e.target.value)}
											size="small"
											variant="filled"
											label="Name"
										/>
										<TextField
											required
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											size="small"
											variant="filled"
											label="Email"
										/>
										<TextField
											required
											value={query}
											onChange={(e) => setQuery(e.target.value)}
											size="small"
											variant="filled"
											label="Query"
											multiline
											rows={8}
										/>
									</motion.div>
									<CustomButton type={"submit"}>
										{loadingSend ? (
											<Typography>
												SUBMITTING
												<CircularProgress
													size={15}
													sx={{ marginLeft: "20px" }}
												/>
											</Typography>
										) : (
											<Typography>SUBMIT</Typography>
										)}
									</CustomButton>
								</Stack>
							</form>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</div>
	);
}
