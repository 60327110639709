import {
	Box,
	IconButton,
	CardContent,
	CardMedia,
	Grid,
	Modal,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useContext, useReducer, useState } from "react";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Store } from "../../Store";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { motion } from "framer-motion";
import {
	BASEURL,
	NEUTRALS_BORDER,
	NEUTRALS_FOREGROUND,
	UTILITY_ERROR,
} from "../../utils/constants";
import CustomButton from "../general/CustomButton.js";
import { getError } from "../../utils";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 350,
	bgcolor: NEUTRALS_FOREGROUND,
	border: `1px solid ${NEUTRALS_BORDER}`,
	borderRadius: "5px",
	boxShadow: 24,
	p: 4,
};

const reducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_REQUEST":
			return { ...state, loading: true };
		case "UPDATE_SUCCESS":
			return { ...state, loading: false, message: action.payload };
		case "UPDATE_FAIL":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function CartProduct({ item }) {
	const isMobile = useMediaQuery("(max-width:600px)");
	const { dispatch: ctxDispatch } = useContext(Store);
	const [open, setOpen] = useState(false);

	const [{ loading, message }, dispatch] = useReducer(reducer, {
		loading: false,
		message: "",
	});

	const removeItemHandler = (item) => {
		// TODO use loading and message
		if (message && loading) {
		}
		ctxDispatch({ type: "CART_REMOVE_ITEM", payload: item });
		setOpen(false);
	};

	const updateCartHandler = async (item, quantity) => {
		try {
			if (quantity <= 0) {
				setOpen(true);
				return;
			}
			dispatch({ type: "UPDATE_REQUEST" });
			const { data } = await axios.get(
				`${BASEURL}/api/products/id/${item._id}`
			);
			if (data.countInStock < quantity) {
				dispatch({
					type: "UPDATE_FAIL",
					message: "Sorry product out of stock",
				});
				// TODO SNACKBAR
				return;
			}
			ctxDispatch({ type: "CART_ADD_ITEM", payload: { ...item, quantity } });
			dispatch({ type: "UPDATE_SUCCESS" });
		} catch (err) {
			dispatch({ type: "UPDATE_FAIL", message: getError(err) });
		}
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<CardContent>
			<Grid container>
				<Grid item xs={3} sm={2} sx={{ backgroundColor: "white" }}>
					<CardMedia
						component="img"
						src={item.image[0]}
						alt={item.name}
						sx={{ borderRadius: 2 }}
					/>
				</Grid>
				<Grid
					item
					xs={3}
					sm={3}
					sx={{ backgroundColor: "white", marginLeft: "10px" }}
				>
					<Stack justifyContent={"center"} height={"100%"}>
						<Typography variant="caption" fontSize={isMobile && 8}>
							{item.brand}
						</Typography>
						<Typography variant="h6" fontSize={isMobile && 10}>
							{item.name}
						</Typography>
					</Stack>
				</Grid>
				<Grid item xs={3} sm={3} sx={{ backgroundColor: "white" }}>
					<Grid
						container
						alignContent={"center"}
						justifyContent={"center"}
						sx={{ height: "100%" }}
					>
						<Grid
							container
							item
							xs={4}
							sm={4}
							justifyContent={"center"}
							alignItems={"center"}
						>
							<IconButton
								onClick={() =>
									updateCartHandler(item, Number(item.quantity) - 1)
								}
							>
								<RemoveOutlinedIcon sx={{ fontSize: isMobile && "13px" }} />
							</IconButton>
						</Grid>
						<Grid
							container
							item
							xs={2}
							sm={2}
							justifyContent={"center"}
							alignItems={"center"}
						>
							<Typography fontSize={isMobile && 10}>{item.quantity}</Typography>
						</Grid>
						<Grid
							container
							item
							xs={4}
							sm={4}
							justifyContent={"center"}
							alignItems={"center"}
						>
							<IconButton
								onClick={() =>
									updateCartHandler(item, Number(item.quantity) + 1)
								}
							>
								<AddOutlinedIcon sx={{ fontSize: isMobile && "13px" }} />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={1}
					sm={2}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography fontSize={isMobile && 10}>
						${item.discountedPrice > 0 ? item.discountedPrice : item.price}
					</Typography>
				</Grid>
				<Grid
					item
					xs={1}
					sm={1}
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Grid
						container
						item
						xs={4}
						sm={4}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<IconButton onClick={handleOpen}>
							<DeleteIcon sx={{ fontSize: isMobile && "13px" }} />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
			<Modal open={open} onClose={handleClose}>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.5 }}
					className="group"
				>
					<Box sx={style}>
						<Stack spacing={3}>
							<Typography variant="h6">Remove item from cart?</Typography>
							<Grid container spacing={1}>
								<Grid item>
									<CustomButton onClick={handleClose}>Cancel</CustomButton>
								</Grid>
								<Grid item>
									<CustomButton
										onClick={() => removeItemHandler(item)}
										backgroundColor={UTILITY_ERROR}
									>
										Confirm
									</CustomButton>
								</Grid>
							</Grid>
						</Stack>
					</Box>
				</motion.div>
			</Modal>
		</CardContent>
	);
}
