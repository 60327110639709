import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Chip,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useContext, useReducer, useState } from "react";
import { Store } from "../../Store";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASEURL } from "../../utils/constants";
import CustomButton from "../general/CustomButton.js";
import { getError } from "../../utils";

const reducer = (state, action) => {
	switch (action.type) {
		case "ADD_REQUEST":
			return { ...state, loading: true };
		case "ADD_SUCCESS":
			return { ...state, loading: false };
		case "ADD_FAIL":
			return { ...state, loading: false, message: action.payload };
		default:
			return state;
	}
};

export default function Product({ product, type }) {
	const isMobile = useMediaQuery("(max-width:600px)");
	const [addedToCart, setAddedToCart] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	const [{ loading, message }, dispatch] = useReducer(reducer, {
		loading: false,
		message: "",
	});

	const addToCartHandler = async (item) => {
		// TODO add message and loading
		if (message && loading) {
		}
		const existItem = cartItems.find((x) => x._id === product._id);
		const quantity = existItem ? existItem.quantity + 1 : 1;
		try {
			const { data } = await axios.get(
				`${BASEURL}/api/products/id/${item._id}`
			);
			if (data.countInStock < quantity) {
				// TODO SNACKBAR
				dispatch({ type: "ADD_FAIL", payload: "Sorry product out of stock" });
				return;
			}
			dispatch({ type: "ADD_REQUEST" });
			ctxDispatch({ type: "CART_ADD_ITEM", payload: { ...item, quantity } });
			setAddedToCart(true);
			dispatch({ type: "ADD_SUCCESS" });
			setTimeout(function () {
				setAddedToCart(false);
			}, 3000);
		} catch (err) {
			dispatch({ type: "ADD_FAIL", payload: getError(err) });
		}
	};

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const {
		cart: { cartItems },
	} = state;

	return (
		<Card
			sx={{
				height: isMobile ? 200 : 350,
				width: isMobile ? 150 : 260,
				borderRadius: 2,
			}}
			variant="outlined"
		>
			<div
				style={{ display: "flex", flexDirection: "column", height: "100%" }}
				className="group"
			>
				<CardMedia
					sx={{
						height: isMobile ? 100 : 180,
						width: isMobile ? 150 : 260,
						objectFit: "cover",
					}}
					component="img"
					src={
						isHovered ? product.image[1] || product.image[0] : product.image[0]
					}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					title={product.name}
				/>

				<CardContent style={{ flexGrow: 1 }}>
					{!isMobile && (
						<Typography variant="caption" color="text.secondary">
							{product.brand}
						</Typography>
					)}
					<Link
						to={`/product/${product.slug}`}
						style={{ textDecoration: "none", color: "black" }}
					>
						<Typography
							gutterBottom
							variant="body2"
							fontSize={isMobile ? 10 : 15}
						>
							{product.name}
						</Typography>
					</Link>
					<div style={{ display: "flex" }}>
						<Typography
							fontSize={isMobile ? 10 : 15}
							sx={{
								textDecoration: product.discountedPrice > 0 && "line-through",
								fontWeight: product.discountedPrice <= 0 && "bold",
							}}
						>
							${product.price}
						</Typography>
						{product.discountedPrice > 0 && (
							<Typography
								fontSize={isMobile ? 10 : 15}
								ml={1}
								sx={{ fontWeight: "bold" }}
							>
								${product.discountedPrice}
							</Typography>
						)}
					</div>
				</CardContent>
				<div className="md:transform md:translate-y-full duration-300 md:bg-[#f1f0ef] group-hover:opacity-100 group-hover:translate-y-0">
					<CardActions sx={{ mt: isMobile && -2, mb: isMobile && -0.5 }}>
						<Grid container>
							<Grid item xs={6} sm={6}>
								{type === "shop" ? (
									<CustomButton
										disabled={product.countInStock <= 0}
										size="small"
										onClick={() => addToCartHandler(product)}
									>
										{addedToCart ? (
											<Typography fontSize={isMobile ? 8 : 13}>
												Added
											</Typography>
										) : (
											<Typography fontSize={isMobile ? 8 : 13}>
												Add to cart
											</Typography>
										)}
									</CustomButton>
								) : (
									<CustomButton size="small">
										<Typography fontSize={isMobile ? 8 : 13}>
											<Link
												style={{ textDecoration: "none" }}
												to={`/product/${product.slug}`}
											>
												View Product
											</Link>
										</Typography>
									</CustomButton>
								)}
							</Grid>
							<Grid item xs={6} sm={6}>
								{product.countInStock <= 0 && (
									<Chip
										label="Out of Stock"
										color="error"
										size="small"
										sx={{ fontSize: isMobile ? 8 : 13 }}
									/>
								)}
							</Grid>
						</Grid>
					</CardActions>
				</div>
			</div>
		</Card>
	);
}
