import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useMediaQuery, Typography } from "@mui/material";

const steps = ["Sign In", "Shipping", "Payment", "Order Placement"];

export default function ShippingStepper({ activeStep }) {
	const isMobile = useMediaQuery("(max-width:600px)");
	return (
		<Box sx={{ width: "100%" }}>
			<Stepper activeStep={activeStep} alternativeLabel={isMobile}>
				{steps.map((label, index) => {
					return (
						<Step key={label}>
							<StepLabel>
								<Typography fontSize={isMobile && 10}>{label}</Typography>
							</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</Box>
	);
}
