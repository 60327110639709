import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { testimonials } from "../../utils/utils";
import HomeTestimonialStackedComponent from "./HomeTestimonialStackedComponent";
import { ABOUT_HEADER_FONT } from "../../utils/constants";

export default function HomeTestimonialStacked() {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const intervalRef = setInterval(() => {
			setIndex((pv) => {
				if (pv === testimonials.length - 1) {
					return 0;
				}
				return pv + 1;
			});
		}, 5000);
		return () => clearInterval(intervalRef);
	}, [index]);

	return (
		<div className="grid grid-rows-2 sm:flex sm:items-center min-h-[650px] w-full pb-10">
			<div className="flex flex-col w-full gap-10 sm:w-[40%] items-center justify-center text-white sm:gap-40 px-10">
				<h1
					style={{ fontFamily: ABOUT_HEADER_FONT }}
					className="uppercase text-2xl md:text-5xl lg:text-7xl font-bold text-center"
				>
					What our customers say about us
				</h1>
				<div className="flex gap-2">
					{Array(5)
						.fill(null)
						.map((_, key) => {
							return (
								<button
									className="h-1.5 sm:h-2 w-10 md:w-14 lg:w-20 bg-gray-500 rounded-lg"
									key={key}
									onClick={() => setIndex(key)}
								>
									{index === key && (
										<motion.div
											className="h-1.5 sm:h-2 bg-[#f9d19cb4] rounded-lg"
											initial={{ width: "0px" }}
											animate={{ width: "100%" }}
											transition={{ duration: 5 }}
										/>
									)}
								</button>
							);
						})}
				</div>
			</div>
			<div className="flex w-full sm:w-[60%] justify-center text-white">
				<HomeTestimonialStackedComponent index={index} />
			</div>
		</div>
	);
}
