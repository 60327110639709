import React, { useContext, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Typography, Container, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MEMBERSHIP_LINK } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import { Store } from "../../Store";
import CustomButton from "../general/CustomButton.js";

export default function HomePopout() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;
	const [isShowModal, setIsShowModal] = useState(
		!userInfo || !userInfo.isMember
	);

	return (
		<AnimatePresence>
			{isShowModal && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					onClick={() => setIsShowModal(false)}
					className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center cursor-pointer overflow-y-scroll"
					data-testid="popout-frame"
				>
					<motion.div
						initial={{ scale: 0, rotate: "12.5deg" }}
						animate={{ scale: 1, rotate: "0deg" }}
						exit={{ scale: 0, rotate: "0deg" }}
						onClick={(e) => e.stopPropagation()}
						className="bg-[#f1f0ef] pt-6 pb-6 pl-1 pr-1 w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
						data-testid="popout-box"
					>
						<Container>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Typography
									variant={isMobile ? "h5" : "h4"}
									fontFamily={"serif"}
								>
									<strong>Save 10% off the products</strong>
								</Typography>
								<button onClick={() => setIsShowModal(!isShowModal)}>
									<CloseIcon sx={{ fontSize: isMobile ? "30px" : "40px" }} />
								</button>
							</div>
							<Typography variant="overline" fontFamily={"serif"}>
								Get alerts about our new products, promotion and more
							</Typography>
							<CustomButton onClick={() => navigate(MEMBERSHIP_LINK)}>
								<div
									style={{
										color: "black",
										fontSize: isMobile && "10px",
										textDecoration: "underline",
									}}
								>
									Click here to check out our membership benefits!
								</div>
							</CustomButton>
						</Container>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
}
