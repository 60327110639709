import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import { AnimatePresence, motion } from "framer-motion";
import { BODY_FONT, UTILITY_INFO, UTILITY_SUCCESS } from "../utils/constants";
import CustomButton from "./general/CustomButton.js";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";

export default function MiniContact() {
	const [open, setOpen] = useState(false);

	return (
		<div className="fixed z-20 bottom-0 right-0 p-[30px]">
			<AnimatePresence>
				<motion.div
					variants={contactVariant}
					animate={open ? "open" : "closed"}
					className="flex flex-col justify-between container mb-2 h-[300px] w-[250px] bg-[#f9d09c] rounded-md p-5 border-[#e2dfdd] border-2"
				>
					<span style={{ fontFamily: BODY_FONT }}>
						Hi! So sorry that we are not with you now. If you drop us a message,
						we will get back to you as soon as possible!
					</span>
					<div className="flex flex-col gap-2">
						<a
							href="https://wa.me/6581886982"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomButton backgroundColor={UTILITY_SUCCESS}>
								<WhatsAppIcon sx={{ mr: 1 }} />
								Whatsapp
							</CustomButton>
						</a>
						<Link to={"/contact"}>
							<CustomButton backgroundColor={UTILITY_INFO}>
								<EmailIcon sx={{ mr: 1 }} />
								Contact Us
							</CustomButton>
						</Link>
					</div>
				</motion.div>
			</AnimatePresence>
			<div className="flex justify-end">
				<div
					className="group h-[60px] w-[60px] flex justify-center items-center bg-[#f1f0ef] rounded-2xl border-2 border-[#e2dfdd] shadow-sm cursor-pointer"
					onClick={() => setOpen(!open)}
				>
					{open ? (
						<CloseIcon fontSize="large" />
					) : (
						<div className="animate-bounce group-hover:animate-none">
							<EmailIcon fontSize="large" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

const contactVariant = {
	open: {
		opacity: 1,
		y: 0,
		x: 0,
		scale: 1,
	},
	closed: {
		opacity: 0,
		y: 100,
		x: 100,
		scale: 0,
		display: "none",
	},
};
