import React from "react";
import { Container, Stack, useMediaQuery } from "@mui/material";
import loadingLogo from "../assets/images/yang_logo.png";
import { BODY_FONT } from "../utils/constants";

export default function MaintenanceMessage() {
	const isMobile = useMediaQuery("(max-width:600px)");
	return (
		<Container
			sx={{
				display: "flex",
				justifyContent: "center",
				height: "80vh",
			}}
		>
			<Stack
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img src={loadingLogo} height={"200px"} width={"200px"} alt="loading" />
				<p
					style={{
						fontFamily: BODY_FONT,
						fontSize: isMobile ? "20px" : "30px",
					}}
				>
					<strong>We will be back soon!</strong>
				</p>
				<p
					style={{
						fontFamily: BODY_FONT,
						fontSize: isMobile ? "15px" : "15px",
					}}
				>
					Sorry for the inconvenience as we are currently performing some
					maintenance. If you need an update, feel free to contact us!
				</p>
			</Stack>
		</Container>
	);
}
