import {
	Button,
	IconButton,
	Checkbox,
	Container,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { getError } from "../utils";
import { useNavigate } from "react-router-dom";
import { BASEURL, HOME_LINK } from "../utils/constants";
import SnackbarMessage from "../components/SnackbarMessage";
import PageLoadingBox from "../components/loading/PageLoadingBox";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CustomButton from "../components/general/CustomButton";
import { Store } from "../Store";

const reducer = (state, action) => {
	switch (action.type) {
		case "FETCH_REQUEST":
			return { ...state, loading: true };
		case "FETCH_SUCCESS":
			return { ...state, loading: false, message: action.payload };
		case "FETCH_FAIL":
			return { ...state, loading: false, message: action.payload };
		case "UPDATE_REQUEST":
			return { ...state, loadingUpdate: true };
		case "UPDATE_SUCCESS":
			return { ...state, loadingUpdate: false, message: action.payload };
		case "UPDATE_FAIL":
			return { ...state, loadingUpdate: false, message: action.payload };
		default:
			return state;
	}
};

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

export default function SetFeaturedProducts() {
	const isMobile = useMediaQuery("(max-width:600px)");
	const [{ loading, message }, dispatch] = useReducer(reducer, {
		loading: false,
		message: "",
	});
	const { dispatch: ctxDispatch } = useContext(Store);

	const [checked, setChecked] = useState([]);
	const [left, setLeft] = useState([]);
	const [right, setRight] = useState([]);
	const [isShowSnackbar, setIsShowSnackbar] = useState(false);
	const navigate = useNavigate();

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const { data } = await axios.get(`${BASEURL}/api/products/featured`);
				setLeft(data.filteredProducts);
				setRight(data.featuredProducts);
				dispatch({ type: "FETCH_SUCCESS", payload: data.message });
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: getError(err) });
				setIsShowSnackbar(true);
			}
		};
		fetchData();
	}, []);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setRight(right.concat(left));
		setLeft([]);
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		setLeft(left.concat(right));
		setRight([]);
	};

	const customList = (items) => (
		<Paper
			variant="outlined"
			sx={{ maxWidth: 350, height: 400, overflow: "auto" }}
		>
			<List dense component="div" role="list">
				{items.map((value, key) => {
					const labelId = `transfer-list-item-${value}-label`;

					return (
						<ListItem key={key} role="listitem" onClick={handleToggle(value)}>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										"aria-labelledby": labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value.name} />
						</ListItem>
					);
				})}
			</List>
		</Paper>
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			dispatch({ type: "UPDATE_REQUEST" });
			const { data } = await axios.post(
				`${BASEURL}/api/products/updatefeaturedproducts`,
				{
					updatedFeaturedProducts: right,
				},
				{
					withCredentials: true,
				}
			);
			dispatch({ type: "UPDATE_SUCCESS", payload: data.message });
			setIsShowSnackbar(true);
			setTimeout(function () {
				navigate(HOME_LINK);
			}, 1000);
		} catch (err) {
			dispatch({ type: "UPDATE_FAIL", payload: getError(err) });
			setIsShowSnackbar(true);
			if (err.response && err.response.status === 403) {
				setTimeout(function () {
					ctxDispatch({ type: "USER_SIGNOUT" });
				}, 1000);
			}
		}
	};

	return (
		<Container maxWidth="md" sx={{ minHeight: "80vh", pt: 5, pb: 5 }}>
			{loading ? (
				<PageLoadingBox />
			) : (
				<Stack spacing={2}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<IconButton onClick={() => navigate(-1)} sx={{ color: "black" }}>
							<ArrowBackIosNewIcon sx={{ fontSize: isMobile ? 20 : 30 }} />
						</IconButton>
						<Typography variant={isMobile ? "h5" : "h4"}>
							Featured Product List
						</Typography>
					</div>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						alignItems="center"
					>
						<Grid item>
							<Typography>All products</Typography>
							{customList(left)}
						</Grid>
						<Grid item>
							<Grid container direction="column" alignItems="center">
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleAllRight}
									disabled={left.length === 0}
									aria-label="move all right"
								>
									≫
								</Button>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleCheckedRight}
									disabled={leftChecked.length === 0}
									aria-label="move selected right"
								>
									&gt;
								</Button>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleCheckedLeft}
									disabled={rightChecked.length === 0}
									aria-label="move selected left"
								>
									&lt;
								</Button>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleAllLeft}
									disabled={right.length === 0}
									aria-label="move all left"
								>
									≪
								</Button>
							</Grid>
						</Grid>
						<Grid item>
							<Typography>Featured Products</Typography>
							{customList(right)}
						</Grid>
					</Grid>
					<div
						style={{
							display: "flex",
							justifyContent: "end",
						}}
					>
						<div
							style={{
								width: "100px",
							}}
						>
							<CustomButton variant="contained" onClick={handleSubmit}>
								<Typography>Submit</Typography>
							</CustomButton>
						</div>
					</div>
				</Stack>
			)}
			<SnackbarMessage
				isShowSnackbar={isShowSnackbar}
				setIsShowSnackbar={setIsShowSnackbar}
				message={message}
			/>
		</Container>
	);
}
