import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import NavigationBar from "./components/navBar/NavigationBar";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import ShopScreen from "./screens/ShopScreen";
import ContactScreen from "./screens/ContactScreen";
import LoginScreen from "./screens/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen";
import CartScreen from "./screens/CartScreen";
import "./index.css";
import ShippingScreen from "./screens/ShippingScreen";
import ProductScreen from "./screens/ProductScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import Footer from "./components/Footer";
import { useEffect } from "react";
import OrderPlacementScreen from "./screens/OrderPlacementScreen";
import OrderReviewScreen from "./screens/OrderReviewScreen";
import OrderHistoryScreen from "./screens/OrderHistoryScreen";
import MembershipScreen from "./screens/MembershipScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoute";
import DashboardScreen from "./screens/DashboardScreen";
import AdminProductsScreen from "./screens/AdminProductsScreen";
import CreateProductScreen from "./screens/CreateProductScreen";
import EditProductScreen from "./screens/EditProductScreen";
import ForgetPasswordScreen from "./screens/ForgetPasswordScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import ProductReviewScreen from "./screens/ProductReviewScreen";
import SetFeaturedProducts from "./screens/SetFeaturedProducts";
import UserBaseScreen from "./screens/UserBaseScreen";
import UserBaseInfoScreen from "./screens/UserBaseInfoScreen";
import TermsScreen from "./screens/TermsScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import ShippingPolicyScreen from "./screens/ShippingPolicyScreen";
import InvalidRoute from "./components/InvalidRoute";
import MiniContact from "./components/MiniContact";

function App() {
	const ScrollToTop = () => {
		const { pathname } = useLocation();
		useEffect(() => {
			window.scrollTo(0, 0);
		}, [pathname]);
		return null;
	};

	return (
		<BrowserRouter>
			<ScrollToTop />
			<NavigationBar />
			<MiniContact />
			<div>
				<main>
					<Routes>
						<Route path="*" element={<InvalidRoute />} />
						<Route path="/" element={<HomeScreen />} />
						<Route path="/about" element={<AboutScreen />} />
						<Route path="/shop/:page" element={<ShopScreen />} />
						<Route path="/contact" element={<ContactScreen />} />
						<Route path="/login" element={<LoginScreen />} />
						<Route path="/signup" element={<SignUpScreen />} />
						<Route path="/cart" element={<CartScreen />} />
						<Route path="/shipping" element={<ShippingScreen />} />
						<Route path="/forget-password" element={<ForgetPasswordScreen />} />
						<Route
							path="/reset-password/:token"
							element={<ResetPasswordScreen />}
						/>
						<Route path="/product/:slug" element={<ProductScreen />} />
						<Route
							path="/profile"
							element={
								<ProtectedRoute>
									<ProfileScreen />
								</ProtectedRoute>
							}
						/>
						<Route path="/payment" element={<PaymentScreen />} />
						<Route path="/terms" element={<TermsScreen />} />
						<Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
						<Route path="/shipping-policy" element={<ShippingPolicyScreen />} />
						<Route path="/orderplacement" element={<OrderPlacementScreen />} />
						<Route
							path="/order/:id"
							element={
								<ProtectedRoute>
									<OrderReviewScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/orderhistory"
							element={
								<ProtectedRoute>
									<OrderHistoryScreen />
								</ProtectedRoute>
							}
						/>
						<Route path="/membership" element={<MembershipScreen />} />
						<Route
							path="/dashboard"
							element={
								<AdminRoute>
									<DashboardScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/adminproducts/:page"
							element={
								<AdminRoute>
									<AdminProductsScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/createproduct"
							element={
								<AdminRoute>
									<CreateProductScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/editproduct/:id"
							element={
								<AdminRoute>
									<EditProductScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/setproducts"
							element={
								<AdminRoute>
									<SetFeaturedProducts />
								</AdminRoute>
							}
						/>
						<Route
							path="/userbase"
							element={
								<AdminRoute>
									<UserBaseScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/userbase/:id"
							element={
								<AdminRoute>
									<UserBaseInfoScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/review/:slug/:orderid"
							element={
								<ProtectedRoute>
									<ProductReviewScreen />
								</ProtectedRoute>
							}
						/>
					</Routes>
				</main>
			</div>
			<Footer />
		</BrowserRouter>
	);
}

export default App;
