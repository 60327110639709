import React from "react";
import { Container, Typography, Stack, Card, CardContent } from "@mui/material";
export default function ShippingPolicyScreen() {
	return (
		<div style={{ backgroundColor: "#FFFAEF " }}>
			<Container
				sx={{
					minHeight: "90vh",
					display: "flex",
					alignItems: "center",
				}}
				maxWidth={"md"}
			>
				<Card variant="outlined">
					<CardContent>
						<Stack spacing={2}>
							<Typography variant="h4" textAlign={"center"}>
								<strong>Shipping Policy</strong>
							</Typography>
							<Typography variant="h6">
								<strong>Order processing and delivery</strong>
							</Typography>
							<Typography>
								Our orders are typically processed within 1-2 business days
								(excluding weekends and holidays).
							</Typography>
							<Typography>
								Due to unforeseen circumstances, such as weather conditions or
								carrier delays, may impact delivery times. We appreciate your
								understanding in such situations.
							</Typography>
							<Typography variant="h6">
								<strong>Returns and refunds</strong>
							</Typography>
							<Typography>
								We do not accept returns and refunds. All sales are final. Once
								an order is placed, it cannot be canceled or refunded.
							</Typography>
							<Typography>
								In the rare event that you receive a damaged or defective item,
								please contact our customer support within [7] days of receiving
								the product. We will work with you to resolve the issue.
							</Typography>
						</Stack>
					</CardContent>
				</Card>
			</Container>
		</div>
	);
}
